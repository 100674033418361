import axios from "axios";
import { getToken } from "components/invoicererun/api";
import { API_URL } from "config/env";
import { axiosInstance } from "lib/axios";

const runDeduplication = async () => {
  const response = await axiosInstance.post(
    `${API_URL}/deactivate_duplicate_invoices`
  );
  return response.data;
};

export default runDeduplication;
