import React, { useState, useEffect } from "react";
import { NavHeader } from "components/layout/NavHeader";
import Header from "./components/Header";
import { Spinner } from "components/common/Spinner";
import MetaData from "./components/MetaData";
import InvoiceTable from "./components/Table";
import { ToastContainer, toast } from "react-toastify";
import { axiosInstance } from "lib/axios";

export default function CreateInvoice() {
  const [loading, setLoading] = useState(false);
  const [invoice_metadata, setInvoiceMetadata] = useState({
    invoice_number: "",
    invoice_type: "",
    vendor_name: "",
    restaurant_id: "",
    invoice_date: "",
    invoice_total_amount: "",
    invoice_due_date: "",
    extra_charges_added: [],
    extra_discounts_added: [],
    extra_fees_added: [],
    invoice_balance_due: "",
    invoice_payment_terms: "",
    invoice_ship_to: "",
    invoice_bill_to: "",
    //invoice_global_addresses: [],
    invoice_sold_to: "",
    vendor_address: "",
  });
  const [invoiceFile, setInvoiceFile] = useState(null);

  // Function to read file as base64
  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]); // extract base64 string from data URL
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleSaveInvoice = async (tableData) => {
    setLoading(true);
    const reversedTableData = {};
    const combinedTableDataKeys = Object.keys(tableData[0]);
    for (const key of combinedTableDataKeys) {
      reversedTableData[key] = {};
    }
    for (const obj of tableData) {
      for (const key of combinedTableDataKeys) {
        reversedTableData[key] = {
          ...reversedTableData[key],
          [tableData.indexOf(obj)]: obj[key],
        };
      }
    }

    // Convert the invoice file to base64 string
    let pdfBase64 = null;
    if (invoiceFile) {
      pdfBase64 = await readFileAsBase64(invoiceFile);
    }

    const body = {
      invoice_metadata,
      combined_table: reversedTableData,
      pdf_in_base64: pdfBase64,
    };
    try {
      const response = await axiosInstance.post(
        "/create_invoice_from_scratch",
        body
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        setLoading(false);
      } else {
        toast.error(response.data.message);
        setLoading(false);
      }
    } catch {
      toast.error("Something went wrong");
      setLoading(false);
    }
  };

  return (
    <>
      <NavHeader view="Invoice Creation" />
      <ToastContainer />
      <Header text={"Create an Invoice"} />
      <div
        style={{
          marginRight: "50px",
          marginLeft: "50px",
        }}
      >
        <MetaData
          invoice_metadata={invoice_metadata}
          setInvoiceMetadata={setInvoiceMetadata}
        />
        <div className="my-5 mx-5" style={{ textAlign: "left" }}>
          <label>Upload Invoice (Optional)</label>
          <input
            type="file"
            className="form-control"
            onChange={(e) => setInvoiceFile(e.target.files[0])}
            style={{ width: "50%" }}
          />
        </div>
        <InvoiceTable invoice_metadata={invoice_metadata} handleSaveInvoice={handleSaveInvoice} loading={loading} />
      </div>
    </>
  );
}
