import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

export const statusFilterOptions = [
  "All",
  "Open",
  "Closed",
];
const statusFilterMap = {
    All: "null",
    Open: "open",
    Closed: "closed",
};

const useReviewFilterStore = createWithEqualityFn(
  (set) => ({
        page_index: 1,
        invoice_size: 0,
        status: "null",
        category: [],
        tags: [],
        start_date: "null",
        end_date: "null",
        changed: true,
        uuid: "null",
    actions: {
        setStatus: (status) => set({ status }),
        setCategory: (category) => set({ category }),
        setTags: (tags) => set({ tags }),
        setStartDate: (start_date) => set({ start_date }),
        setEndDate: (end_date) => set({ end_date }),
        setPageIndex: (page_index) => set({ page_index }),
        setChanged: (changed) => set({ changed }),
        setUUID: (uuid) => set({ uuid }),
    },
  }),
  Object.is
);

// This hook will not trigger re-render even if any of the state change
export const useReviewFilterActions = () =>
  useReviewFilterStore((state) => state.actions);

export const useReviewFilterParams = () =>
  useReviewFilterStore(
    (state) => ({
        page_index: state.page_index,
        invoice_size: state.invoice_size,
        status: statusFilterMap[state.status] || "null",
        category: state.category || "null",
        tags: state.tags || [],
        start_date: state.start_date || "null",
        end_date: state.end_date || "null",
    }),
    shallow
  );
export default useReviewFilterStore;
