function calculateTimeDifference(loadDateStr, maxLimitHours) {
    const loadDate = new Date(loadDateStr);

    const currentTime = new Date();

    const timeDifferenceMs = currentTime - loadDate;

    const timeDifferenceHours = timeDifferenceMs / (1000 * 60 * 60);

    const adjustedTimeDifferenceHours =  maxLimitHours - timeDifferenceHours ;

    const hours = Math.floor(adjustedTimeDifferenceHours);
    const minutes = Math.floor((adjustedTimeDifferenceHours - hours) * 60);
    const result = `${hours}hr ${minutes}min`;

    return result;
}

export default calculateTimeDifference;