import React from "react";
import ResizableCell from "components/Utility/ResizableCell";
import useAppStore from "store/global";

export default function CategoryWiseSumTable() {
  const { categoryWiseSum } = useAppStore();
  if(categoryWiseSum==null){
    return
  }
  const categoryWiseSumArray = Object.entries(categoryWiseSum).map(([category, sum]) => ({ category, sum }));
  return (
    <div
      className="d-flex flex-column align-items-center gap-2 pb-4 w-100 mt-4 mx-2 border border-gray rounded p-2"
      style={{ minHeight: "200px" }}
    >
        <div className="d-flex align-items-center justify-content-between w-100">
            <h6 className="m-2">Category Wise Total</h6>
        </div>
        <div className="d-flex flex-column align-items-center w-100">
            <div className="w-100">
            <table className="table table-bordered">
                <thead>
                <tr>
                    <th className="text-center">Category</th>
                    <th className="text-center">Total</th>
                </tr>
                </thead>
                <tbody>
                {categoryWiseSumArray.map((item, index) => (
                    <tr key={index}>
                    <td className="text-center">{item.category}</td>
                    <td className="text-center">{parseFloat(item.sum).toFixed(2)}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            </div>
        </div>

    </div>
  );
}
