import axios from "axios";
import { API_URL } from "config/env";
import { axiosInstance } from "lib/axios";

const getInvoiceNumbersandUUIDs = async (vendorName) => {
  const payload = {
    vendor_name: vendorName
  };
  const response = await axiosInstance.post(
    `${API_URL}/get_failed_invoice_uuids_for_vendor`,
    payload
  );
  return response.data;
};

const reRunInvoices = async (uuids) => {
  const payload = {
    invoice_uuids: uuids
  };
  const response = await axiosInstance.post(`${API_URL}/extract_invoice_new`, payload);
  return response.data;
};

export { getInvoiceNumbersandUUIDs, reRunInvoices };
