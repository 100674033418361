import React, { useState, useEffect } from "react";
import { NavHeader } from "components/layout/NavHeader";
import Header from "./components/Header";
import { getVendorBranchDetails, saveBranchDetails } from "./api";
import { useParams } from "react-router-dom";
import { Spinner } from "components/common/Spinner";
import BranchDetailsTable from "./components/BranchDetails";
import { toast, ToastContainer } from "react-toastify";

export default function BranchDetails() {
  const [loading, setLoading] = useState(false);
  const [branchDetails, setBranchDetails] = useState([]);
  const { vendorId, branchId } = useParams();

  const getvendorBranchDetails = () => {
    setLoading(true);
    getVendorBranchDetails(vendorId, branchId)
      .then((res) => {
        const desiredOrder = [
          "vendor_address",
          "created_date",
          "last_modified_date",
          "human_verified",
          "document_count",
          "vendor_city",
          "vendor_phone_number",
          "vendor_state",
          "vendor_street",
          "vendor_zip_code",
          "vendor_address_synonyms",
        ];
        const orderedVendorDetails = {};
        desiredOrder.forEach((key) => {
          if (res.hasOwnProperty(key)) {
            orderedVendorDetails[key] = res[key];
          }
        });
        orderedVendorDetails["vendor_id"] = vendorId;
        orderedVendorDetails["branch_id"] = branchId;
        setBranchDetails(orderedVendorDetails);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleSaveBranchDetails = (data) => {
    setLoading(true);
    saveBranchDetails(data)
      .then((res) => {
        toast.success(res.message);
        getvendorBranchDetails();
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Error saving branch details");
        setLoading(false);
      });
  };

  useEffect(() => {
    getvendorBranchDetails();
  }, []);

  return (
    <>
      <NavHeader view="Vendor Branch Details" />
      {loading === true && 
      <div className="d-flex justify-content-center h-100"
      style={{
        marginTop: "85px",
      }}
      >
        <Spinner/>
        
      </div>
      }
      {loading === false && (
        <>
          <Header
            text={`Vendor Branch Details for ${branchDetails?.vendor_address}`}
          />
          <BranchDetailsTable
            data={branchDetails}
            handleSaveBranchDetails={handleSaveBranchDetails}
            getVendorBranchDetails={getvendorBranchDetails}
          />
        </>
      )}
      <ToastContainer />
    </>
  );
}
