import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "lib/axios";
import useGlobalFilterStore from "store/globalFIlter";
import { useGlobalFilterActions } from "store/globalFIlter";
import { Spinner, Dropdown, FormControl } from "react-bootstrap";
import { PatchCheckFill } from "react-bootstrap-icons";
import useUpdateSearchParams from "hooks/useUpdateSearchParams";
import useGetNestedFilterValue from "hooks/useGetNestedFilterValue";
import useAppStore from "store/global";
export const useVendorList = (restId) => 
  useQuery({
    queryKey: ["vendors", restId],
    queryFn: async () => {
      if (restId === undefined){
        restId = null
      }
      const res = await axiosInstance.get(
        `/get_vendor_names?restaurant_id=${restId}`
      );
      console.log("the vendor names are here");
      return res.data.vendor_names;
    },
  });

export const VendorDropdown = () => {
  const updateSearchParams = useUpdateSearchParams();
  const vendorName = useGlobalFilterStore((state) => state.vendorName);
  const { setVendorName } = useGlobalFilterActions();
  const { restaurantsId } = useAppStore();
  const getNestedFilterValue = useGetNestedFilterValue();
  const rest_id = getNestedFilterValue(["filters", "restaurant_id"])
  const { data, isPending, error, isError } = useVendorList(rest_id);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredVendors, setFilteredVendors] = useState([]);
  useEffect(() => {
    if (!data) return;
    const lowercaseSearchQuery = searchQuery.toLowerCase();
    const filtered = data.filter((vendor) =>
      vendor.vendor_name
        .toLowerCase()
        .trim()
        .includes(lowercaseSearchQuery.trim())
    );
    //console.log("the filtered vendors", filtered);
    setFilteredVendors((prev) => filtered);
  }, [searchQuery, data]);
  //console.log("the filered vendors areee", filteredVendors.map((vendor) => vendor.vendor_name))
  return (
    <Dropdown
      autoClose={"outside"}
      onSelect={(v) => {
        if (v === "-1") {
          return;
        }
        setVendorName(v);
        updateSearchParams({ filters: { vendor_name: v } });
      }}
    >
      <Dropdown.Toggle
        variant="outline"
        className="border w-100 bg-white h-100"
        style={{
          lineHeight: "1.2",
          fontSize: "14px",
        }}
      >
        {getNestedFilterValue(["filters", "vendor_name"]) || "All Vendors"}
      </Dropdown.Toggle>
      <Dropdown.Menu
        style={{
          maxWidth: "300px",
          minHeight: "100px",
          maxHeight: "300px",
          overflowY: "scroll",
          overflowX: "scroll",
        }}
      >
        {isPending ? (
          <div className="d-flex h-100 w-100 justify-content-center align-items-center">
            <Spinner />
          </div>
        ) : isError ? (
          error.message
        ) : (
          <>
            <FormControl
              type="text"
              className="form-control mx-auto mb-4"
              placeholder="Search"
              onInput={(e) => {
                setSearchQuery(e.target.value);
              }}
              style={{
                width: "90%",
              }}
            />
            <Dropdown.Item eventKey={null}>{"All Vendors"}</Dropdown.Item>
            {filteredVendors?.map((vendor, i) => (
              <Dropdown.Item
                className="d-flex justify-content-between align-items-center"
                key={vendor.vendor_id}
                eventKey={vendor.vendor_name}
              >
                {vendor.vendor_name}
                {vendor.human_verified && <PatchCheckFill fill="blue" />}
              </Dropdown.Item>
            ))}
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
