import React, {useState, useEffect} from "react";
import { Dropdown } from "react-bootstrap";
import useReviewFilterStore from "../store";
import { getCommonCategories } from "../api";

export default function CategoryDropdown({items}) {
  const {
    category,
    actions: { setCategory },
  } = useReviewFilterStore();
  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="outline"
        className="border w-100 bg-white"
        style={{
          lineHeight: "1.2",
          fontSize: "14px",
        }}
      >
          {category.length === 0 ? 'Select Category' : category.join(', ')}
      </Dropdown.Toggle>
      <Dropdown.Menu
        style={{
          minHeight: "100px",
          maxHeight: "200px",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <Dropdown.Item
        className={category.length === 0 ? 'bg-primary text-white' : ''}
        key={"all"}
        onClick={() => {
          setCategory([]);
        }
        }
        >
          All

          </Dropdown.Item>
        {items?.map((filter, i) => (
          <Dropdown.Item
            className={category.includes(filter) ? 'bg-primary text-white' : ''}
            key={filter}
            onClick={() => {
              const oldCats = [...category]
              if(oldCats.includes(filter)) {
                setCategory(oldCats.filter((item) => item !== filter));
              } else {
                setCategory([...oldCats, filter]);
              }
            }}
          >
            {filter}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
