import React, { useState, useRef, useEffect } from "react";
import { Tooltip } from "react-tooltip";
import ResizableCell from "../Utility/ResizableCell";
import useAppStore from "store/global";
import { Dropdown, Form } from "react-bootstrap";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";

export default function CombinedTable({combinedTableData, setCombinedTableData}) {
  const ddRef = useRef(null);
  const {additionalHeaders} = useAppStore();
  const combinedTableheaders = Object.values(
    combinedTableData[0]
  ).map((entry) => entry.text);
  const actualTableheaders = Object.values(
    combinedTableData[0]
  ).map((entry) => entry.actual_text);

  const dataForCombinedTable = combinedTableData.slice(
    1,
    combinedTableData.length
  );

  const width = "100%";

  const handleCheckbox = (e, header, index) => {
    const isChecked = e.target.checked;
    const combinedTableDataCopy = [...combinedTableData];
    combinedTableDataCopy[0][index].selected_column =
      isChecked;
    for (let i = 0; i< combinedTableData.length; i++) {
      if (combinedTableDataCopy[i][index]) {
        combinedTableDataCopy[i][index].selected_column = isChecked;
      }   
    }
    setCombinedTableData(combinedTableDataCopy);
    
  };
  
  const handleHeaderChange = async (newHeader, index) => {
    const updatedData = [...combinedTableData];

    if (newHeader === "-1") {
      updatedData.forEach((obj, i) => {
        const keys = Object.keys(obj);
        if (index >= 0 && index < keys.length) {
          delete obj[keys[index]];
          for (let i = index + 1; i < keys.length; i++) {
            obj[keys[i - 1]] = obj[keys[i]];
            delete obj[keys[i]];
          }
        }
      });
    } else {
      updatedData[0][index].text = newHeader;
      updatedData[0][index].confidence = 100;
    }

    setCombinedTableData(updatedData);
  }
  useEffect(() => {
    const handleKeyPress = (event) => {
      const { key } = event;
      if (!ddRef.current) return;
      const dropdownItems = ddRef.current.querySelectorAll(
        ".dropdown-item"
      );
      const firstMatchingItem = Array.from(dropdownItems).find((item) =>
        item.textContent.trim().toLowerCase().startsWith(key.toLowerCase())
      );

      if (firstMatchingItem) {
        firstMatchingItem.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  return (
    <div
      style={{
        width: width || "100%",
        height: "471px",
        overflowX: "auto",
        overflowY: "auto",
      }}
    >
      <table className="table table-bordered border-gray table-striped table-responsive">
        <thead style={{ position: 'sticky', top: 0, zIndex: 1 }}>
          <tr>
            {combinedTableheaders.map((header, index) => {
              const isChecked =
                combinedTableData[0][index]
                  ?.selected_column === true;
              const handleCheckboxChange = (e) => {
                handleCheckbox(e, header, index);
              };

              return (
                <td
                  key={index}
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100px",
                    }}
                  >
                    <input
                      type="checkbox"
                      className="form-check"
                      id={`checkbox_${index}`}
                      checked={isChecked ? true : false}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                </td>
              );
            })}
          </tr>
          <tr>
            {actualTableheaders.map((header, index) => (
                <td
                key={index}
                style={{ textAlign: "center", verticalAlign: "middle" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    
                    fontSize: "14px",
                  }}
                >
                  {header}
                </div>
              </td>
              ))
            }
          </tr>
          <tr>
          {combinedTableheaders.map((header, index) => (
                <th
                  style={{
                    // width: "100px",
                    padding: 6,
                    backgroundColor: "#FFF2CD",
                    textTransform: "capitalize",
                    verticalAlign: "middle",
                  }}
                  key={index}
                  className="resizable-header"
                >
                  <ResizableCell width={120} style={{ minWidth: "120px" }}>
                    <Dropdown
                      onSelect={(e) => handleHeaderChange(e, index)}
                    >
                      <Dropdown.Toggle
                        variant="outline"
                        className="border w-100 bg-white"
                        style={{
                          lineHeight: "1.2",
                          fontSize: "14px",
                        }}
                      >
                        {header}
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        style={{
                          maxHeight: "200px",
                          overflowY: "scroll",
                          overflowX: "hidden",
                        }}
                        ref={ddRef}
                      >
                        <Dropdown.Item eventKey={"-1"}>
                          <DeleteIcon height={20} width={20} />
                        </Dropdown.Item>
                        <Dropdown.Item
                          style={{
                            lineHeight: "1",
                            fontSize: "14px",
                          }}
                          eventKey={header}
                        >
                          {header}
                        </Dropdown.Item>
                        {additionalHeaders.map((option, index) => (
                          <Dropdown.Item
                            style={{
                              lineHeight: "1",
                              fontSize: "14px",
                            }}
                            key={index}
                            eventKey={option}
                            disabled={option != "NA" && combinedTableheaders.includes(option)}
                          >
                            {option}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </ResizableCell>
                </th>
              ))}
            {/* {combinedTableheaders.map((header, index) => (
              <th
                style={{
                  backgroundColor: "#FFF2CD",
                  textTransform: "capitalize",
                  verticalAlign: "middle",
                  lineHeight: "1.2",
                  fontSize: "14px",
                }}
                key={index}
                className="resizable-header"
              >
                <ResizableCell width={100}>
                  <div
                    style={{
                      lineHeight: "1.5",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {header}
                  </div>
                </ResizableCell>
              </th>
            ))} */}
          </tr>
        </thead>
        <tbody>
          {Object.keys(dataForCombinedTable).map((key, rowIndex) => (
            <tr key={rowIndex}>
              {combinedTableheaders.map((header, colIndex) => (
                <td
                  style={{
                    fontSize: "14px",
                    backgroundColor: `${
                      combinedTableData[0][colIndex]
                        ?.selected_column == true
                        ? "#7efc5b"
                        : "#f78879"
                    }`,
                  }}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={`Confidence: ${dataForCombinedTable[key][colIndex]?.confidence}`}
                  key={colIndex}
                >
                  {dataForCombinedTable[key][colIndex]?.text}
                  <Tooltip id={colIndex} />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
