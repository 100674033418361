import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "lib/axios";
import useGlobalFilterStore from "store/globalFIlter";
import { useGlobalFilterActions } from "store/globalFIlter";
import { Spinner, Dropdown } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import useUpdateSearchParams from "hooks/useUpdateSearchParams";
import CustomDropdown from "components/CustomDropdown";
import useGetNestedFilterValue from "hooks/useGetNestedFilterValue";
import { queryClient } from "lib/react-query";
export const useRestaurantList = () =>
  useQuery({
    queryKey: ["restaurants"],
    queryFn: async () => {
      const res = await axiosInstance.get("/get_restaurant_ids");
      return res.data.restaurants;
    },
  });

export const RestaurantDropdown = () => {
  const updateSearchParams = useUpdateSearchParams();
  const restaurantId = useGlobalFilterStore((state) => state.restaurantId);
  const { setRestaurantId } = useGlobalFilterActions();
  const { data, isPending, error, isError } = useRestaurantList();
  const dropdownMenuRef = useRef(null);
  const getNestedFilterValue = useGetNestedFilterValue();
  useEffect(() => {
    const handleKeyPress = (event) => {
      const { key } = event;
      if (!dropdownMenuRef.current) return;

      const dropdownItems =
        dropdownMenuRef.current.querySelectorAll(".dropdown-item");
      const firstMatchingItem = Array.from(dropdownItems).find((item) =>
        item.textContent.trim().toLowerCase().startsWith(key.toLowerCase())
      );

      if (firstMatchingItem) {
        firstMatchingItem.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  return (
    <CustomDropdown
      data={data}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      className="w-auto"
      maxWidth="100px"
      minWidth="120px"
      menuWidth="300px"
      Objectkey="restaurant_id"
      displayKey="restaurant_name"
      value={
        data?.find(
          (d) =>
            d.restaurant_id ===
            getNestedFilterValue(["filters", "restaurant_id"])
        )?.restaurant_name || "All Restaurants"
      }
      onSelect={(v) => {
        setRestaurantId(v);
        updateSearchParams({ filters: { restaurant_id: v } });
        queryClient.invalidateQueries("vendors");
      }}
    />
  );
  // <Dropdown
  //   autoClose={"outside"}
  //   onSelect={(r) => {
  //     setRestaurantId(r);
  //     updateSearchParams({filters:{ restaurant_id: r }});
  //   }}
  //   ref={dropdownMenuRef}
  // >
  //   <Dropdown.Toggle
  //     variant="outline"
  //     className="border w-100 bg-white h-100"
  //     style={{
  //       lineHeight: "1.2",
  //       fontSize: "14px",
  //     }}
  //   >
  //     {restaurantId || "All Restaurants"}
  //   </Dropdown.Toggle>
  //   <Dropdown.Menu
  //     style={{
  //       minHeight: "100px",
  //       maxHeight: "200px",
  //       overflowY: "scroll",
  //       overflowX: "hidden",
  //     }}
  //   >
  //     {isPending ? (
  //       <div className="d-flex h-100 w-100 justify-content-center align-items-center">
  //         <Spinner />
  //       </div>
  //     ) : isError ? (
  //       error.message
  //     ) : (
  //       <>
  //         <Dropdown.Item eventKey={null}>{"All Restaurants"}</Dropdown.Item>
  //         {data?.map((restaurant, i) => (
  //           <Dropdown.Item key={restaurant + i} eventKey={restaurant}>
  //             {restaurant}
  //           </Dropdown.Item>
  //         ))}
  //       </>
  //     )}
  //   </Dropdown.Menu>
  // </Dropdown>
};
