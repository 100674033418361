import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { getCommonCategories, addCommonCategory, addCategory, useGetEngineeringIssues, addComment } from "../api";
import useReviewFilterStore from "../store";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import { useReviewFilterParams } from "../store";
import useAppStore from "store/global"
import { useParams } from 'react-router-dom';

export default function Assignment() {
  const [items, setItems] = useState([]);
  const [comment, setComment] = useState("");
  const [categories, setCategories] = useState([]);
  const [issues, setIssues] = useState([]); 
  const [review, setReview] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    changed,
    actions: { setChanged },
  } = useReviewFilterStore();
  const { uuid } = useParams();
  const {vendorName} = useAppStore();
  const filters = useReviewFilterParams();

  useEffect(() => {
    
    getCommonCategories()
      .then((res) => {
        setItems(res.common_categories);
      })
      .catch((err) => {
      });
  }, []);

  const { data, isLoading, isError } = useGetEngineeringIssues(filters);

  const handleCommentAdd = () => {
    if (comment === "") {
      toast.error("Please enter a comment");
      return;
    }
    addComment(uuid, comment)
      .then((res) => {
        toast.success("Comment Added Successfully");
        setChanged(!changed);
      })
      .catch((err) => {
        toast.error("Something went wrong ");
      });
  };
  return (
    <Row>
      <Col md={6}>
        <div
          className="mb-2 mt-2 p-2 border border-gray rounded mx-2"
          style={{ height: "380px" }}
        >
          <h6>Reported Issues</h6>
          <div className="mt-4">
            {issues?.map((issue, index) => (
                <span key={index} className="badge bg-primary me-1">{issue}</span>
            ))}
          </div>
          <div className="mt-2">
            <textarea
              className="form-control"
              name=""
              id=""
              cols="60"
              rows="7"
              disabled={true}
              value={review}
            ></textarea>
          </div>
        </div>
      </Col>
      <Col md={6}>
        <div
          className="mb-2 mt-2 p-2 border border-gray rounded mx-2"
          style={{ height: "380px" }}
        >
          <h6>Assign Categories</h6>
          <div className="mt-4 d-flex justify-content-center">
            <label htmlFor="category-dropdown">Select Category</label>
            <select
              className="form-select mx-2"
              aria-label="Default select example"
              id="category-dropdown"
              style={{ width: "230px" }}
              onChange={(e) => {
                if (e.target.value === "Assign a Category") {
                  return;
                }
                addCategory(uuid, e.target.value)
                  .then((res) => {
                    toast.success("Category Added Successfully");
                    setChanged(!changed);
                  })
                  .catch((err) => {
                    toast.error("Something went wrong ");
                  });
              }}
            >
              <option selected>Assign a Category</option>
              {items?.map((cat, index) => (
                <option
                  key={index}
                  value={cat}
                  className={categories.includes(cat) ? "bg-primary" : ""}
                >
                  {cat}
                </option>
              ))}
            </select>
            <input
              placeholder="Add a new category"
              className="form-control"
              style={{ width: "230px" }}
              type="text"
              value={newCategory}
              onChange={(e) => {
                setNewCategory(e.target.value);
              }}
            />
            <button
              className="btn btn-sm btn-warning mx-2"
              onClick={() => {
                if (newCategory === "") {
                  toast.error("Please enter a category name");
                  return;
                }
                setLoading(true);
                addCommonCategory(newCategory)
                  .then((res) => {
                    toast.success("Category Added Successfully");
                    setChanged(!changed);
                    setNewCategory("");
                    setLoading(false);
                  })
                  .catch((err) => {
                    toast.error("Something went wrong ");
                    setLoading(false);
                  });
              }}
            >
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mx-1"
                />
              ) : null}
              Add
            </button>
          </div>
          <div className="mt-2">
            <div className="mt-2">
              {categories.map((cat, index) => (
                <span key={index} className="badge bg-warning me-1">
                  {cat}
                </span>
              ))}
            </div>
          </div>
          <div className="mt-2">
            <textarea
              className="form-control"
              name=""
              id=""
              cols="60"
              rows="7"
              placeholder={"Add a comment"}
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            ></textarea>
            <div className="d-flex justify-content-end">
              <button className="btn btn-sm btn-warning mt-2 mx-2" onClick={handleCommentAdd}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}
