export function getColumnsWiseData(invoicetableData) {
  const keys0 = Object.keys(invoicetableData[0]);
  const data0 = {};

  for (const key of keys0) {
    data0[key] = {};
  }

  for (let j = 0; j < invoicetableData.length; j++) {
    const item = invoicetableData[j];
    for (let i = 0; i < keys0.length; i++) {
      const key = keys0[i];
      data0[key][j] = item[key];
    }
  }

  return data0;
}

export function getRowWiseData(data0) {
 
  const keys0 = Object.keys(data0);
  if (keys0.length === 0) {
    return [];
  }
  const invoicetableData = [];
  for (let i = 0; i < Object.values(data0[keys0[0]]).length; i++) {
    const obj = {};
    for (const key of keys0) {
      obj[key] = data0[key][i];
    }
    invoicetableData.push(obj);
  }
  return invoicetableData;
}