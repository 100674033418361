import React, { useState, useEffect } from "react";
import { NavHeader } from "components/layout/NavHeader";
import Header from "./components/Header";
import {
  ArrowRightCircleFill,
  ArrowLeftCircleFill,
} from "react-bootstrap-icons";
import { getItemsForVendor, approveItem, getAdditionalData } from "./api";
import VendorItemMastertable from "./components/VendorItemMaster";
import { Spinner } from "components/common/Spinner";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import useAppStore from "store/global";
import { Dropdown, ProgressBar } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { useFetchAllUsers } from "modules/userActivity/api";
import CustomDropdown from "components/CustomDropdown";
export default function VendorItemMaster() {
  const [searchParams] = useSearchParams();
  const startDate = searchParams.get("startDate");
  const { data: userData, loading: userDataLoading } = useFetchAllUsers();
  const endDate = searchParams.get("endDate");
  const userActivityFilters = searchParams.get("filters") || undefined;
  const { setPageNum, scale } = useAppStore();
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [tempValue, setTempValue] = useState(1);
  const [pageIndex, setPageIndex] = useState(1);
  const [itemList, setItemList] = useState([]);
  const [requiredItemColumns, setRequiredItemColumns] = useState([]);
  const [approveLoading, setApproveLoading] = useState(false);
  const [categoryChoices, setCategoryChoices] = useState([]);
  const [hoveredCellCoordinates, setHoveredCellCoordinates] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [verifiedItems, setVerifiedItems] = useState(0);
  const [sortItemDescAsc, setSortItemDescsAsc] = useState(null);
  const [sortItemCodeAsc, setSortItemCodeAsc] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchBy, setSearchBy] = useState("Item Description");
  const [search, setSearch] = useState(false);
  const [humanVerifiedFilter, setHumanVerifiedFilter] = useState(null);
  const [verifiedByFilter, setVerifiedByFilter] = useState(null);
  const [categoryReviewRequiredFilter, setCategoryReviewRequiredFilter] =
    useState(null);
  const calculatePixelCoordinates = (
    normalizedCoordinates,
    pageWidth,
    pageHeight,
    scale
  ) => {
    const pixelCoordinates = normalizedCoordinates.map(({ X, Y }) => ({
      x: X * pageWidth * scale,
      y: Y * pageHeight * scale,
    }));
    return pixelCoordinates;
  };

  function handleMultipleCellHover(
    normalizedCoordinatesArray,
    pageNumber,
    pdfUrl
  ) {
    if (
      !normalizedCoordinatesArray ||
      normalizedCoordinatesArray.length === 0 ||
      normalizedCoordinatesArray === null
    ) {
      setHoveredCellCoordinates([]);
      return;
    }
    console.log("The page number is: ", pageNumber);
    setPageNum(pageNumber);
    //toast.success("Fetching PDF page dimensions...");
    console.log("page", pageNumber);
    pdfjs
      .getDocument(pdfUrl)
      .promise.then((pdf) => {
        const promises = normalizedCoordinatesArray.map(
          async (row, rowIndex) => {
            const page = await pdf.getPage(pageNumber);
            const pageWidth = page.view[2];
            const pageHeight = page.view[3];

            const coordinates = normalizedCoordinatesArray.map(
              (normalizedCoordinates) => {
                const pixelCoordinates = calculatePixelCoordinates(
                  normalizedCoordinates,
                  pageWidth,
                  pageHeight,
                  scale
                );
                return {
                  coordinates: pixelCoordinates,
                };
              }
            );

            return coordinates;
          }
        );

        Promise.all(promises)
          .then((boundingBoxes) => {
            const flattenedCoordinates = boundingBoxes.flat();
            setHoveredCellCoordinates(flattenedCoordinates);
          })
          .catch((error) => {
            console.error("Error fetching PDF page dimensions:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching PDF:", error);
      });
  }

  const { vendorId } = useParams();

  const handleInputChange = (e) => {
    const newValue = e.target.value === "" ? 0 : parseInt(e.target.value, 10);
    setTempValue(parseInt(newValue));
  };

  const handleBlur = () => {
    setPageIndex(tempValue);
  };

  const handleGetAdditionalData = () => {
    getAdditionalData()
      .then((res) => {
        setCategoryChoices(res.category_choices);
      })
      .catch((err) => {});
  };
  const getItemList = (documentUUID, fetchingInvoiceItems) => {
    if (!fetchingInvoiceItems) {
      setLoading(true);
    }
    const body = {
      page_index: pageIndex,
      page_size: 10,
      vendor_id: vendorId,
      document_uuid: documentUUID,
      search_filter: {
        item_description:
          searchBy === "Item Description"
            ? searchTerm === ""
              ? null
              : searchTerm
            : null,
        item_code:
          searchBy === "Item Code"
            ? searchTerm === ""
              ? null
              : searchTerm
            : null,
      },
      sort_by: {
        item_description: sortItemDescAsc,
        item_code: sortItemCodeAsc,
      },
      date_filter: {
        start_date: startDate ? startDate : null,
        end_date: endDate ? endDate : null,
      },
      human_verified_filter: humanVerifiedFilter,
      verified_by_filter: verifiedByFilter,
      from_user_activity:
        searchParams.get("from_user_activity") === "true" ? true : false,
      category_review_required_filter: categoryReviewRequiredFilter,
      user_activity_filters:
        userActivityFilters !== undefined
          ? JSON.parse(userActivityFilters)
          : undefined,
    };
    getItemsForVendor(body)
      .then((res) => {
        setItemList(res.items);
        setTotalPages(res.total_pages);
        setTotalItems(res.total_items);
        setVerifiedItems(res.verified_items_count);
        setRequiredItemColumns(res.required_columns);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetAdditionalData();
    getItemList(null, false);
  }, [
    pageIndex,
    sortItemDescAsc,
    sortItemCodeAsc,
    search,
    startDate,
    endDate,
    humanVerifiedFilter,
    verifiedByFilter,
    categoryReviewRequiredFilter,
    userActivityFilters,
  ]);

  const handleCategoryChange = (body, docId) => {
    body.vendor_id = vendorId;
    setApproveLoading(true);
    approveItem(body)
      .then((data) => {
        toast.success(data.message);
        setApproveLoading(false);
        getItemList(docId, true);
      })
      .catch((err) => {
        setApproveLoading(false);
        toast.error(err.response.data.message);
      });
  };

  return (
    <>
      <NavHeader view="Vendor Items" />
      <Header
        text={"Vendor Items"}
        children={
          <div className="d-flex justify-content-end">
            <span className="mr-3">Total: {totalItems}</span>
            <ProgressBar
              now={verifiedItems}
              max={totalItems}
              label={`${verifiedItems}`}
              variant="success"
              style={{
                backgroundColor: "white",
                width: "300px",
                marginTop: "5px",
                marginLeft: "10px",
              }}
            />
          </div>
        }
      />
      {loading && (
        <div
          className="d-flex justify-content-center h-100"
          style={{
            marginTop: "85px",
          }}
        >
          <Spinner />
        </div>
      )}
      {!loading && (
        <div className="mx-5 my-4">
          <div className="d-flex justify-content-end my-2">
            <div className="d-flex justify-content-end mx-4">
              <Dropdown className="mx-2">
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  {humanVerifiedFilter === null
                    ? "Human Verified"
                    : humanVerifiedFilter
                    ? "Verified"
                    : "Not Verified"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setHumanVerifiedFilter(true);
                    }}
                  >
                    Verified
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setHumanVerifiedFilter(false);
                    }}
                  >
                    Not Verified
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setHumanVerifiedFilter(null);
                    }}
                  >
                    Clear
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {!userDataLoading && userData ? (
                <CustomDropdown
                  data={[
                    ...userData?.users,
                    { user_uuid: null, username: "None" },
                  ]}
                  Objectkey={"user_uuid"}
                  displayKey={"username"}
                  className="w-auto"
                  maxWidth="180px"
                  minWidth="180px"
                  value={
                    userData?.users?.find(
                      (user) => user.user_uuid === verifiedByFilter
                    )?.username || "Verified By"
                  }
                  onSelect={(v) => {
                    setVerifiedByFilter(v);
                  }}
                />
              ): null}

              <Dropdown className="mx-2">
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  {categoryReviewRequiredFilter === null
                    ? "Category Review"
                    : categoryReviewRequiredFilter
                    ? "Yes"
                    : "No"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setCategoryReviewRequiredFilter(true);
                    }}
                  >
                    Yes
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setCategoryReviewRequiredFilter(false);
                    }}
                  >
                    No
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setCategoryReviewRequiredFilter(null);
                    }}
                  >
                    Clear
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-4">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                style={{
                  borderRadius: "0",
                }}
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
              />
            </div>
            <div className="col-md-2">
              <select
                className="form-control"
                style={{
                  borderRadius: "0",
                }}
                onChange={(e) => {
                  if (e.target.value === "Clear") {
                    setSearchTerm("");
                    setSearchBy("Search By");
                    setSearch(!search);
                  }
                  setSearchBy(e.target.value);
                }}
                value={searchBy}
              >
                <option>Search By</option>
                <option value={"Item Code"}>Item Code</option>
                <option value={"Item Description"}>Item Description</option>
                <option value={"Clear"}>Clear</option>
              </select>
            </div>
            {/* <div className="col-md-2"> */}
            <button
              className="btn btn-primary"
              onClick={() => {
                setSearch(!search);
              }}
              style={{
                borderRadius: "0",
              }}
            >
              <Search />
            </button>
            {/* </div> */}
          </div>
          <VendorItemMastertable
            categoryChoices={categoryChoices}
            items={itemList}
            approveLoading={approveLoading}
            handleCategoryChange={handleCategoryChange}
            handleMultipleCellHover={handleMultipleCellHover}
            hoveredCellCoordinates={hoveredCellCoordinates}
            getItemList={getItemList}
            sortItemDescAsc={sortItemDescAsc}
            setSortItemDescsAsc={setSortItemDescsAsc}
            sortItemCodeAsc={sortItemCodeAsc}
            setSortItemCodeAsc={setSortItemCodeAsc}
            requiredItemColumns={requiredItemColumns}
          />
          <div className="row">
            <div className="mt-4 mb-2">
              <button
                className="my-4 mx-2 btn bg-transparent p-0 btn-outline border-0"
                disabled={tempValue - 1 <= 0}
                onClick={() => {
                  setPageIndex(tempValue - 1);
                  setTempValue(tempValue - 1);
                }}
              >
                <ArrowLeftCircleFill size={40} />
              </button>
              <span className="my-4 mx-2">
                <input
                  value={tempValue}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  className="btn btn-secondary"
                  style={{ width: "7ch" }}
                />
                <span className="my-4">
                  {" "}
                  <strong>/</strong>{" "}
                  <input
                    disabled
                    value={`${totalPages || 10}`}
                    className="btn btn-secondary"
                    style={{ width: "7ch", cursor: "default" }}
                  />
                </span>
              </span>
              <button
                className="my-4 mx-2 btn bg-transparent p-0 btn-outline border-0"
                disabled={totalPages <= tempValue}
                onClick={() => {
                  setPageIndex(tempValue + 1);
                  setTempValue(tempValue + 1);
                }}
              >
                <ArrowRightCircleFill size={40} />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
