import React, { useState, useEffect } from "react";
import { Table, Form, Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import { formatDate } from "utils/format";
import { deleteVendorBranch } from "../api";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";

const BranchDetailsTable = ({ data, handleSaveBranchDetails }) => {
  const [formData, setFormData] = useState(data);
  const [newItem, setNewItem] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { vendorId, branchId } = useParams();

  useEffect(() => {
    setFormData(data);
  }, [data]);

  const handleInputChange = (e, key) => {
    setFormData({
      ...formData,
      [key]: e.target.value
    });
  };

  const handleDropdownChange = (value, key) => {
    if (key === "vendor_address_synonyms") {
      return;
    } else if (key === "vendor_gl_code") {
      setFormData({
        ...formData,
        [key]: JSON.parse(value)
      });
    } else {
      setFormData({
        ...formData,
        [key]: value
      });
    }
  };

  const addItem = (key) => {
    if (key === "vendor_address_synonyms") {
      let oldSynonyms = formData.vendor_address_synonyms;
      if (!oldSynonyms.includes(newItem)) {
        oldSynonyms.push(newItem);
      }
      setFormData({
        ...formData,
        vendor_address_synonyms: oldSynonyms
      });
      setNewItem("");
      toast.success("Synonym added successfully");
    } else if (key === "vendor_payment_terms") {
      const allPaymentTerms = formData.metadata.all_payment_terms;
      if (!allPaymentTerms.includes(newItem)) {
        allPaymentTerms.push(newItem);
      }
      setFormData({
        ...formData,
        metadata: {
          ...formData.metadata,
          all_payment_terms: allPaymentTerms
        }
      });
      setNewItem("");
      toast.success("Payment term added successfully");
    } else if (key === "vendor_category") {
      const allCategories = formData.metadata.all_categories;
      if (!allCategories.includes(newItem)) {
        allCategories.push(newItem);
      }
      setFormData({
        ...formData,
        metadata: {
          ...formData.metadata,
          all_categories: allCategories
        }
      });
      setNewItem("");
      toast.success("Category added successfully");
    }
  };
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  // const handleKeyDown = (e) => {
  //   if (e.key === " ") {
  //     e.stopPropagation();
  //   }
  // };
  const handleDelete = () => {
    deleteVendorBranch(vendorId, branchId)
      .then((res) => {
        toast.success(res.message);
        setShowDeleteModal(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  const synonymDeleteHandler = (index) => {
    const newSynonyms = formData.vendor_address_synonyms.filter(
      (sy, i) => i !== index
    );

    setFormData({ ...formData, vendor_address_synonyms: newSynonyms });

    console.log("formData", formData);
  };
  return (
    <div
      className="mx-5"
      style={{
        marginTop: "50px"
      }}
    >
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Field</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(formData).map(
            ([key, value]) =>
              !key.startsWith("metadata") &&
              key !== "error" && (
                <tr
                  key={key}
                  style={{
                    textTransform: "capitalize"
                  }}
                >
                  <td>{key.replace(/_/g, " ")}</td>
                  <td>
                    {key === "vendor_address_synonyms" ||
                    key === "vendor_payment_terms" ||
                    key === "vendor_gl_code" ||
                    key === "vendor_category" ? (
                      <Dropdown
                        onSelect={(value) => handleDropdownChange(value, key)}
                      >
                        <Dropdown.Toggle variant="success">
                          {Array.isArray(formData[key])
                            ? formData[key].length > 0
                              ? formData[key][0]
                              : "Select"
                            : formData[key]
                            ? typeof formData[key] === "object"
                              ? formData[key]?.name
                                ? formData[key].name
                                : "None"
                              : formData[key]
                            : "None"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {key === "vendor_payment_terms"
                            ? formData.metadata.all_payment_terms.map(
                                (term, index) => (
                                  <Dropdown.Item key={index} eventKey={term}>
                                    {term}
                                  </Dropdown.Item>
                                )
                              )
                            : key === "vendor_category"
                            ? formData.metadata.all_categories.map(
                                (category, index) => (
                                  <Dropdown.Item
                                    key={index}
                                    eventKey={category}
                                  >
                                    {category === null ? "Null" : category}
                                  </Dropdown.Item>
                                )
                              )
                            : key === "vendor_gl_code"
                            ? formData.metadata.all_gl_codes.map(
                                (glCode, index) => (
                                  <Dropdown.Item
                                    key={glCode.id}
                                    eventKey={JSON.stringify(glCode)}
                                  >
                                    {glCode.name === null
                                      ? "None"
                                      : glCode.name}
                                  </Dropdown.Item>
                                )
                              )
                            : formData.vendor_address_synonyms.map(
                                (synonym, index) => (
                                  <Dropdown.Item key={index} eventKey={synonym}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        margin: "0",
                                        padding: "5px 0"
                                      }}
                                    >
                                      <p style={{ margin: "0" }}>{synonym}</p>
                                      <p
                                        onClick={() => {
                                          synonymDeleteHandler(index);
                                        }}
                                        style={{
                                          margin: "0",
                                          marginLeft: "30px",
                                          border:'1px solid gray',paddingLeft:"10px",paddingRight:"10px",borderRadius:5 ,backgroundColor:"#E5E4E2" 
                                        }}
                                      >
                                        Remove
                                      </p>
                                    </div>
                                  </Dropdown.Item>
                                )
                              )}
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : key === "human_verified" ? (
                      <Form.Switch
                        //disabled={true}
                        id="toggleButton"
                        checked={value}
                        onChange={(e) =>
                          handleDropdownChange(e.target.checked, key)
                        }
                      />
                    ) : (
                      <Form.Control
                        //type = {key === "created_date" || key === "last_modified_date" ? "date" : "text"}
                        // disabled={true}
                        type="text"
                        disabled={
                          key === "document_count" ||
                          key === "last_modified_date" ||
                          key === "created_date" ||
                          key === "vendor_id" ||
                          key === "branch_id"
                        }
                        value={
                          key === "created_date" || key === "last_modified_date"
                            ? formatDate(value)
                            : value
                        }
                        onChange={(e) => handleInputChange(e, key)}
                      />
                    )}
                  </td>
                </tr>
              )
          )}
        </tbody>
      </Table>
      <div className="mt-4 mb-3">
        <div>
          <button
            className="btn btn-success"
            style={{ width: "50%" }}
            onClick={() => handleSaveBranchDetails(formData)}
          >
            Save
          </button>
        </div>
        <div className="mt-3">
          <button
            className="btn btn-danger"
            style={{ width: "50%" }}
            onClick={() => setShowDeleteModal(true)}
          >
            Delete
          </button>
        </div>
      </div>
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure you want to delete this branch?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table">
            <tbody>
              <tr>
                <td>Vendor Address</td>
                <td>{formData.vendor_address}</td>
              </tr>
              <tr>
                <td>Branch ID</td>
                <td>{branchId}</td>
              </tr>
            </tbody>
          </table>
          {/* <p className="text-muted fs-6">
            This action cannot be undone. All items associated with this branch
            will also be deleted.
          </p> */}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger" onClick={handleDelete}>
            Delete
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BranchDetailsTable;
