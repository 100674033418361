import React, { useState } from "react";
import { Dropdown, Form, FloatingLabel, Button } from "react-bootstrap";
import { Spinner } from "components/common/Spinner";
import EngIssuesNewTable from "components/engissues/engIssuesNewTable";
export default function IssueSubmitScreen({
  mutate,
  uuid,
  isPending,
  isLoading,
   data,
   error
}) {
  const [view, setView] = useState("current_user_issues");
  const [selectedIssues, setSelectedIssues] = useState([]);
  const [show, setShow] = useState(false);

  const toggleMultiSelect = (option) => {
    if (selectedIssues.includes(option)) {
      setSelectedIssues((prev) => prev.filter((el) => el !== option));
    } else {
      setSelectedIssues((prev) => [...prev, option]);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let review = event.target.review.value ?? "";
    if (review.length < 1) review = null;
    const payload = {
      issue_uuid: null,
      item_uuid: uuid,
      issue: review,
      status: "open",
      tag: ["unknown"]
    };
    mutate(payload);
  };

  return (
    <div
      className="gap-2 pb-4 w-100 my-4 mx-2 border border-gray rounded p-2"
      style={{ minHeight: "200px", overflow: "auto" }}
    >
      <div className="d-flex justify-content-end mx-2">
        <Dropdown>
          <Dropdown.Toggle
            variant="light"
            id="dropdown-basic"
            style={{
              textTransform: "capitalize",
            }}
          >
            {view.replace(/_/g, " ")}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {["current_user_issues", "other_user_issues"]?.map(
              (option, index) => (
                <Dropdown.Item
                  style={{
                    textTransform: "capitalize",
                  }}
                  key={index}
                  onClick={() => setView(option)}
                >
                  {option.replace(/_/g, " ")}
                </Dropdown.Item>
              )
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <EngIssuesNewTable
          data={data}
          view={view}
          setView={setView}
          desiredOrder={[
            "issue",
            "tag",
            "status",
            "created_date",
            "last_modified_date",
          ]}
        />
        // <div
        //   className="p-2 my-2 border-white shadow-sm rounded-2"
        //   style={{
        //     overflow: "auto",
        //     maxHeight: "400px",
        //     width: "100%",
        //   }}
        // >
        //   <table className="table table-striped table-responsive">
        //     <thead>
        //       <tr>
        //         {data[view] && data[view].length > 0 &&
        //           Object.keys(data[view][0]).map(
        //             (key) =>
        //               key !== "issue_uuid" && (
        //                 <th
        //                   key={key}
        //                   style={{
        //                     textTransform: "capitalize",
        //                     verticalAlign: "middle",
        //                   }}
        //                 >
        //                   {key.replace(/_/g, " ")}
        //                 </th>
        //               )
        //           )}
        //       </tr>
        //     </thead>
        //     <tbody>
        //       {data[view] && data[view].length > 0 && data[view]?.map((row, index) => (
        //         <tr
        //           key={index}
        //           style={{
        //             textTransform: "capitalize",
        //           }}
        //         >
        //           {Object.keys(row).map(
        //             (key, index) =>
        //               key !== "issue_uuid" && (
        //                 <td key={index}>
        //                   {key === "created_date" ||
        //                   key === "last_modified_date"
        //                     ? formatDate(row[key])
        //                     : row[key]}
        //                 </td>
        //               )
        //           )}
        //         </tr>
        //       ))}
        //     </tbody>
        //   </table>
        // </div>
      )}
      <div className="d-flex flex-row">
        <Dropdown
          className="my-2 w-100"
          onToggle={() => setShow((s) => !s)}
          show={show}
        >
          <Dropdown.Toggle variant="warning" id="dropdown-issues">
            Select Issues
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ maxHeight: 200, overflowY: "auto" }}>
            {["unknown"]?.map((option, index) => (
              <Dropdown.Item
                key={index}
                onClick={() => toggleMultiSelect(option)}
                active={selectedIssues.includes(option)}
              >
                {option}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        {/* <Button variant="secondary mx-4" onClick={() => setShowModal(true)}>
            Add Issue
          </Button> */}
      </div>
      {selectedIssues.length > 0 && (
        <div className="d-flex flex-row flex-wrap gap-2 justify-content-center">
          <span className="fw-bold">Selected Issues:</span>
          {selectedIssues.map((issue, index) => (
            <span key={index} className="badge bg-warning text-black">
              {issue}
            </span>
          ))}
        </div>
      )}
      <Form onSubmit={handleSubmit} id="reject_form" className="w-100">
        <FloatingLabel
          controlId="floatingTextarea2"
          label="Review"
          style={{ height: "120px", width: "80%", minWidth: "200px" }}
          className="mx-auto"
        >
          <Form.Control
            as="textarea"
            name="review"
            defaultValue={""}
            className="h-100 w-100"
            placeholder="Leave a comment here"
          />
          <Form.Control.Feedback type="invalid">
            Please add a review
          </Form.Control.Feedback>
        </FloatingLabel>
        <Button
          //   disabled={!uuid}
          type="submit"
          variant="success"
          className="mt-2"
        >
          {isPending ? "Submitting..." : "Submit"}
        </Button>
      </Form>
    </div>
  );
}
