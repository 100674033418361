import { useQuery } from "@tanstack/react-query";
import { queryClient } from "lib/react-query";
import { axiosInstance } from "lib/axios";
import { useMemo } from "react";
import { toast } from "react-toastify";

export const useFetchAllUsers = () => {
  return useQuery({
    placeholderData: (previousData, q) => previousData,
    queryKey: ["allUsers"],
    queryFn: async () => {
      try {
        const response = await axiosInstance.get(`/user_activity/list_users`);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.ensureQueryData(["allUsers"], data);
    },
  });
};

// 
export const useFetchUserActivity = (userUUID, dateRange) => {
  const queryKey = useMemo(
    () => ["userActivity", userUUID, dateRange],
    [userUUID, dateRange]
  );
  return useQuery({
    placeholderData: (previousData, q) => previousData,
    queryKey: queryKey,
    queryFn: async () => {
      try {
        const formatDate = (date) => {
          if (!date) return null;
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          return `${year}-${month}-${day}`;
        };

        const postData = {
          user_uuid: userUUID === "all" ? null : userUUID,
          date_filter: {
            start_date: formatDate(dateRange[0]?.startDate),
            end_date: formatDate(dateRange[0]?.endDate),
          },
        };
        const response = await axiosInstance.post(
          `/user_activity/user_activity_and_statistics`,
          postData
        );
        return response.data;
      } catch (error) {
        console.log("first error", error);
        throw error;
      }
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.ensureQueryData(
        ["userActivity", userUUID, dateRange],
        data
      );
    },
  });
};


export const useFetchUserInvoiceIssues = (pageIndex, pageSize, user_uuid) => {
  return useQuery({
    placeholderData: (previousData, q) => previousData,
    queryKey: ["userInvoiceIssues", pageIndex, pageSize, user_uuid],
    queryFn: async () => {
      try {
        const body = {
          all_users: user_uuid === "all" ? true : false,
          page_index: pageIndex,
          page_size: pageSize,
        };
        const response = await axiosInstance.post(
          `/user_activity/get_user_invoice_issues`,
          body
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.ensureQueryData(
        ["userInvoiceIssues", pageIndex, pageSize, user_uuid],
        data
      );
    },
  });
};

export const useFetchUserItemMasterIssues = (pageIndex, pageSize, user_uuid) => {
  return useQuery({
    placeholderData: (previousData, q) => previousData,
    queryKey: ["userItemMasterIssues", pageIndex, pageSize, user_uuid],
    queryFn: async () => {
      try {
        const body = {
          all_users: user_uuid === "all" ? true : false,
          page_index: pageIndex,
          page_size: pageSize,
        };
        const response = await axiosInstance.post(
          `/user_activity/get_user_item_master_issues`,
          body
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.ensureQueryData(
        ["userItemMasterIssues", pageIndex, pageSize, user_uuid],
        data
      );
    },
  });
};

export const useFetchVerifiedVendorData = (pageIndex, pageSize, user_uuid, date, timeRange, action, fetch) => {
  return useQuery({
    placeholderData: (previousData, q) => previousData,
    queryKey: ["verifiedVendorData", pageIndex, pageSize, user_uuid, date, timeRange, action],
    queryFn: async () => {
      try {
        const body = {
          page_index: pageIndex,
          page_size: pageSize,
          user_uuid: user_uuid,
          date: {
            start_date: date[0],
            end_date: date[1]
          },
          verification_time_range: {
            start: timeRange[0] ? timeRange[0] : null,
            end: timeRange[1] ? timeRange[1] : null
          },
          action: action
          }
        const response = await axiosInstance.post(
          `/user_activity/verified_vendor_details`,
          body
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.ensureQueryData(
        ["verifiedVendorData", pageIndex, pageSize, user_uuid, date, timeRange, action],
        data
      );
    },
    enabled: !!fetch,
  });
};

export const useFetchVerifiedItemData = (pageIndex, pageSize, user_uuid, date, timeRange, fetch) => {
  return useQuery({
    placeholderData: (previousData, q) => previousData,
    queryKey: ["verifiedItemData", pageIndex, pageSize, user_uuid, date, timeRange],
    queryFn: async () => {
      try {
        const body = {
          page_index: pageIndex,
          page_size: pageSize,
          user_uuid: user_uuid,
          date: {
            start_date: date[0],
            end_date: date[1]
          },
          verification_time_range: {
            start: timeRange[0] ? timeRange[0] : null,
            end: timeRange[1] ? timeRange[1] : null
          },
          }
        const response = await axiosInstance.post(
          `/user_activity/verified_item_master_details`,
          body
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.ensureQueryData(
        ["verifiedItemData", pageIndex, pageSize, user_uuid, date, timeRange],
        data
      );
    },
    enabled: !!fetch,
  });
};
