export const formatDate = (inputDate, local = "en-US") => {
  if (!inputDate) {
    return inputDate;
  }
  const date = new Date(inputDate);
  const formattedDate = date.toLocaleDateString(local, {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });
  return formattedDate;
};
