import React, { useEffect, useState } from "react";
import { Table, Button, Spinner } from "react-bootstrap";
import { PlusCircle, Trash2Fill } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { axiosInstance } from "lib/axios";
import { Modal } from "react-bootstrap";

const InvoiceTable = ({ invoice_metadata, handleSaveInvoice, loading }) => {
  const [show, setShow] = useState(false);
  const [matchingItems, setMatchingItems] = useState([]); 
  const [rowIndex, setRowIndex] = useState(null);
  const initialTableData = [
    {
      0: { text: "Category", confidence: 100, selected_column: true },
      1: { text: "Item Code", confidence: 100, selected_column: true },
      2: { text: "Item Description", confidence: 100, selected_column: true },
      3: { text: "Quantity", confidence: 100, selected_column: true },
      4: { text: "Unit Price", confidence: 100, selected_column: true },
      5: { text: "Extended Price", confidence: 100, selected_column: true },
    },
    {
      0: { text: "", confidence: 0, selected_column: true },
      1: { text: "", confidence: 0, selected_column: true },
      2: { text: "", confidence: 0, selected_column: true },
      3: { text: "", confidence: 0, selected_column: true },
      4: { text: "", confidence: 0, selected_column: true },
      5: { text: "", confidence: 0, selected_column: true },
    },
  ];
  const categoryChoices = [
    "Bar supplies",
    "Catering supplies",
    "Cgs beer",
    "Cgs food",
    "Cgs liquor",
    "Cgs merchandise",
    "Cgs wine",
    "Cleaning supplies",
    "Co2 gas",
    "Dish lease",
    "Dish supplies",
    "Equipment rental",
    "Freight and postage",
    "Fry oil",
    "General supplies",
    "Kitchen supplies",
    "Knife rental",
    "Linen",
    "Menu collateral",
    "Paper goods",
    "Smallwares",
    "Uniforms",
  ];
  const [tableData, setTableData] = useState(initialTableData);
  const [dataForEditableTable, setDataForEditableTable] = useState([]);
  const [headers, setHeaders] = useState([]);

  const additionalHeaders = [
    "Category",
    "Item Code",
    "Cases",
    "Bottles",
    "Item Description",
    "Quantity",
    "Pack",
    "Adj",
    "Discount",
    "Bottle Price",
    "Unit Price",
    "Unit Discount",
    "Tax",
    "Dep",
    "Weight",
    "Extended Price",
    "Extra Amount In Ext. Price",
    "Size",
    "Unit Tax Amount",
    "Bin Location",
    "Unit of Measure",
    "Upc",
    "NA"
]

  useEffect(() => {
    setDataForEditableTable(tableData.slice(1, tableData.length));
    setHeaders(
      Object.keys(tableData[0])
        .filter((key) => tableData[0][key].selected_column)
        .map((key) => ({ ...tableData[0][key], key }))
    );
  }, [tableData]);

  const addRow = () => {
    const newRow = {};
    Object.keys(initialTableData[0]).forEach((key) => {
      newRow[key] = { text: "", confidence: 0, selected_column: true };
    });
    setTableData([...tableData, newRow]);
  };

  const deleteRow = (index) => {
    if (index >= 0 && index < tableData.length) {
      if (index == 0) {
        toast.error("The table needs to have one row at least");
        return;
      }
      const updatedTableData = [
        ...tableData.slice(0, index),
        ...tableData.slice(index + 1),
      ];
      setTableData(updatedTableData);
    }
  };

  const addColumn = () => {
    const newTableData = tableData.map((row) => {
      const newRow = { ...row };
      const maxColumnKey =
        Math.max(...Object.keys(newRow).map((key) => parseInt(key))) + 1;
      const newColumnKey = maxColumnKey.toString();
      newRow[newColumnKey] = {
        text: "",
        confidence: 100,
        selected_column: true,
      };
      return newRow;
    });
    setTableData(newTableData);
  };

  const deleteColumn = (columnKey) => {
    const updatedTableData = tableData.map((row) => {
      const newRow = { ...row };
      delete newRow[columnKey];
      return newRow;
    });

    setTableData(updatedTableData);
  };

  const handleCellChange = async (rowIndex, colIndex, value, headerValue) => {
    setDataForEditableTable((prevData) => {
      const newData = [...prevData];
      newData[rowIndex] = { ...newData[rowIndex] };
      newData[rowIndex][colIndex] = {
        ...newData[rowIndex][colIndex],
        text: value,
      };
      return newData;
    });
  };

  const handleItemMasterLookup = async (rowIndex, colIndex, value) => {
    const response = await axiosInstance.post("/item_master_lookup", {
      vendor_id: invoice_metadata.vendor_details.vendor_id,
      branch_id: invoice_metadata.vendor_details.branch_id,
      item_code: value,
      item_description: "",
    });
    let matching_items = response.data.matching_items;
    if (matching_items.length > 0) {
      if (matching_items.length > 1) {
        toast.warning("Multiple items found for the given item code");
        setRowIndex(rowIndex);
        setMatchingItems(matching_items);
        setShow(true);
        return;
      }
      const item = matching_items[0];
      setDataForEditableTable((prevData) => {
        const newData = [...prevData];
        newData[rowIndex] = { ...newData[rowIndex] };
        newData[rowIndex][0] = {
          text: item.category,
        };
        newData[rowIndex][2] = {
          ...newData[rowIndex][2],
          text: item.item_description,
        };
        newData[rowIndex][4] = {
          text: item.unit_price,
        };
        return newData;
      });
    }
  };

  const handleHeaderChange = (colIndex, value) => {
    if (value == "-1") {
      deleteColumn(colIndex);
      return;
    }
    setHeaders((prevHeaders) => {
      const newHeaders = [...prevHeaders];
      newHeaders[colIndex] = { ...newHeaders[colIndex], text: value };
      return newHeaders;
    });
  };

  return (
    <>
      <div
        className="mb-2 mt-3 mx-5"
        style={{
          textAlign: "left",
        }}
      >
        <h4>Enter Invoice Data</h4>
      </div>
      <div className="mx-5 overflow-auto shadow-sm p-3 bg-white rounded">
        <Table bordered responsive striped>
          <thead>
            <tr>
              {Object.keys(headers).map((colIndex) => (
                <th
                  key={colIndex}
                  style={{
                    position: "sticky",
                    top: "0",
                    zIndex: "1",
                    width: "270px",
                    minWidth: "200px",
                    verticalAlign: "middle",
                  }}
                >
                  <select
                    className="form-select"
                    value={headers[colIndex]?.text}
                    onChange={(e) =>
                      handleHeaderChange(colIndex, e.target.value)
                    }
                  >
                    <option value="">Select Header</option>
                    {additionalHeaders.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                    <option value="-1">
                      Delete Column
                      <Trash2Fill
                        fill="red"
                        style={{
                          cursor: "pointer",
                        }}
                        width={20}
                        height={20}
                      ></Trash2Fill>
                    </option>
                  </select>
                </th>
              ))}
              <td>
                <Button variant="primary" onClick={addColumn}>
                  <PlusCircle />
                </Button>
              </td>
            </tr>
          </thead>
          <tbody>
            {dataForEditableTable.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {Object.keys(row).map((colIndex) => (
                  <>
                    <td key={colIndex}>
                      {headers[colIndex]?.text === "Category" ? (
                        <select
                          className="form-select"
                          value={row[colIndex].text}
                          onChange={(e) =>
                            handleCellChange(
                              rowIndex,
                              colIndex,
                              e.target.value,
                              headers[colIndex].text
                            )
                          }
                        >
                          <option value="">Select Category</option>
                          {categoryChoices.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type="text"
                          className="form-control"
                          value={row[colIndex].text}
                          onChange={(e) =>
                            handleCellChange(
                              rowIndex,
                              colIndex,
                              e.target.value,
                              headers[colIndex].text
                            )
                          }
                          onBlur={(e) => {
                            if (headers[colIndex].text === "Item Code") {
                              handleItemMasterLookup(
                                rowIndex,
                                colIndex,
                                e.target.value
                              );
                            }  
                          }}
                        />
                      )}
                    </td>
                  </>
                ))}
                <td>
                  <Trash2Fill
                    fill="red"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      deleteRow(rowIndex);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Button className="my-4" variant="primary" onClick={addRow}>
          <PlusCircle /> Add Row
        </Button>{" "}
      </div>
      <div className="my-5">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => handleSaveInvoice(tableData)}
          style={{ width: "50%" }}
          disabled={loading}
        >
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mx-2"
            />
          )}
          {loading ? "Saving" : "Save Invoice"}
        </button>
      </div>

      <Modal show={show} onHide={() => setShow(false)} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Item Master Lookup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Item Master Lookup</p>
          <Table bordered responsive striped>
            <thead>
              <tr>
                <th>Category</th>
                <th>Item Code</th>
                <th>Item Description</th>
                <th>Unit Price</th>
              </tr>
            </thead>
            <tbody>
              {matchingItems.map((item, index) => (
                <tr key={index}
                onClick={() => {
                  setDataForEditableTable((prevData) => {
                    const newData = [...prevData];
                    newData[rowIndex] = { ...newData[rowIndex] };
                    newData[rowIndex][0] = {
                      text: item.category,
                    };
                    newData[rowIndex][2] = {
                      ...newData[rowIndex][2],
                      text: item.item_description,
                    };
                    newData[rowIndex][4] = {
                      text: item.unit_price,
                    };
                    return newData;
                  });
                  setShow(false);
                }  
                }
                >
                  <td>{item.category}</td>
                  <td>{item.item_code}</td>
                  <td>{item.item_description}</td>
                  <td>{item.unit_price}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InvoiceTable;
