import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FloatingLabel, Form } from "react-bootstrap";

export default function RejectModal({ show, width, handleClose, reason }) {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    // const form = event.currentTarget;
    // if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    // }
    setValidated(true);
    event.target.reason.value && handleClose(event.target.reason.value);
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add A Reason to Reject
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          id="reject_form"
        >
          <FloatingLabel controlId="floatingTextarea2" label="Reason">
            <Form.Control
              required
              as="textarea"
              name="reason"
              defaultValue={reason}
              placeholder="Leave a comment here"
              style={{ height: "100px" }}
              minLength={1}
            />
            <Form.Control.Feedback type="invalid">
              Please add a reason
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="submit"
          form="reject_form"
          className="btn btn-danger mx-2"
        >
          Reject
        </Button>
        <Button
          className="btn btn-warning"
          type="button"
          onClick={() => handleClose(false)}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
