import React, { useState } from "react";
import useAppStore from "store/global";
import { axiosInstance } from "lib/axios";
import { toast } from "react-toastify";
import { PatchCheckFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

export default function MetaDataTable() {
  const {
    invoiceNum,
    invoiceDate,
    dueDate,
    vendorName,
    vendorPhone,
    vendorAddress,
    invoiceBalance,
    invoiceTotal,
    invoicePaymentTerms,
    invoiceShipTo,
    invoiceType,
    invoiceBillTo,
    invoiceGlobalAddresses,
    invoiceSoldTo,
    totalPagesRcvd,
    totalPagesInInvoice,
    totalPagesInInvoiceFromGlobal,
    vendorNamesSource,
    totalPagesProcessed,
    humanVerificationReqd,
    invoiceTotalFromtable,
    failedReasons,
    verdict,
    concerns,
    extraChargesAdded,
    extraFeesAdded,
    extraDiscountsAdded,
    documentTypePrediction,
    version,
    rerunStatus,
    invoiceUUID,
    restaurantsId,
    quickBooksDocType,
    isVendorVerified,
    isAddressVerified,
    vendorId,
    branchId,
    creditCardNumber,
    creditCardName,
    humanVerified,
    isRejected,
    restaurantName
  } = useAppStore();

  const [fetchingVendor, setFetchingVendor] = useState(false);
  const [progress, setProgress] = useState(25);

  const handleViewVendorDetails = async () => {
    setFetchingVendor(true);
    setProgress(50);
    try {
      const response = await axiosInstance.post(
        `/get_vendor_id_by_document_uuid`,
        {
          document_uuids: [invoiceUUID],
        }
      );
      const vendorId = response.data?.document_uuid_to_vendor_id[invoiceUUID];
      setProgress(100);
      if (vendorId) {
        setFetchingVendor(false);
        window.open(
          `/vendor-consolidation/${vendorId}`,
          "_blank",
          "noopener,noreferrer"
        );
      } else {
        setFetchingVendor(false);
        toast.error("No vendor details found for this invoice");
      }
    } catch (error) {
      setFetchingVendor(false);
      toast.error("No vendor details found for this invoice");
    }
  };

  function handleEmptyValues(x) {
    if (
      x === null ||
      (typeof x === "string" && x.trim() === "") ||
      (Array.isArray(x) && x.length === 0) ||
      x === "NA"
    ) {
      return "NA";
    } else {
      return x;
    }
  }

  return (
    <div
      style={{
        width: "100%",
        height: "471px",
        overflowX: "auto",
        overflowY: "auto",
      }}
    >
      <table className="table table-bordered">
        <tbody>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Invoice Number{" "}
            </th>
            <td>
              {handleEmptyValues(invoiceNum)}
              {humanVerified === true && isRejected === false && (
                <span class="mx-2 badge bg-success">Accepted </span>
              )}
              {isRejected === true && (
                <span class="mx-2 badge bg-danger">Rejected </span>
              )}
              {
                humanVerified === false && isRejected === false && (
                  <span class="mx-2 badge bg-warning">Pending </span>
                )
              }
            </td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Invoice Type
            </th>
            <td>{handleEmptyValues(invoiceType)}</td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Invoice Date
            </th>
            <td>
              {typeof invoiceDate === "string" || invoiceDate === null
                ? handleEmptyValues(invoiceDate)
                : invoiceDate.toISOString().split("T")[0]}
            </td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Due Date
            </th>
            <td>
              {typeof dueDate === "string" || dueDate === null
                ? handleEmptyValues(dueDate)
                : dueDate.toISOString().split("T")[0]}
            </td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Invoice Total
            </th>
            <td>{handleEmptyValues(invoiceTotal)}</td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Invoice Balance Due
            </th>
            <td>{handleEmptyValues(invoiceBalance)}</td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Restaurant Name
            </th>
            <td>
              {restaurantName === null ||
              restaurantName === "" ||
              restaurantName === "NA" ? (
                handleEmptyValues(restaurantName)
              ) : (
                <Link
                  target="_blank"
                  to={`https://crunch-client.vercel.app/restaurants/details/${restaurantsId}`}
                >
                  {handleEmptyValues(restaurantName)}
                </Link>
              )}
            </td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Vendor Names
            </th>
            <td
              onClick={handleViewVendorDetails}
              style={{
                cursor: "pointer",
                color: "blue",
                textDecoration: "underline",
              }}
              className="d-flex justify-content-center align-items-center"
            >
              {handleEmptyValues(vendorName)}
              {isVendorVerified && (
                <PatchCheckFill className="mx-3" fill="blue" />
              )}
            </td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
              className="d-flex justify-content-center align-items-center"
            >
              Restaurant Vendor Details
            </th>
            <td>
              <Link
                to={`https://crunch-client.vercel.app/restaurants/${restaurantsId}/vendors/vendor-details/${vendorId}`}
                target="_blank"
              >
                View Restaurant Vendor Details
              </Link>
            </td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Vendor Address
            </th>
            <td>
              {vendorAddress === null ||
              vendorAddress === "" ||
              vendorAddress === "NA" ? (
                <>{handleEmptyValues(vendorAddress)}</>
              ) : (
                <Link
                  to={`/vendor/${vendorId}/branch/${branchId}`}
                  target="_blank"
                >
                  {handleEmptyValues(vendorAddress)}
                  {isAddressVerified && (
                    <PatchCheckFill className="mx-3" fill="blue" />
                  )}
                </Link>
              )}
            </td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Vendor Phone Number
            </th>
            <td>{handleEmptyValues(vendorPhone)}</td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Document Type Prediction
            </th>
            <td>{handleEmptyValues(documentTypePrediction)}</td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Verdict
            </th>
            <td>{handleEmptyValues(verdict)}</td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Concerns
            </th>
            <td>
              <ul>
                {handleEmptyValues(concerns) === "NA" ? (
                  <p>NA</p>
                ) : (
                  <ul>
                    {concerns.map((concern, index) => (
                      <li key={index}>{concern}</li>
                    ))}
                  </ul>
                )}
              </ul>
            </td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Human Verification Required
            </th>
            <td>
              {handleEmptyValues(humanVerificationReqd) === "NA" ? (
                <p>NA</p>
              ) : (
                <p>{humanVerificationReqd ? "Yes" : "No"}</p>
              )}
            </td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Quick Books Document Type
            </th>
            <td>{handleEmptyValues(quickBooksDocType)}</td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Taxes Added
            </th>
            <td>
              {handleEmptyValues(extraChargesAdded) === "NA" ? (
                <p>NA</p>
              ) : (
                <ul>
                  {extraChargesAdded.map((charge, index) => (
                    <li key={index}>{charge}</li>
                  ))}
                </ul>
              )}
            </td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Extra Fees Added
            </th>
            <td>
              {handleEmptyValues(extraFeesAdded) === "NA" ? (
                <p>NA</p>
              ) : (
                <ul>
                  {extraFeesAdded.map((charge, index) => (
                    <li key={index}>{charge}</li>
                  ))}
                </ul>
              )}
            </td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Extra Discounts Added
            </th>
            <td>
              <ul style={{ textTransform: "capitalize" }}>
                {handleEmptyValues(extraDiscountsAdded) === "NA" ? (
                  <p>NA</p>
                ) : (
                  <ul>
                    {extraDiscountsAdded.map((discount, index) => (
                      <li key={index}>{discount}</li>
                    ))}
                  </ul>
                )}
              </ul>
            </td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Invoice Payment Terms
            </th>
            <td>{handleEmptyValues(invoicePaymentTerms)}</td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Credit Card Name
            </th>
            <td>{handleEmptyValues(creditCardName)}</td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Credit Card Number
            </th>
            <td>{handleEmptyValues(creditCardNumber)}</td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Invoice Ship To
            </th>
            <td>{handleEmptyValues(invoiceShipTo)}</td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Invoice Bill To
            </th>
            <td>{handleEmptyValues(invoiceBillTo)}</td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Invoice Sold To
            </th>
            <td>{handleEmptyValues(invoiceSoldTo)}</td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Total No. of Pages Received
            </th>
            <td>{handleEmptyValues(totalPagesRcvd)}</td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Invoice Total From Table
            </th>
            <td>{handleEmptyValues(invoiceTotalFromtable)}</td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Invoice UUID
            </th>
            <td>{handleEmptyValues(invoiceUUID)}</td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Version
            </th>
            <td>{handleEmptyValues(version)}</td>
          </tr>
          <tr>
            <th
              style={{
                width: "200px",
                backgroundColor: "#FFF2CD",
                textTransform: "capitalize",
              }}
            >
              Rerun Status
            </th>
            <td>
              {handleEmptyValues(rerunStatus) === "NA" ? (
                <p>NA</p>
              ) : (
                <p>{rerunStatus ? "Rerun" : "Not Rerun"}</p>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
