import { create } from "zustand";
// import { createWithEqualityFn } from "zustand/traditional";
import { immer } from "zustand/middleware/immer";

const useStatisticsStore = create(
  immer((set) => ({
    selectedVendor: null,
    tableFilter: { id: "all_invoice_list", name: "All Invoices" },
    activeTab: "all",
    allPagination: {
      tempValue: 1,
      pageNumber: 1,
      setPageNumber: (pageNumber) =>
        set((state) => {
          state.allPagination.pageNumber = pageNumber;
        }),
      setTempValue: (tempValue) =>
        set((state) => {
          state.allPagination.tempValue = tempValue;
        }),
    },
    vendorPagination: {
      tempValue: 1,
      pageNumber: 1,
      setPageNumber: (pageNumber) =>
        set((state) => {
          state.vendorPagination.pageNumber = pageNumber;
        }),
      setTempValue: (tempValue) =>
        set((state) => {
          state.vendorPagination.tempValue = tempValue;
        }),
    },
    actions: {
      setSelectedVendor: (selectedVendor) => set({ selectedVendor }),
      setTableFilter: (tableFilter) => set({ tableFilter }),
      setActiveTab: (activeTab) => set({ activeTab }),
    },
  }))
);

// This hook will not trigger re-render even if any of the state change
export const useStatisticsActions = () =>
  useStatisticsStore((state) => state.actions);

export default useStatisticsStore;
