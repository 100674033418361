import React from "react";
import { Nav, Tab } from "react-bootstrap";
import { ToastContainer } from "react-toastify";

export default function Header({vendorName}) {
  return (
    <>
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey="all"
        variant="pills"
        justify
      >
        <Nav
          variant="pills"
          style={{
            display: "flex",
            justifyContent: "between",
            alignItems: "left",
            marginTop: "100px",
            gap: "10px",
          }}
          className="d-flex bg-nav p-2 rounded-2 align-items-center mx-5"
        >
          <div className="col-sm-2" />
          <Nav.Item>
            <Nav.Link
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Re-Run Invoices {vendorName === "NA" ? "" : `for ${vendorName}` }
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content></Tab.Content>
      </Tab.Container>
      <ToastContainer />
    </>
  );
}
