import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

export const verificationFilterOptions = [
  "All",
  "Human Verification Required",
  "Human Verification Not Required",
];
export const verificationFilterMap = {
  All: "both",
  "Human Verification Required": "true",
  "Human Verification Not Required": "false",
};
export const detectionFilterOptions = [
  "All",
  "Detected",
  "Undetected",
];
export const detectionFilterMap = {
  All: "all",
  "Detected": "detected",
  "Undetected": "undetected",
};
export const rerunFilterOptions = [
  "All",
  "Rerun",
  "Not Rerun",
];
export const rerunFilterMap = {
  All: "both",
  "Rerun": "true",
  "Not Rerun": "false",
};

export const invoiceFilterOptions = [
  "All",
  "Normal Invoice",
  "Liquor Invoice",
  "Summary Invoice",
];
export const invoiceFilterMap = {
  All: "all",
  "Normal Invoice": "normal_invoice",
  "Liquor Invoice": "liquor_invoice",
  "Summary Invoice": "summary_invoice",
};

export const autoAcceptedFilterOptions = [
  "true" ,
  "false",
  "both",
]

export const autoAcceptedFilterMap = {
  "true": "true",
  "false": "false",
  "both": "both",
}

const useGlobalFilterStore = createWithEqualityFn(
  (set) => ({
    verified: "both",
    verification: "All",
    invoiceType: "All",
    invoiceDetectionStatus: "All",
    invoiceRerunStatus: "All",
    dateRange: [
        {
          startDate: null,
          endDate: null,
          key: 'selection'
        }
      ],
    vendorName: null,
    restaunrantId: null,
    autoAcceptedFilter: "both",
    actions: {
      setVerified: (verified) => set({ verified }),
      setVerification: (verification) => set({ verification }),
      setInvoiceType: (invoiceType) => set({ invoiceType }),
      setDateRange: (dateRange) => set({dateRange}),
      setVendorName: (vendorName) => set({vendorName}),
      setInvoiceDetectionStatus: (invoiceDetectionStatus) => set({invoiceDetectionStatus}),
      setInvoiceRerunStatus: (invoiceRerunStatus) => set({invoiceRerunStatus}),
      setRestaurantId: (restaurantId) => set({restaurantId}),
      setAutoAcceptedFilter: (autoAcceptedFilter) => set({autoAcceptedFilter}),
    },
  }),
  Object.is
);

// This hook will not trigger re-render even if any of the state change
export const useGlobalFilterActions = () =>
  useGlobalFilterStore((state) => state.actions);

export const useGlobalFilterParams = () =>
  useGlobalFilterStore(
    (state) => ({
      human_verified: state.verified,
      human_verification: verificationFilterMap[state.verification] || "both",
      invoice_detection_status: detectionFilterMap[state.invoiceDetectionStatus] || "all",
      rerun_status: rerunFilterMap[state.invoiceRerunStatus] || "all",
      invoice_type: invoiceFilterMap[state.invoiceType] || "all",
      start_date: state.dateRange[0].startDate?.toLocaleDateString('en-CA') || null ,
      end_date: state.dateRange[0].endDate?.toLocaleDateString('en-CA') || null,
      vendor_name: state.vendorName || null,
      restaurant_id: state.restaurantId || null,
      auto_accepted: autoAcceptedFilterMap[state.autoAcceptedFilter] || "both",
    }),
    shallow
  );
export default useGlobalFilterStore;
