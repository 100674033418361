import { getToken } from "components/invoicererun/api";
import { API_URL } from "config/env";
import { axiosInstance } from "lib/axios";
import {useQuery} from "@tanstack/react-query";
import { queryClient } from "lib/react-query";

export const useGetEngineeringIssues = (documentUUID) => {
  return useQuery({
    queryKey: ["engineeringIssues", documentUUID],
    queryFn: async () => {
      try {
        const body = {
          document_uuid: documentUUID,
        };
        
        const response = await axiosInstance.post(
          `/engineering_issues/get_invoice_issue`,
          body
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.ensureQueryData(["engineeringIssues", documentUUID], data);
    },
  });
};

const getCountByCategory = async (filters) => {
  const response = await axiosInstance.post(
    `${API_URL}/get_count_per_category`,
    filters
  );
  return response.data;
};

const changeStatus = async (uuid, status) => {
  const payload = {
    uuid: uuid,
    status: status
  };
  const response = await axiosInstance.post(
    `${API_URL}/change_engineering_review_status`,
    payload
  );
  return response.data;
};

const addCategory = async (uuid, category) => {
  const payload = {
    uuid: uuid,
    category: category
  };
  const response = await axiosInstance.post(
    `${API_URL}/add_category_to_engineering_review`,
    payload
  );
  return response.data;
};

const getCommonCategories = async () => {
  const response = await axiosInstance.get(`${API_URL}/get_common_categories`
  );
  return response.data;
};

const addCommonCategory = async (category) => {
  const payload = {
    category: category,
    category_description: "null"
  };
  const response = await axiosInstance.post(`${API_URL}/add_category`);
  return response.data;
};

const getCommonIssues = async () => {
  const response = await axiosInstance.get(`${API_URL}/get_common_issues`);
  return response.data;
};

const addComment = async (uuid, comment) => {
  const payload = {
    uuid: uuid,
    comment: comment
  };
  const response = await axiosInstance.post(
    `${API_URL}add_comment_to_engineering_review`,
    payload
  );
  return response.data;
};
export {
  getCountByCategory,
  changeStatus,
  addCategory,
  getCommonCategories,
  addCommonCategory,
  getCommonIssues,
  addComment
};
