import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "lib/axios";
export const useGetInvoiceAssignment = () => {
  return useQuery({
    queryKey: ["assignedInvoices", localStorage.getItem("username")],
    queryFn: async () => {
      const response = await axiosInstance.get("get_invoice_balancer_reports");
      return response.data
    },

  });
};
