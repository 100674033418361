import { Button, Form, InputGroup } from "react-bootstrap";
import styles from "./InvoiceComponent.module.css";
import useAppStore from "store/global";
import {
  CurrencyDollar,
  DashCircleFill,
  DashLg,
  Plus,
} from "react-bootstrap-icons";

export const InvoiceComponent = ({
  taxes,
  setExtraChargesAdded,
  extraChargesAdded,
  discounts,
  extraDiscountsAdded,
  setExtraDiscountsAdded,
  calculatedSum,
  invoiceTotalFromtable,
  invoiceTotal,
  setInvoiceTotal,
  sum,
  extraDiscountsSum,
  invoiceTaxesSum,
  extraFeesSum,
  editFees,
  setEditFees,
  addFee,
  setAddFee,
  handleFeeChange,
  fees,
  setFees,
  newFee,
  handleInputChangeFeeAddition,
  addNewFee,
  extraFeesAdded,
  setExtraFeesAdded,
}) => {
  const addTax = () => {
    const newTaxes = [...extraChargesAdded];
    newTaxes.push([0]);
    setExtraChargesAdded(newTaxes);
  };

  const removeTax = (index) => {
    const newTaxes = [...extraChargesAdded];
    newTaxes.splice(index, 1);
    setExtraChargesAdded(newTaxes);
  };

  const addDiscount = () => {
    const newDiscounts = [...extraDiscountsAdded];
    newDiscounts.push(0);
    setExtraDiscountsAdded(newDiscounts);
  };

  const removeDiscount = (index) => {
    const newDiscounts = [...extraDiscountsAdded];
    newDiscounts.splice(index, 1);
    setExtraDiscountsAdded(newDiscounts);
  };

  const addNewFeeAmt = () => {
    const newFees = [...extraFeesAdded];
    newFees.push(0);
    setExtraFeesAdded(newFees);
  };

  const removeFee = (index) => {
    const newFees = [...extraFeesAdded];
    newFees.splice(index, 1);
    setExtraFeesAdded(newFees);
  };

  const { invoiceType } = useAppStore();
  return (
    <div className={styles.card}>
      {invoiceType !== "Summary Invoice" ? (
        <>
          <div className={styles.button_row}>
            <Button
              disabled={Number(taxes.at(-1)) === 0}
              variant="success"
              onClick={addTax}
            >
              Add Tax
            </Button>
            <Button
              disabled={Number(discounts.at(-1)) === 0}
              variant="warning"
              onClick={addDiscount}
            >
              Add Discount
            </Button>
            <Button
              disabled={Number(fees.at(-1)) === 0}
              variant="info"
              onClick={addNewFeeAmt}
            >
              Add Fee
            </Button>
          </div>
          <div className={styles.grid_container}>
            <div className={styles.label}>Subtotal</div>
            <div className={styles.value}>
              <InputGroup>
                <InputGroup.Text>
                  <CurrencyDollar />
                </InputGroup.Text>
                <Form.Control type="number" disabled value={sum} />
              </InputGroup>
            </div>

            <div className={styles.label}>Taxes</div>
            {taxes.map((tax, index) => (
              <>
                {index !== 0 && <div className={styles.label} />}
                <div className={styles.value}>
                  <InputGroup>
                    <InputGroup.Text>
                      <Plus />
                    </InputGroup.Text>
                    <Form.Control
                      type="number"
                      min={0}
                      max={100}
                      step="0.01"
                      value={tax}
                      onChange={(e) => {
                        const newTaxes = [...extraChargesAdded];
                        newTaxes[index] = e.target.value;
                        setExtraChargesAdded(newTaxes);
                      }}
                    />
                  </InputGroup>

                  <button
                    onClick={() => removeTax(index)}
                    disabled={index === 0}
                    className={`border-0 bg-transparent ${
                      index === 0 ? "invisible" : "visible"
                    }`}
                  >
                    <DashCircleFill height={20} width={20} color="red" />
                  </button>
                </div>
              </>
            ))}

            <div className={styles.label}>Fees</div>
            {fees.map((fee, index) => (
              <>
                {index !== 0 && <div className={styles.label} />}
                <div className={styles.value}>
                  <InputGroup>
                    <InputGroup.Text>
                      <Plus />
                    </InputGroup.Text>
                    <Form.Control
                      type="number"
                      min={0}
                      max={100}
                      step="0.01"
                      value={fee}
                      onChange={(e) => {
                        const newFee = [...extraFeesAdded];
                        newFee[index] = e.target.value;
                        setExtraFeesAdded(newFee);
                      }}
                    />
                  </InputGroup>

                  <button
                    onClick={() => removeFee(index)}
                    disabled={index === 0}
                    className={`border-0 bg-transparent ${
                      index === 0 ? "invisible" : "visible"
                    }`}
                  >
                    <DashCircleFill height={20} width={20} color="red" />
                  </button>
                </div>
              </>
            ))}

            <div className={styles.label}>Discounts</div>
            {discounts.map((tax, index) => (
              <>
                {index !== 0 && <div className={styles.label} />}
                <div className={styles.value}>
                  <InputGroup>
                    <InputGroup.Text>
                      <DashLg />
                    </InputGroup.Text>
                    <Form.Control
                      type="number"
                      min={0}
                      max={100}
                      step="0.01"
                      value={tax}
                      onChange={(e) => {
                        const newDiscounts = [...extraDiscountsAdded];
                        newDiscounts[index] = e.target.value;
                        setExtraDiscountsAdded(newDiscounts);
                      }}
                    />
                  </InputGroup>
                  <button
                    onClick={() => removeDiscount(index)}
                    disabled={index === 0}
                    className={`border-0 bg-transparent ${
                      index === 0 ? "invisible" : "visible"
                    }`}
                  >
                    <DashCircleFill height={20} width={20} color="red" />
                  </button>
                </div>
              </>
            ))}
          </div>

          <div className={styles.divider} />

          <div className={styles.grid_container}>
            <div className={styles.label}>Total</div>
            <div className={styles.value}>
              <InputGroup>
                <InputGroup.Text>
                  <CurrencyDollar />
                </InputGroup.Text>
                <Form.Control
                  type="number"
                  disabled
                  className={` ${
                    calculatedSum - invoiceTotal > 0 ||
                    calculatedSum - invoiceTotal < -0
                      ? "text-danger fw-bolder border-danger "
                      : "text-success fw-bolder border-success "
                  }`}
                  value={calculatedSum}
                  width={"100%"}
                />
              </InputGroup>
            </div>

            <div className={styles.label}>Extracted Total</div>
            <div className={styles.value}>
              <InputGroup>
                <InputGroup.Text>
                  <CurrencyDollar />
                </InputGroup.Text>
                <Form.Control
                  type="number"
                  onChange={(e) => {
                    setInvoiceTotal(e.target.value);
                  }}
                  className={`form-control ${
                    calculatedSum - invoiceTotal > 0 ||
                    calculatedSum - invoiceTotal < -0
                      ? "text-danger fw-bolder border-danger"
                      : "text-success fw-bolder border-success"
                  }`}
                  value={invoiceTotal}
                  width={"100%"}
                />
              </InputGroup>
            </div>
          </div>

          <div className={styles.divider} />
          <div className={styles.grid_container}>
            <div className={styles.label}>Difference</div>
            <div className={styles.value}>
              <InputGroup>
                <InputGroup.Text>
                  <CurrencyDollar />
                </InputGroup.Text>
                <Form.Control
                  type="number"
                  disabled
                  className={
                    invoiceTotal - calculatedSum > 0 ||
                    invoiceTotal - calculatedSum < -0
                      ? "text-danger fw-bolder border-danger"
                      : "text-success fw-bolder border-success"
                  }
                  value={(
                    invoiceTotal -
                    (sum - extraDiscountsSum + invoiceTaxesSum + extraFeesSum)
                  ).toFixed(2)}
                  width={"100%"}
                />
              </InputGroup>
            </div>
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-center">
          <div className="mx-2">Extracted Total</div>
          <div className="mx-2">
            <InputGroup>
              <InputGroup.Text>
                <CurrencyDollar />
              </InputGroup.Text>
              <Form.Control
                type="number"
                onChange={(e) => {
                  setInvoiceTotal(e.target.value);
                }}
                className={`form-control ${
                  calculatedSum - invoiceTotal > 0 ||
                  calculatedSum - invoiceTotal < -0
                    ? "text-danger fw-bolder border-danger"
                    : "text-success fw-bolder border-success"
                }`}
                value={invoiceTotal}
                width={"100%"}
              />
            </InputGroup>
          </div>
        </div>
      )}
    </div>
  );
};
