import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const ColorIndications = () => {
  const colorMeanings = [
    { color: "red", meaning: "Failed" },
    { color: "green", meaning: "Success" },
    { color: "orange", meaning: "Failed Due to Different Document Category" },
    { color: "#FFEF00", meaning: "Failed Due to Page Missing" },
  ];

  return (
    <Container>
      <div className="d-flex align-items-center mb-2 justify-content-end">
        <Row>
          {colorMeanings.map((item, index) => (
            <span
              key={index}
              xs={6}
              className="col-1 d-flex text-center"
              style={{ width: "220px" }}
            >
              <span
                className="mx-1"
                style={{
                  backgroundColor: item.color,
                  width: "15px",
                  height: "15px",
                  minWidth: "15px",
                }}
              ></span>
              <div
                className="text-muted mb-0"
                style={{ fontSize: "12px", verticalAlign: "top" }}
              >
                {item.meaning}
              </div>
            </span>
          ))}
        </Row>
      </div>
    </Container>
  );
};

export default ColorIndications;
