import { ColorRing } from "react-loader-spinner";

export const Spinner = () => (
  <ColorRing
    visible={true}
    height="80"
    width="80"
    ariaLabel="blocks-loading"
    wrapperStyle={{}}
    wrapperClass="blocks-wrapper"
    colors={["#F57E37", "#1BBEE9", "#F57E37", "#1BBEE9", "#F57E37", "#1BBEE9"]}
  />
);
