import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { axiosInstance } from "lib/axios";
import { Button, Col, Container, Row } from "react-bootstrap";
import { NavHeader } from "components/layout/NavHeader";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import {
  ArrowClockwise,
  ArrowCounterclockwise,
  ZoomIn,
  ZoomOut,
  Download,
} from "react-bootstrap-icons";
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const PdfViewer = ({ pdfList, title }) => {
  const [currentPdfIndex, setCurrentPdfIndex] = useState(0);
  const handleNextPdf = () => {
    setCurrentPdfIndex((prevIndex) => (prevIndex + 1) % pdfList.length);
  };


  const [pdfScale, setPdfScale] = useState(1.0);
  const [rotation, setRotation] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [numPages, setNumPages] = useState(0);

  function changePage(offset) {
    const newPageNum = pageNum + offset;
    setPageNum(newPageNum);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNum(1);
  }

  return (
    <Container>
      <h3>{title} PDFs</h3>
      <Row>
        <Col>
          {pdfList[currentPdfIndex]?.source === "azure_blob" || pdfList[currentPdfIndex]?.source === "clickbacon" && (
            <div
              className="ml-50 p-2 rounded"
              style={{ zIndex: "50", backgroundColor: "rgb(240, 240, 240)" }}
            >
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ gap: "16px" }}
              >
                <ZoomIn
                  height={20}
                  width={20}
                  disabled={pdfScale >= 8}
                  className="cursor-pointer"
                  onClick={() => setPdfScale((s) => s * 2)}
                />
                <ZoomOut
                  height={20}
                  width={20}
                  disabled={pdfScale <= 0.1}
                  className="cursor-pointer"
                  onClick={() => setPdfScale((s) => s / 2)}
                />
                <button
                  type="button"
                  disabled={pageNum <= 1}
                  onClick={previousPage}
                  className="btn btn-sm btn-outline-secondary"
                >
                  Previous
                </button>
                <button
                  type="button"
                  disabled={pageNum >= numPages}
                  onClick={nextPage}
                  className="btn btn-sm btn-outline-secondary"
                >
                  Next
                </button>
                <ArrowClockwise
                  height={20}
                  width={20}
                  className="cursor-pointer"
                  onClick={() => setRotation((r) => (r === 270 ? 0 : r + 90))}
                />
                <ArrowCounterclockwise
                  height={20}
                  width={20}
                  className="cursor-pointer"
                  onClick={() => setRotation((r) => (r === 0 ? 270 : r - 90))}
                />
                <div>
                  <span>
                    Page {pageNum} of {numPages}
                  </span>
                  <Download
                    height={20}
                    width={20}
                    className="cursor-pointer mx-4"
                    onClick={() =>
                      window.open(pdfList[currentPdfIndex]?.link, "_blank")
                    }
                  />
                </div>
              </div>
            </div>
          )}
          <div style={{ height: "580px", overflow: "auto" }}>
            {pdfList[currentPdfIndex]?.source === "azure_blob" || pdfList[currentPdfIndex]?.source === "clickbacon" ? (
              <>
                <Document
                  file={pdfList[currentPdfIndex]?.link}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page
                    pageNumber={pageNum}
                    scale={pdfScale}
                    rotate={rotation}
                  />
                </Document>
              </>
            ) : (
              <>
                <iframe
                  title={`${title} PDF`}
                  src={`${pdfList[currentPdfIndex]?.link?.replace(
                    "uc?id=",
                    "file/d/"
                  )}/preview?embedded=true`}
                  width="100%"
                  height="570"
                />
              </>
            )}
          </div>
          <Button
            onClick={handleNextPdf}
            className="mt-3"
            disabled={pdfList?.length === 1}
          >
            Next PDF
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default function InvoiceComparison() {
  const { actualVendorName, similarVendorName } = useParams();
  const [data, setData] = useState([]);
  const getInvoicePdfs = async () => {
    const body = {
      mode: "vendor_name",
      vendors: [actualVendorName, similarVendorName],
    };
    const response = await axiosInstance.post("/get_vendor_pdfs", body);
    if (response.status === 200) {
      setData(response.data.pdfs);
    }
  };
  useEffect(() => {
    getInvoicePdfs();
  }, []);

  return (
    <>
      <NavHeader />
      <div
        className="mx-5"
        style={{
          marginTop: "120px",
        }}
      >
        <Row>
          {Object.keys(data).map((category) => (
            <Col key={category}>
              <PdfViewer pdfList={data[category]} title={category} />
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}
