import React, { useState, useEffect } from "react";
import { NavHeader } from "components/layout/NavHeader";
import Header from "./components/Header";
import { findSimilarVendors, combineVendors } from "./api";
import { useParams } from "react-router-dom";
import VendorCombineTable from "./components/VendorCombineTable";
import { Spinner } from "components/common/Spinner";
import { ToastContainer, toast } from "react-toastify";

export default function VendorCombine() {
  const [loading, setLoading] = useState(false);
  const [similarVendors, setSimilarVendors] = useState([]);
  const [actualVendorName, setActualVendorName] = useState("");
  const [similarity, setSimilarity] = useState(50);
  const { vendorID } = useParams();

  const getSimilarVendors = () => {
    setLoading(true);
    findSimilarVendors(similarity, vendorID)
      .then((res) => {
        setSimilarVendors(res.vendor_datas);
        setActualVendorName(res.actual_vendor_name);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleCombineVendors = (selectedVendorIds) => {
    setLoading(true);
    const data = {
      actual_vendor_name: actualVendorName,
      actual_vendor_id: vendorID,
      selected_vendor_ids: selectedVendorIds,
    };
    combineVendors(data)
      .then((res) => {
        toast.success(res.message);
        getSimilarVendors();
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Error combining vendors");
        setLoading(false);
      });
  };

  useEffect(() => {
    getSimilarVendors();
  }, []);

  return (
    <>
      <NavHeader view="Combine Vendors" />
      <Header text={"Combine Vendors"} />
      <ToastContainer/>
      {loading && (
         <div className="d-flex justify-content-center h-100"
         style={{
           marginTop: "85px",
         }}
         >
           <Spinner/>
           
         </div>
      )}
      {!loading && (
        <>
          <VendorCombineTable
            vendorData={similarVendors}
            actualVendorName = {actualVendorName}
            setActualVendorName = {setActualVendorName} 
            handleCombineVendors={handleCombineVendors}
          />
          <div className="my-4 container">
            <label>Similarity : {similarity} </label>
            <input
              class="form-range d-block mx-auto my-2 bg-secondary"
              type="range"
              value={similarity}
              onChange={(e) => setSimilarity(parseInt(e.target.value))}
              min={0}
              max={100}
              step="1"
              style={{ width: "50%" }}
            />
          </div>
          <div className="my-4 container">
            <button
              type="button"
              className="btn btn-info d-block mx-auto my-2"
              onClick={getSimilarVendors}
              style={{ width: "50%" }}
            >
              Find Similar Vendors
            </button>
          </div>
        </>
      )}
    </>
  );
}
