import { create } from "zustand";

const useHomePagination = create((set) => ({
  tempValue: 1,
//   totalInvoices: 0,
  pageNumber: 1,
  actions: {
    setPageNumber: (pageNumber) => set({ pageNumber }),
    setTempValue: (tempValue) => set({ tempValue }),
    // setTotalInvoices: (totalInvoices) => set({ totalInvoices }),
  },
}));

// This hook will not trigger re-render even if any of the state change
export const useHomePaginationActions = () =>
  useHomePagination((state) => state.actions);

export default useHomePagination;
