import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { formatDate } from "utils/format";
import { useGlobalFilterActions } from "store/globalFIlter";
import { useNavigate } from "react-router-dom";
import { Trash3Fill } from "react-bootstrap-icons";
import { Modal } from "react-bootstrap";
import { PatchCheckFill } from "react-bootstrap-icons";

export default function VendorTable({ data, handleDeleteVendor }) {
  const navigate = useNavigate();
  const { setVendorName } = useGlobalFilterActions();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [vendorIdToDelete, setVendorIdToDelete] = useState(null);
  const [vendorNameToDelete, setVendorNameToDelete] = useState(null);
  const handleViewInvoice = (vendorName) => {
    setVendorName(vendorName);
    navigate("/details");
  };
  return (
    <div
      className="mx-5"
      style={{
        marginTop: "20px",
      }}
    >
      <Table
        striped
        bordered
        hover
        variant="light"
        responsive
        className="shadow-sm"
      >
        <thead>
          <tr>
            <th>Vendor Name</th>
            <th>Vendor Category</th>
            <th>Created Date</th>
            <th>Document Count</th>
            <th>Branch Count</th>
            <th>Verified Branch Count</th>
            <th>Item Count</th>
            <th>Verified Item Count</th>
            <th>Verified By</th>
            <th>View Invoice</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>
                <Link
                  to={`/vendor-consolidation/${item.vendor_id}`}
                  style={{
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  {item.vendor_name} {item.human_verified && <PatchCheckFill fill="blue" />}
                </Link>
              </td>
              <td>{item.vendor_category}</td>
              <td>{formatDate(item.created_date)}</td>
              <td>{item.document_count}</td>
              <td>{item.branch_count}</td>
              <td>{item.verified_branch_count}</td>
              <td>{item.item_count}</td>
              <td>{item.verified_item_count}</td>
              <td>{item.verified_by}</td>
              <td>
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  style={{
                    backgroundColor: "#2d2d2d",
                    borderColor: "#2d2d2d",
                  }}
                  onClick={() => handleViewInvoice(item.vendor_name)}
                >
                  View
                </button>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-sm btn-danger"
                  onClick={() => {
                    setVendorIdToDelete(item.vendor_id);
                    setVendorNameToDelete(item.vendor_name);
                    setShowDeleteModal(true);
                  }}
                >
                  <Trash3Fill />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal
        show={showDeleteModal}
        onHide={() => {
          setVendorIdToDelete(null);
          setVendorNameToDelete(null);
          setShowDeleteModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Vendor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this vendor?
          <table className="table">
            <tbody>
              <tr>
                <td>Vendor Name</td>
                <td>{vendorNameToDelete}</td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              setVendorIdToDelete(null);
              setVendorNameToDelete(null);
              setShowDeleteModal(false);
            }}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handleDeleteVendor(vendorIdToDelete)}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
