import { SimpleBarChart } from "@carbon/charts-react";
import { useInvoiceStatistics } from "../api";
import "@carbon/charts-react/styles.css";
import { useWindowSize } from "usehooks-ts";
import useGlobalFilterStore from "store/globalFIlter";
import useStatisticsStore from "../store";

const truncation = ({ width, height }) => {
  let threshold = 25,
    numCharacter = 20;
  if (width < 1200) {
    threshold = 20;
    numCharacter = 16;
  }
  return {
    type: "end_line", //"none"
    threshold,
    numCharacter
  };
};

const options = (loading, size, data) => {
  let height = "300px";
  if (size.width < 900) {
    height = "400px";
  }

  return {
    title: "Statistics",
    axes: {
      left: {
        mapsTo: "value"
      },
      bottom: {
        scaleType: "labels",
        mapsTo: "group",
        truncation: truncation(size)
      }
    },
    data: {
      loading: Boolean(loading)
    },
    legend: {
      alignment: "center",
      truncation: { type: "none" }
    },
    toolbar: {
      enabled: true,
      numberOfIcons: 3,
      controls: [
        { type: "Show as data-table" },
        { type: "Make fullscreen" },
        { type: "Export as CSV" },
        { type: "Export as JPG" },
        { type: "Export as PNG" }
      ]
    },
    height,
    tooltip: {
      customHTML: (model) => {
        if (model && model.length > 0) {
          const data = model[0];
          return `<div style="font-size: 12px;">
                    <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                      <p>X-Value:</p>
                      <p>${data.group}</p>
                    </div>
                    <hr style="margin: 4px 0;">
                    <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                      <p>Y-Value:</p>
                      <p>${data.value}</p>
                    </div>
                    <hr style="margin: 4px 0;">
                    <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                      <p>Group:</p>
                      <p>${data.key}</p>
                    </div>
                    <hr style="margin: 4px 0;">
                    <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                      <p>Percentage:</p>
                      <p>${data.percentage}</p>
                    </div>
                  </div>`;
        } else {
          return "Tooltip data not available";
        }
      }
    }
    
  };
};

export const BarChart = () => {
  const {
    verification,
    invoiceType,
    dateRange,
    invoiceDetectionStatus,
    verified,
    invoiceRerunStatus
  } = useGlobalFilterStore();
  const activeTab = useStatisticsStore((state) => state.activeTab);
  const allPagination = useStatisticsStore((state) => state.allPagination);
  const vendorName = useGlobalFilterStore((state) => state.vendorName);
  const { data, isPending, isError, error } = useInvoiceStatistics(
    allPagination.pageNumber,
    invoiceType,
    dateRange,
    vendorName,
    verification,
    invoiceDetectionStatus,
    verified,
    invoiceRerunStatus
  );
  console.log(data);
  const size = useWindowSize();

  if (isError) {
    return error?.message;
  }

  return (
    <div className="px-5 col-xl-8 mx-auto py-2" style={{ minWidth: "500px" }}>
      <SimpleBarChart
        data={data || []}
        options={options(isPending, size, data)}
      ></SimpleBarChart>
    </div>
  );
};
