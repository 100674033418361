import React, { useEffect } from "react";
import "@carbon/charts/styles.css";
import { SimpleBarChart } from "@carbon/charts-react";
import EngIssuesNewTable from "components/engissues/engIssuesNewTable";
import { Spinner } from "components/common/Spinner";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Modal, Table } from "react-bootstrap";
import { useFetchVerifiedVendorData, useFetchVerifiedItemData } from "./api";
import { Pagination } from "react-bootstrap";
const transformProcessingStats = (stats) => {
  return Object.keys(stats).map((key) => ({
    group: key.replace(/_/g, " "),
    key: key.replace(/_/g, " "),
    value: stats[key],
  }));
};

const transformVerificationTimeStats = (stats) => {
  return Object.keys(stats).map((key) => ({
    group: key.replace(/_/g, " "),
    key: key.replace(/_/g, " "),
    value: stats[key],
  }));
};

const transformStatistics = (data) => {
  const invoiceProcessingData = transformProcessingStats(
    data.invoice_statistics.processing_stats
  );
  const invoiceVerificationData = transformVerificationTimeStats(
    data.invoice_statistics.verification_time_stats
  );

  const vendorProcessingData = transformProcessingStats(
    data.vendor_statistics.processing_stats
  );
  const vendorVerificationData = transformVerificationTimeStats(
    data.vendor_statistics.verification_time_stats
  );

  const itemMasterProcessingData = transformProcessingStats(
    data.item_master_statistics.processing_stats
  );
  const itemMasterVerificationData = transformVerificationTimeStats(
    data.item_master_statistics.verification_time_stats
  );

  return {
    invoiceProcessingData,
    invoiceVerificationData,
    vendorProcessingData,
    vendorVerificationData,
    itemMasterProcessingData,
    itemMasterVerificationData,
  };
};

const Dashboard = ({
  data,
  issuesData,
  userInvoiceIssuesLoading,
  userInvoiceIssuesError,
  handleDownloadAsExcel,
  convertingToExcel,
  itemsIssuesData,
  itemsIssuesLoading,
  itemsIssuesError,
  state,
}) => {
  const navigate = useNavigate();
  const chartRef = React.useRef(null);
  const chartTwoRef = React.useRef(null);
  const chartThreeRef = React.useRef(null);
  const chartFourRef = React.useRef(null);
  const chartFiveRef = React.useRef(null);
  const chartSixRef = React.useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  let pageIndex = searchParams.get("page_index") ?? 1;
  let sfilters = searchParams.get("filters") ?? "{}";
  let filters = JSON.parse(sfilters);
  let user_activity_filters = filters["user_activity_filters"] ?? null;
  let startDate = user_activity_filters?.start_date ?? null;
  let endDate = user_activity_filters?.end_date ?? null;
  const [getVerifiedVendorDataFilters, setGetVerifiedVendorDataFilters] =
    React.useState({
      page_index: 1,
      page_size: 10,
      user_uuid: searchParams.get("user_id"),
      date: {
        start_date: startDate,
        end_date: endDate,
      },
      verification_time_range: {
        start: null,
        end: null,
      },
      action: "verify",
    });
  const [getVerifiedItemDataFilters, setGetVerifiedItemDataFilters] =
    React.useState({
      page_index: 1,
      page_size: 10,
      user_uuid: searchParams.get("user_id"),
      date: {
        start_date: startDate,
        end_date: endDate,
      },
      verification_time_range: {
        start: null,
        end: null,
      },
    });
  const [showVerifiedVendorModal, setShowVerifiedVendorModal] =
    React.useState(false);
  const [showVerifiedItemModal, setShowVerifiedItemModal] =
    React.useState(false);

  pageIndex = parseInt(pageIndex);

  const {
    data: verifiedVendorData,
    isLoading: verifiedVendorDataLoading,
    isError: verifiedVendorDataError,
  } = useFetchVerifiedVendorData(
    getVerifiedVendorDataFilters.page_index,
    getVerifiedVendorDataFilters.page_size,
    searchParams.get("user_id"),
    [startDate, endDate],
    [
      getVerifiedVendorDataFilters.verification_time_range.start,
      getVerifiedVendorDataFilters.verification_time_range.end,
    ],
    getVerifiedVendorDataFilters.action,
    showVerifiedVendorModal
  );

  const {
    data: verifiedItemData,
    isLoading: verifiedItemDataLoading,
    isError: verifiedItemDataError,
  } = useFetchVerifiedItemData(
    getVerifiedItemDataFilters.page_index,
    getVerifiedItemDataFilters.page_size,
    searchParams.get("user_id"),
    [startDate, endDate],
    [
      getVerifiedItemDataFilters.verification_time_range.start,
      getVerifiedItemDataFilters.verification_time_range.end,
    ],
    showVerifiedItemModal
  );

  const {
    invoiceProcessingData,
    invoiceVerificationData,
    vendorProcessingData,
    vendorVerificationData,
    itemMasterProcessingData,
    itemMasterVerificationData,
  } = transformStatistics(data);

  const chartOptions = {
    axes: {
      left: { mapsTo: "value" },
      bottom: { mapsTo: "key", scaleType: "labels" },
    },
    height: "400px",
  };

  const updateSearchParam = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  // event listeners for bar clicks

  const chartOnClick = ({ detail }) => {
    let group = detail?.datum?.group.split(" ")[0].split("-");
    console.log("group", group);
    if (group[0] == ">5") {
      group = ["5", "null"];
    }
    let filters = {
      page_index: 1,
      pdf_index: 1,
      invoice_size: 8,
      filters: {
        human_verified: "both",
        human_verification: "both",
        invoice_detection_status: "all",
        rerun_status: "both",
        invoice_type: "all",
        start_date: null,
        end_date: null,
        vendor_name: null,
        restaurant_id: null,
      },
      from_user_activity: true,
      user_activity_filters: {
        user_uuid: searchParams.get("user_id"),
        verification_time_range: {
          start: parseInt(group[0]) * 60,
          end: group[1] != "null" ? parseInt(group[1]) * 60 : null,
        },
        action: "verify",
        date: {
          start_date: startDate ?? null,
          end_date: endDate ?? null,
        },
      },
    };
    navigate(`/home/?filters=${JSON.stringify(filters)}`);
  };

  const chartTwoOnClick = ({ detail }) => {
    let group = detail?.datum?.group.split(" ")[0];
    if (group == "verified") {
      group = "verify";
    } else if (group == "rejected") {
      group = "reject";
    }
    let filters = {
      page_index: 1,
      pdf_index: 1,
      invoice_size: 8,
      filters: {
        human_verified: "both",
        human_verification: "both",
        invoice_detection_status: "all",
        rerun_status: "both",
        invoice_type: "all",
        start_date: null,
        end_date: null,
        vendor_name: null,
        restaurant_id: null,
      },
      from_user_activity: true,
      user_activity_filters: {
        user_uuid: searchParams.get("user_id"),
        verification_time_range: {
          start: null,
          end: null,
        },
        action: group,
        date: {
          start_date: startDate ?? null,
          end_date: endDate ?? null,
        },
      },
    };
    navigate(`/home/?filters=${JSON.stringify(filters)}`);
  };

  const chartThreeOnClick = ({ detail }) => {
    let group = detail?.datum?.group.split(" ")[0].split("-");
    console.log("first", group);
    if (detail?.datum?.group.split(" ")[1] === "branches") {
      return;
    }
    if (group[0] == "verified") {
      group = ["null", "null"];
      setGetVerifiedItemDataFilters((prev) => ({
        ...prev,
        action: "verify",
      }));
    } else if (group[0] === "deleted") {
      console.log("coming here");
      group = ["null", "null"];
      setGetVerifiedVendorDataFilters((prev) => ({
        ...prev,
        action: "delete",
      }));
    } else if (group[0] == ">5") {
      group = ["5", "null"];
    }
    setGetVerifiedVendorDataFilters((prev) => ({
      ...prev,
      verification_time_range: {
        start: group[0] != "null" ? parseInt(group[0]) * 60 : null,
        end: group[1] != "null" ? parseInt(group[1]) * 60 : null,
      },
    }));
    console.log(getVerifiedVendorDataFilters);
    setShowVerifiedVendorModal(true);
  };

  const chartFourOnClick = ({ detail }) => {
    let group = detail?.datum?.group.split(" ")[0].split("-");
    if (group[0] === "deleted") {
      return;
    }
    if (group[0] == "verified") {
      group = ["null", "null"];
    } else if (group[0] == ">5") {
      group = ["5", "null"];
    }
    setGetVerifiedItemDataFilters({
      ...getVerifiedItemDataFilters,
      verification_time_range: {
        start: group[0] != "null" ? parseInt(group[0]) * 60 : null,
        end: group[1] != "null" ? parseInt(group[1]) * 60 : null,
      },
    });
    setShowVerifiedItemModal(true);
  };

  useEffect(() => {
    chartRef.current.chart.services.events.addEventListener(
      "bar-click",
      chartOnClick
    );
    chartTwoRef.current.chart.services.events.addEventListener(
      "bar-click",
      chartTwoOnClick
    );
    chartThreeRef.current.chart.services.events.addEventListener(
      "bar-click",
      chartThreeOnClick
    );
    chartFourRef.current.chart.services.events.addEventListener(
      "bar-click",
      chartThreeOnClick
    );
    chartFiveRef.current.chart.services.events.addEventListener(
      "bar-click",
      chartFourOnClick
    );
    chartSixRef.current.chart.services.events.addEventListener(
      "bar-click",
      chartFourOnClick
    );
  }, [chartRef, chartTwoRef, chartThreeRef, chartFourRef]);

  useEffect(
    () => () => {
      if (chartRef.current) {
        chartRef.current.chart.services.events.removeEventListener(
          "bar-click",
          chartOnClick
        );
      }
      if (chartTwoRef.current) {
        chartTwoRef.current.chart.services.events.removeEventListener(
          "bar-click",
          chartTwoOnClick
        );
      }
      if (chartThreeRef.current) {
        chartThreeRef.current.chart.services.events.removeEventListener(
          "bar-click",
          chartThreeOnClick
        );
      }
      if (chartFourRef.current) {
        chartFourRef.current.chart.services.events.removeEventListener(
          "bar-click",
          chartThreeOnClick
        );
      }
      if (chartFiveRef.current) {
        chartFiveRef.current.chart.services.events.removeEventListener(
          "bar-click",
          chartFourOnClick
        );
      }
      if (chartSixRef.current) {
        chartSixRef.current.chart.services.events.removeEventListener(
          "bar-click",
          chartFourOnClick
        );
      }
    },
    []
  );

  return (
    <div>
      <ToastContainer />
      <div className="mx-5 my-4 border-white rounded shadow-lg p-3">
        <h4
          style={{
            textAlign: "left",
          }}
        >
          Invoice Statistics
        </h4>
        <div className="row">
          <div className="col-md-6">
            <SimpleBarChart
              ref={chartTwoRef}
              className="mx-5"
              data={invoiceProcessingData}
              options={{
                ...chartOptions,
                title: "Invoice Processing Stats",
              }}
            />
          </div>
          <div className="col-md-6">
            <SimpleBarChart
              ref={chartRef}
              className="mx-5"
              data={invoiceVerificationData}
              options={{
                ...chartOptions,
                title: "Invoice Verification Time Stats",
              }}
            />
          </div>
        </div>
      </div>
      <div className="mx-5 my-4 border-white rounded shadow-lg p-3">
        <h4
          style={{
            textAlign: "left",
          }}
        >
          Vendor
        </h4>
        <div className="row">
          <div className="col-md-6">
            <SimpleBarChart
              ref={chartThreeRef}
              data={vendorProcessingData}
              options={{ ...chartOptions, title: "Vendor Processing Stats" }}
            />
          </div>
          <div className="col-md-6">
            <SimpleBarChart
              ref={chartFourRef}
              data={vendorVerificationData}
              options={{
                ...chartOptions,
                title: "Vendor Verification Time Stats",
              }}
            />
          </div>
        </div>
      </div>
      <div className="mx-5 my-4 border-white rounded shadow-lg p-3">
        <h4
          style={{
            textAlign: "left",
          }}
        >
          Item Master Statistics
        </h4>
        <div className="row">
          <div className="col-md-6">
            <SimpleBarChart
              ref={chartFiveRef}
              data={itemMasterProcessingData}
              options={{
                ...chartOptions,
                title: "Item Master Processing Stats",
              }}
            />
          </div>
          <div className="col-md-6">
            <SimpleBarChart
              ref={chartSixRef}
              data={itemMasterVerificationData}
              options={{
                ...chartOptions,
                title: "Item Master Verification Time Stats",
              }}
            />
          </div>
        </div>
      </div>

      <div className="mx-5 my-4 border-white rounded shadow-lg p-3">
        <div className="d-flex mb-1 justify-content-between">
          <h4
            style={{
              textAlign: "left",
            }}
          >
            User Invoice Issues
          </h4>
          <button
            className="btn btn-sm btn-success"
            disabled={
              convertingToExcel ||
              userInvoiceIssuesLoading ||
              !issuesData ||
              issuesData["issues"]?.length === 0
            }
            onClick={handleDownloadAsExcel}
          >
            {convertingToExcel ? "Downloading..." : "Download as Excel"}
          </button>
        </div>
        {userInvoiceIssuesLoading && <Spinner />}
        {userInvoiceIssuesError && <div>Something went wrong</div>}
        {issuesData && (
          <EngIssuesNewTable
            data={issuesData}
            view="issues"
            scrollable={false}
            desiredOrder={[
              "vendor_name",
              "document_uuid",
              "tag",
              "issue",
              "status",
              "username",
              "created_date",
              "last_modified_date",
            ]}
          />
        )}
        {issuesData["issues"]?.length > 0 && (
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-end">
              <li
                class="page-item"
                onClick={() => {
                  if (pageIndex === 1) return;
                  updateSearchParam("page_index", pageIndex - 1);
                }}
              >
                <a class="page-link">Previous</a>
              </li>
              <li
                class="page-item"
                onClick={() => {
                  if (pageIndex === 1) return;
                  updateSearchParam("page_index", pageIndex - 1);
                }}
              >
                <a class="page-link">{pageIndex - 1}</a>
              </li>
              <li class="page-item active">
                <a class="page-link">{pageIndex}</a>
              </li>
              <li
                class="page-item"
                onClick={() => {
                  updateSearchParam("page_index", pageIndex + 1);
                }}
              >
                <a class="page-link">{pageIndex + 1}</a>
              </li>
              <li
                class="page-item"
                onClick={() => {
                  updateSearchParam("page_index", pageIndex + 1);
                }}
              >
                <a class="page-link">Next</a>
              </li>
            </ul>
          </nav>
        )}
      </div>

      <div className="mx-5 my-4 border-white rounded shadow-lg p-3">
        <div className="d-flex mb-1 justify-content-between">
          <h4
            style={{
              textAlign: "left",
            }}
          >
            User Item Master Issues
          </h4>
          <button
            className="btn btn-sm btn-success"
            disabled={
              convertingToExcel ||
              itemsIssuesLoading ||
              !itemsIssuesData ||
              itemsIssuesData["issues"]?.length === 0
            }
            onClick={handleDownloadAsExcel}
          >
            {convertingToExcel ? "Downloading..." : "Download as Excel"}
          </button>
        </div>
        {itemsIssuesLoading && <Spinner />}
        {itemsIssuesError && (
          <div>Something went wrong fetching the item master issues</div>
        )}
        {itemsIssuesData && (
          <EngIssuesNewTable
            data={itemsIssuesData}
            view="issues"
            scrollable={false}
            desiredOrder={[
              "vendor_name",
              "tag",
              "issue",
              "status",
              "username",
              "created_date",
              "last_modified_date",
            ]}
          />
        )}
        {itemsIssuesData?.["issues"]?.length > 0 && (
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-end">
              <li
                class="page-item"
                onClick={() => {
                  if (pageIndex === 1) return;
                  updateSearchParam("page_index", pageIndex - 1);
                }}
              >
                <a class="page-link">Previous</a>
              </li>
              <li
                class="page-item"
                onClick={() => {
                  if (pageIndex === 1) return;
                  updateSearchParam("page_index", pageIndex - 1);
                }}
              >
                <a class="page-link">{pageIndex - 1}</a>
              </li>
              <li class="page-item active">
                <a class="page-link">{pageIndex}</a>
              </li>
              <li
                class="page-item"
                onClick={() => {
                  updateSearchParam("page_index", pageIndex + 1);
                }}
              >
                <a class="page-link">{pageIndex + 1}</a>
              </li>
              <li
                class="page-item"
                onClick={() => {
                  updateSearchParam("page_index", pageIndex + 1);
                }}
              >
                <a class="page-link">Next</a>
              </li>
            </ul>
          </nav>
        )}
      </div>
      <Modal
        show={showVerifiedVendorModal}
        onHide={() => setShowVerifiedVendorModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Verified Vendor Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {verifiedVendorDataLoading && (
            <div className="d-flex justify-content-center">
              {" "}
              <Spinner />
            </div>
          )}
          {verifiedVendorDataError && (
            <div>Something went wrong fetching the data</div>
          )}
          {verifiedVendorData && (
            <>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Vendor Name</th>
                    <th>Link</th>
                  </tr>
                </thead>
                <tbody>
                  {verifiedVendorData?.vendor_details?.map((vendor) => (
                    <tr>
                      <td>{vendor.vendor_name}</td>
                      <td>
                        <Link to={`/vendor-consolidation/${vendor.vendor_id}`}>
                          Link
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="d-flex justify-content-end">
                <Pagination>
                  <Pagination.First
                    disabled={getVerifiedVendorDataFilters.page_index === 1}
                    onClick={() => {
                      setGetVerifiedVendorDataFilters({
                        ...getVerifiedVendorDataFilters,
                        page_index: 1,
                      });
                    }}
                  />
                  <Pagination.Prev
                    disabled={getVerifiedVendorDataFilters.page_index === 1}
                    onClick={() => {
                      setGetVerifiedVendorDataFilters({
                        ...getVerifiedVendorDataFilters,
                        page_index: getVerifiedVendorDataFilters.page_index - 1,
                      });
                    }}
                  />
                  <Pagination.Item active>
                    {getVerifiedVendorDataFilters.page_index}
                  </Pagination.Item>
                  <Pagination.Item
                    disabled={
                      getVerifiedVendorDataFilters.page_index ===
                      verifiedVendorData?.total_pages
                    }
                    onClick={() => {
                      setGetVerifiedVendorDataFilters({
                        ...getVerifiedVendorDataFilters,
                        page_index: getVerifiedVendorDataFilters.page_index + 1,
                      });
                    }}
                  >
                    {getVerifiedVendorDataFilters.page_index + 1}
                  </Pagination.Item>
                  <Pagination.Next
                    disabled={
                      getVerifiedVendorDataFilters.page_index ===
                      verifiedVendorData?.total_pages
                    }
                    onClick={() => {
                      setGetVerifiedVendorDataFilters({
                        ...getVerifiedVendorDataFilters,
                        page_index: getVerifiedVendorDataFilters.page_index + 1,
                      });
                    }}
                  />
                  <Pagination.Last
                    disabled={
                      getVerifiedVendorDataFilters.page_index ===
                      verifiedVendorData?.total_pages
                    }
                    onClick={() => {
                      setGetVerifiedVendorDataFilters({
                        ...getVerifiedVendorDataFilters,
                        page_index: verifiedVendorData?.total_pages,
                      });
                    }}
                  />
                </Pagination>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        show={showVerifiedItemModal}
        onHide={() => setShowVerifiedItemModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Verified Item Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {verifiedItemDataLoading && (
            <div className="d-flex justify-content-center">
              {" "}
              <Spinner />
            </div>
          )}
          {verifiedItemDataError && (
            <div>Something went wrong fetching the data</div>
          )}
          {verifiedItemData && (
            <>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Vendor Name</th>
                    <th>Verified Item Count</th>
                  </tr>
                </thead>
                <tbody>
                  {verifiedItemData?.vendor_item_summary?.map((vendor) => (
                    <tr
                      onClick={() => {
                        const filters = {
                          user_uuid: searchParams.get("user_id"),
                          verification_time_range: {
                            start:
                              getVerifiedItemDataFilters.verification_time_range
                                .start,
                            end: getVerifiedItemDataFilters
                              .verification_time_range.end,
                          },
                          action: "verify",
                          date: {
                            start_date: startDate ?? null,
                            end_date: endDate ?? null,
                          },
                        };
                        navigate(
                          `/vendor-consolidation/vendor-item-master/${
                            vendor.vendor_id
                          }?filters=${JSON.stringify(
                            filters
                          )}&from_user_activity=true`
                        );
                      }}
                    >
                      <td>{vendor.vendor_name}</td>
                      <td>{vendor.verified_item_count}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="d-flex justify-content-end">
                <Pagination>
                  <Pagination.First
                    disabled={getVerifiedItemDataFilters.page_index === 1}
                    onClick={() => {
                      setGetVerifiedItemDataFilters({
                        ...getVerifiedItemDataFilters,
                        page_index: 1,
                      });
                    }}
                  />
                  <Pagination.Prev
                    disabled={getVerifiedItemDataFilters.page_index === 1}
                    onClick={() => {
                      setGetVerifiedItemDataFilters({
                        ...getVerifiedItemDataFilters,
                        page_index: getVerifiedItemDataFilters.page_index - 1,
                      });
                    }}
                  />
                  <Pagination.Item active>
                    {getVerifiedItemDataFilters.page_index}
                  </Pagination.Item>
                  <Pagination.Item
                    disabled={
                      getVerifiedItemDataFilters.page_index ===
                      verifiedItemData?.total_pages
                    }
                    onClick={() => {
                      setGetVerifiedItemDataFilters({
                        ...getVerifiedItemDataFilters,
                        page_index: getVerifiedItemDataFilters.page_index + 1,
                      });
                    }}
                  >
                    {getVerifiedItemDataFilters.page_index + 1}
                  </Pagination.Item>
                  <Pagination.Next
                    disabled={
                      getVerifiedItemDataFilters.page_index ===
                      verifiedItemData?.total_pages
                    }
                    onClick={() => {
                      setGetVerifiedItemDataFilters({
                        ...getVerifiedItemDataFilters,
                        page_index: getVerifiedItemDataFilters.page_index + 1,
                      });
                    }}
                  />
                  <Pagination.Last
                    disabled={
                      getVerifiedItemDataFilters.page_index ===
                      verifiedItemData?.total_pages
                    }
                    onClick={() => {
                      setGetVerifiedItemDataFilters({
                        ...getVerifiedItemDataFilters,
                        page_index: verifiedItemData?.total_pages,
                      });
                    }}
                  />
                </Pagination>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Dashboard;
