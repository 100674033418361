import React, { useState, useEffect, useMemo } from "react";
import { NavHeader } from "components/layout/NavHeader";
import Header from "./components/Header";
import {
  ArrowRightCircleFill,
  ArrowLeftCircleFill,
} from "react-bootstrap-icons";
import { getVendorConsolidation, deleteVendor, addNewVendor } from "./api";
import VendorTable from "./components/VendorTable";
import { Spinner } from "components/common/Spinner";
import { Search, PlusCircleFill } from "react-bootstrap-icons";
import { toast, ToastContainer } from "react-toastify";
import { Modal, ProgressBar, Dropdown } from "react-bootstrap";
import { useFetchAllUsers } from "modules/userActivity/api";
import { useSearchParams } from "react-router-dom";
import CustomDropdown from "components/CustomDropdown";
export default function Home() {
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [tempValue, setTempValue] = useState(1);
  const [pageIndex, setPageIndex] = useState(1);
  const [vendorList, setVendorList] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const [addVendorModal, setAddVendorModal] = useState(false);
  const [addingVendor, setAddingVendor] = useState(false);
  const [vendorName, setVendorName] = useState("");
  const [totalVendorCount, setTotalVendorCount] = useState(0);
  const [verifiedVendorCount, setVerifiedVendorCount] = useState(0);
  const [humanVerifiedFilter, setHumanVerifiedFilter] = useState(null);
  const [verifiedByFilter, setVerifiedByFilter] = useState(null);
  const [vendorCategoryFilter, setVendorCategoryFilter] = useState(null);
  const [searchParams] = useSearchParams();
  let filters = searchParams.get("filters") ?? `{}`;
  filters=JSON.parse(filters)

  const { data: userData, isLoading: userDataLoading } = useFetchAllUsers();

  const handleInputChange = (e) => {
    const newValue = e.target.value === "" ? 0 : parseInt(e.target.value, 10);
    setTempValue(parseInt(newValue));
  };

  const handleBlur = () => {
    setPageIndex(tempValue);
  };

  const getVendorList = () => {
    setLoading(true);
    const body = {
      page_index: pageIndex,
      page_size: 10,
      vendor_name_search: searchValue !== "" ? searchValue : null,
      human_verified_filter: humanVerifiedFilter,
      verified_by_filter: verifiedByFilter,
      vendor_category_filter: vendorCategoryFilter,
      date_filter: {
        start_date: filters?.filters?.start_date,
        end_date: filters?.filters?.end_date,
      },
    };
    getVendorConsolidation(body)
      .then((res) => {
        setVendorList(res.vendors_data);
        setTotalPages(res.total_pages);
        setTotalVendorCount(res.total_vendors);
        setVerifiedVendorCount(res.verified_vendors_count);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const deleteVendorHandler = (vendorId) => {
    setLoading(true);
    deleteVendor(vendorId)
      .then((res) => {
        setLoading(false);
        getVendorList();
        toast.success(res.message);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data.message);
      });
  };

  const addVendorHandler = () => {
    setAddingVendor(true);
    addNewVendor(vendorName)
      .then((res) => {
        setAddingVendor(false);
        getVendorList();
        setVendorName("");
        setAddVendorModal(false);
        toast.success(res.message);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data.message);
      });
  };

  const memoizedFilters = useMemo(
    () => filters,
    [filters?.filters?.start_date, filters?.filters?.end_date]
  );

  useEffect(() => {
    getVendorList();
  }, [
    pageIndex,
    humanVerifiedFilter,
    verifiedByFilter,
    vendorCategoryFilter,
    memoizedFilters,
  ]);
console.log(userData,"iseejjj")
  return (
    <>
      <NavHeader view="Vendors" />
      <ToastContainer />
      <Header
        text={"Vendors"}
        children={
          <div className="d-flex justify-content-end">
            <span className="mr-3">Total: {totalVendorCount}</span>
            <ProgressBar
              now={verifiedVendorCount}
              max={totalVendorCount}
              label={`${verifiedVendorCount}`}
              variant="success"
              style={{
                backgroundColor: "white",
                width: "300px",
                marginTop: "5px",
                marginLeft: "10px",
              }}
            />
          </div>
        }
      />
      <div className="row mt-3 mx-5">
        <div className="col-md-6 d-flex">
          <input
            type="text"
            className="form-control"
            placeholder="Search by vendor name"
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <button className="btn btn-light" onClick={getVendorList}>
            <Search />
          </button>
        </div>
        <div className="col-md-6 d-flex align-items-center justify-content-end">
          <button
            className="btn btn-primary"
            onClick={() => {
              setAddVendorModal(true);
            }}
          >
            <PlusCircleFill className="mx-2" />
            Add Vendor
          </button>
        </div>
      </div>
      <div className="d-flex justify-content-end mx-5 mt-2 mb-0">
        <Dropdown className="mx-2">
          <Dropdown.Toggle variant="light" id="dropdown-basic">
            {humanVerifiedFilter === null
              ? "Human Verified"
              : humanVerifiedFilter
              ? "Verified"
              : "Not Verified"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                setHumanVerifiedFilter(true);
              }}
            >
              Verified
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setHumanVerifiedFilter(false);
              }}
            >
              Not Verified
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setHumanVerifiedFilter(null);
              }}
            >
              Clear
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
       {!userDataLoading && <CustomDropdown
          data={[...userData?.users, { user_uuid: null, username: "None" }]}
          Objectkey={"user_uuid"}
          displayKey={"username"}
          className="w-auto"
          maxWidth="180px"
          minWidth="180px"
          value={
            userData?.users?.find((user) => user.user_uuid === verifiedByFilter)
              ?.username || "Verified By"
          }
          onSelect={(v) => {
            setVerifiedByFilter(v);
          }}
        />}
        {/* <Dropdown className="mx-2">
          <Dropdown.Toggle variant="light" id="dropdown-basic">
            {verifiedByFilter === null
              ? "Verified By"
              : userData?.users?.find(
                  (user) => user.user_uuid === verifiedByFilter
                )?.username || "None"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {userDataLoading && (
              <div className="d-flex h-100 w-100 justify-content-center align-items-center">
                <Spinner />
              </div>
            )}
            {!userDataLoading &&
              userData &&
              userData?.users?.map((user) => (
                <Dropdown.Item
                  onClick={() => {
                    setVerifiedByFilter(user.user_uuid);
                  }}
                >
                  {user.username}
                </Dropdown.Item>
              ))}
            <Dropdown.Item
              onClick={() => {
                setVerifiedByFilter(null);
              }}
            >
              None
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
        <Dropdown className="mx-2">
          <Dropdown.Toggle variant="light" id="dropdown-basic">
            {vendorCategoryFilter === null
              ? "Vendor Category"
              : vendorCategoryFilter}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {[
              "NA",
              "Normal Invoice",
              "Liquor Invoice",
              "Summary Invoice",
              null,
            ].map((category) => (
              <Dropdown.Item
                onClick={() => {
                  setVendorCategoryFilter(category);
                }}
              >
                {category === null ? "None" : category}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      {loading && (
        <div
          className="d-flex justify-content-center h-100"
          style={{
            marginTop: "85px",
          }}
        >
          <Spinner />
        </div>
      )}
      {!loading && (
        <>
          <VendorTable
            data={vendorList}
            handleDeleteVendor={deleteVendorHandler}
          />
          <div className="row">
            <div className="mt-4 mb-2">
              <button
                className="my-4 mx-2 btn bg-transparent p-0 btn-outline border-0"
                disabled={tempValue - 1 <= 0}
                onClick={() => {
                  setPageIndex(tempValue - 1);
                  setTempValue(tempValue - 1);
                }}
              >
                <ArrowLeftCircleFill size={40} />
              </button>
              <span className="my-4 mx-2">
                <input
                  value={tempValue}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  className="btn btn-secondary"
                  style={{ width: "7ch" }}
                />
                <span className="my-4">
                  {" "}
                  <strong>/</strong>{" "}
                  <input
                    disabled
                    value={`${totalPages || 10}`}
                    className="btn btn-secondary"
                    style={{ width: "7ch", cursor: "default" }}
                  />
                </span>
              </span>
              <button
                className="my-4 mx-2 btn bg-transparent p-0 btn-outline border-0"
                disabled={totalPages <= tempValue}
                onClick={() => {
                  setPageIndex(tempValue + 1);
                  setTempValue(tempValue + 1);
                }}
              >
                <ArrowRightCircleFill size={40} />
              </button>
            </div>
          </div>
        </>
      )}
      <Modal
        centered
        show={addVendorModal}
        onHide={() => setAddVendorModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Vendor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Vendor Name</label>
            <input
              type="text"
              className="form-control"
              value={vendorName}
              onChange={(e) => setVendorName(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            onClick={addVendorHandler}
            disabled={addingVendor}
          >
            {addingVendor ? "Adding" : "Add"}
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => setAddVendorModal(false)}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
