import React, { useState, useEffect } from "react";
import { NavHeader } from "components/layout/NavHeader";
import Header from "./components/Header";
import {
  getVendorBranches,
  combineVendorBranches,
  deleteVendorBranch,
} from "./api";
import { useParams } from "react-router-dom";
import { Spinner } from "components/common/Spinner";
import { ToastContainer, toast } from "react-toastify";
import VendorBranchesTable from "./components/VendorBranchesTable";
import {
  ArrowRightCircleFill,
  ArrowLeftCircleFill,
} from "react-bootstrap-icons";
import { Search } from "react-bootstrap-icons";
import ProgressBar from "react-bootstrap/ProgressBar";

export default function VendorBranches() {
  const [loading, setLoading] = useState(false);
  const [merging, setMerging] = useState(false);
  const [vendorDetails, setvendorDetails] = useState([]);
  const { vendorID } = useParams();
  const [totalPages, setTotalPages] = useState(0);
  const [tempValue, setTempValue] = useState(1);
  const [pageIndex, setPageIndex] = useState(1);
  const [searchValue, setSearchValue] = useState(null);
  const [masterVendor, setMasterVendor] = useState(null);
  const [vendorsToMerge, setVendorsToMerge] = useState([]);
  const [totalBranches, setTotalBranches] = useState(0);
  const [verifiedBranches, setVerifiedBranches] = useState(0);

  const handleInputChange = (e) => {
    const newValue = e.target.value === "" ? 0 : parseInt(e.target.value, 10);
    setTempValue(parseInt(newValue));
  };

  const handleBlur = () => {
    setPageIndex(tempValue);
  };

  const getVendorBranchesFn = () => {
    setLoading(true);
    const body = {
      vendor_id: vendorID,
      page_index: pageIndex,
      page_size: 10,
      address_search: searchValue !== "" ? searchValue : null,
    };
    getVendorBranches(body)
      .then((res) => {
        setvendorDetails(res.vendors_branch_data);
        setTotalBranches(res.vendor_branches_count);
        setVerifiedBranches(res.verified_branches_count);
        setTotalPages(res.total_pages);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getVendorBranchesFn();
  }, [pageIndex]);

  const handleCombineVendorBranches = () => {
    setMerging(true);
    const data = {
      vendor_id: vendorID,
      master_branch_id: masterVendor,
      branches_to_merge: vendorsToMerge,
    };
    combineVendorBranches(data)
      .then((res) => {
        toast.success(res.message);
        setMerging(false);
        setMasterVendor(null);
        setVendorsToMerge([]);
        getVendorBranchesFn();
      })
      .catch((err) => {
        toast.error(err);
        setMerging(false);
      });
  };

  const handleDeleteVendorBranch = (branchId) => {
    setLoading(true);
    deleteVendorBranch(vendorID, branchId)
      .then((res) => {
        setLoading(false);
        getVendorBranchesFn();
        toast.success(res.message);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data.message);
      });
  };

  return (
    <>
      <NavHeader view="Vendor Details" />
      {loading && (
        <div
          className="d-flex justify-content-center h-100"
          style={{
            marginTop: "85px",
          }}
        >
          <Spinner />
        </div>
      )}
      {!loading && (
        <>
          <Header
            text={`Vendor Branches`}
            children={
              <div className="d-flex justify-content-end">
                <span className="mr-3">Total: {totalBranches}</span>
                <ProgressBar
                  now={verifiedBranches*100}
                  max={totalBranches*100}
                  label={`${verifiedBranches}/${totalBranches}`}
                  variant="success"
                  style={{
                    backgroundColor: "white",
                    width: "300px",
                    marginTop: "5px",
                    marginLeft: "10px",
                  }}
                />
              </div>
            }
          />
          <div className="row mt-3 mx-5">
            <div className="col-md-6 d-flex">
              <input
                type="text"
                className="form-control"
                placeholder="Search by vendor address"
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <button className="btn btn-light" onClick={getVendorBranchesFn}>
                <Search />
              </button>
            </div>
          </div>
          <VendorBranchesTable
            data={vendorDetails}
            vendorsToMerge={vendorsToMerge}
            masterVendor={masterVendor}
            setMasterVendor={setMasterVendor}
            setVendorsToMerge={setVendorsToMerge}
            getVendorBranchesFn={getVendorBranchesFn}
            handleDeleteVendorBranch={handleDeleteVendorBranch}
          />

          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary"
              style={{ width: "75%" }}
              onClick={() => {
                handleCombineVendorBranches();
              }}
              disabled={vendorsToMerge.length === 0 || !masterVendor || merging}
            >
              {merging
                ? "Combining Vendor Branches"
                : "Combine Vendor Branches"}
            </button>
          </div>

          <div className="row">
            <div className="mt-4 mb-2">
              <button
                className="my-4 mx-2 btn bg-transparent p-0 btn-outline border-0"
                disabled={tempValue - 1 <= 0}
                onClick={() => {
                  setPageIndex(tempValue - 1);
                  setTempValue(tempValue - 1);
                }}
              >
                <ArrowLeftCircleFill size={40} />
              </button>
              <span className="my-4 mx-2">
                <input
                  value={tempValue}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  className="btn btn-secondary"
                  style={{ width: "7ch" }}
                />
                <span className="my-4">
                  {" "}
                  <strong>/</strong>{" "}
                  <input
                    disabled
                    value={`${totalPages || 10}`}
                    className="btn btn-secondary"
                    style={{ width: "7ch", cursor: "default" }}
                  />
                </span>
              </span>
              <button
                className="my-4 mx-2 btn bg-transparent p-0 btn-outline border-0"
                disabled={totalPages <= tempValue}
                onClick={() => {
                  setPageIndex(tempValue + 1);
                  setTempValue(tempValue + 1);
                }}
              >
                <ArrowRightCircleFill size={40} />
              </button>
            </div>
          </div>
        </>
      )}
      <ToastContainer />
    </>
  );
}
