import axios from "axios";
import { API_URL } from "config/env";
import { axiosInstance } from "lib/axios";
import { useQuery, useMutation } from "@tanstack/react-query";
import { queryClient } from "lib/react-query";
import { toast } from "react-toastify";
const getVendorConsolidation = async (filters) => {
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/get_all_vendor_data`,
    filters
  );
  return response.data;
};

const getVendorDetails = async (vendorId) => {
  const body = {
    vendor_id: vendorId,
  };
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/get_vendor_details`,
    body
  );
  return response.data;
};

const getVendorBranchDetails = async (vendorId, branchId) => {
  const body = {
    vendor_id: vendorId,
    branch_id: branchId,
  };
  const response = await axiosInstance.post(
    `${API_URL}/vendor_branch_page/get_vendor_branch_details`,
    body
  );
  return response.data;
};

const saveVendorDetails = async (vendorDetails) => {
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/save_vendor_details`,
    vendorDetails
  );
  return response.data;
};

const getVendorBranches = async (body) => {
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/get_all_vendor_branch_data`,
    body
  );
  return response.data;
};

const saveBranchDetails = async (branchDetails) => {
  const response = await axiosInstance.post(
    `${API_URL}/vendor_branch_page/save_vendor_branch_details`,
    branchDetails
  );
  return response.data;
};

const findSimilarVendors = async (similarity, vendorId) => {
  const body = {
    similarity,
    vendor_id: vendorId,
  };
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/get_similar_vendors_data`,
    body
  );
  return response.data;
};

const combineVendors = async (data) => {
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/combine_vendors`,
    data
  );
  return response.data;
};

const combineVendorBranches = async (data) => {
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/combine_vendor_branches`,
    data
  );
  return response.data;
};

const getVendorBranchPdfs = async (vendorId, branchId) => {
  const body = {
    vendor_id: vendorId,
    branch_id: branchId,
  };
  const response = await axiosInstance.post(
    `${API_URL}/get_vendor_branch_pdfs`,
    body
  );
  return response.data;
};

const renameVendor = async (data) => {
  const response = await axiosInstance.post(
    `${API_URL}/rename_vendor_branch_address`,
    data
  );
  return response.data;
};

const getItemsForVendor = async (body) => {
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/get_item_master_for_vendor`,
    body
  );
  return response.data;
};

const approveItem = async (body) => {
  // const body = {
  //   item_uuid,
  //   category,
  //   branch_id: branchId,
  //   human_verified: humanVerified,
  //   item_code: itemCode,
  //   item_description: itemDescription,
  // };
  const response = await axiosInstance.post(
    `${API_URL}/update_item_master_details`,
    body
  );
  return response.data;
};

const getItemPdfs = async (item_uuid, vendor_id) => {
  const body = {
    item_uuid,
    vendor_id,
  };
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/get_item_master_pdf_links`,
    body
  );
  return response.data;
};

const deleteVendor = async (vendorId) => {
  const body = {
    vendor_id: vendorId,
  };
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/delete_vendor`,
    body
  );
  return response.data;
};

const deleteVendorBranch = async (vendorId, branchId) => {
  const body = {
    vendor_id: vendorId,
    branch_id: branchId,
  };
  const response = await axiosInstance.post(
    `${API_URL}/vendor_branch_page/delete_vendor_branch`,
    body
  );
  return response.data;
};

const addNewVendor = async (vendorName) => {
  const body = {
    vendor_name: vendorName,
  };
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/create_vendor`,
    body
  );
  return response.data;
};

const getAdditionalData = async () => {
  const body = {
    category_choices: true,
    common_issues_in_invoice_extraction: false,
    processed_table_header_candidates: true,
  };
  const response = await axiosInstance.post(
    `${API_URL}/get_additional_data`,
    body
  );
  return response.data;
};

const mergeItemMaster = async (
  vendorId,
  branchId,
  masterItemUUID,
  itemsToMerge
) => {
  const data = {
    vendor_id: vendorId,
    branch_id: branchId,
    master_item_uuid: masterItemUUID,
    items_to_merge: itemsToMerge,
  };
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/combine_item_masters`,
    data
  );
  return response.data;
};

const deleteItemMaster = async (vendorId, branchId, itemUUID, softDelete) => {
  const data = {
    vendor_id: vendorId,
    branch_id: branchId,
    item_uuid: itemUUID,
    soft_delete: softDelete,
  };
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/delete_item_master`,
    data
  );
  return response.data;
};

const migrateBranch = async (payload) => {
  const response = await axiosInstance.post(
    `${API_URL}/vendor_branch_page/migrate_branch_vendor`,
    payload
  );
  return response.data;
};

const disapproveAllItems = async (vendorId) => {
  const response = await axiosInstance.post(
    `${API_URL}/vendor_page/unverify_all_items`,
    { vendor_id: vendorId }
  );
  return response.data;
};

const createOrUpdateItemMasterIssues = async (data) => {
  const response = await axiosInstance.post(
    `${API_URL}/engineering_issues/create_or_update_item_master_issue`,
    data
  );
  return response.data;
};

export const useFetchItemMasterIssues = (itemList) => {
  return useQuery({
    placeholderData: (previousData, q) => previousData,
    queryKey: ["itemMasterIssues", itemList?.[0]?.item_uuid],
    queryFn: async () => {
      try {
        const body = {
          item_uuid: itemList[0].item_uuid,
        };
        const response = await axiosInstance.post(
          `/engineering_issues/get_item_master_issue`,
          body
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.ensureQueryData(
        ["itemMasterIssues", itemList[0]?.item_uuid],
        data
      );
    },
    enabled: !!itemList?.length > 0,
  });
};

export const useGetInvoiceHeaderExceptionsForVendor = (vendorId) => {
  return useQuery({
    queryKey: ["invoiceHeaderExceptions", vendorId],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `${API_URL}/vendor_page/get_invoice_header_exceptions?vendor_id=${vendorId}`
      );
      return response.data;
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.setQueryData(
        ["invoiceHeaderExceptions", vendorId],
        data
      );
    },
    enabled: !!vendorId,
  });
};

export const useUpdateInvoiceHeaderExceptionsForVendor = () => {
  return useMutation({
    mutationFn: async (data) => {
      const response = await axiosInstance.post(
        `${API_URL}/vendor_page/update_invoice_header_exceptions`,
        data
      );
      return response.data;
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries([
        "invoiceHeaderExceptions",
        variables.vendorId,
      ]);
      toast.success(data.message);
    },
    onError: (error) => {
      toast.error("Error updating invoice header exceptions");
    },
  });
};

export {
  getVendorConsolidation,
  getVendorDetails,
  saveVendorDetails,
  getVendorBranches,
  findSimilarVendors,
  combineVendors,
  combineVendorBranches,
  getVendorBranchDetails,
  getVendorBranchPdfs,
  renameVendor,
  saveBranchDetails,
  getItemsForVendor,
  approveItem,
  getItemPdfs,
  deleteVendor,
  deleteVendorBranch,
  addNewVendor,
  getAdditionalData,
  mergeItemMaster,
  deleteItemMaster,
  migrateBranch,
  disapproveAllItems,
  createOrUpdateItemMasterIssues,
};
