import { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { NavHeader } from "components/layout/NavHeader";
import ListTable from "./ListTable";
import SideAccordion from "./SideAccordion";
import { useGetEngineeringIssues, getCountByCategory, getCommonIssues } from "./api";
import { Spinner } from "components/common/Spinner";
import useStatisticsStore from "modules/statistics/store";
import CategoryDropdown from "./Dropdowns/CategoryDropdown";
import TagsDropdown from "./Dropdowns/TagsDropdown";
import StatusDropdown from "./Dropdowns/StatusDropdown";
import { useReviewFilterParams } from "./store";
import { ToastContainer } from "react-toastify";
import useReviewFilterStore from "./store";
import useAppStore from "store/global";
import { useCommonIssuesActions } from "store/commonIssues";
import {
  ArrowRightCircleFill,
  ArrowLeftCircleFill,
} from "react-bootstrap-icons";
import { getCommonCategories } from "./api";
import useGlobalFilterStore from "store/globalFIlter";

export const EngineeringIssuesPage = () => {
  const { data: engIssuesData, isLoading, isError } = useGetEngineeringIssues(filters);
  const [countByCategory, setCountByCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [items, setItems] = useState([]);
  const [tempValue, setTempValue] = useState(1);
  const allPagination = useStatisticsStore((state) => state.allPagination);
  const filters = useReviewFilterParams();
  const vendorName = useGlobalFilterStore((state) => state.vendorName);
  const { changed, 
  actions: { setChanged, setPageIndex },
  } = useReviewFilterStore();
  const {setIssues, setUUID} = useCommonIssuesActions()
  useEffect(() => {
    getCommonCategories()
      .then((res) => {
        setItems(res.common_categories);
      })
      .catch((err) => {
      });
  }, [changed]);

  useEffect(() => {
    setLoading(true);
    const vname = vendorName === null || vendorName === "NA" ? [] : [vendorName];
    const newFils = {...filters, vendor_name: vname, uuid: "null"}
    getCountByCategory(newFils)
      .then((res) => {
        setCountByCategory(res.data);
      })
      .catch((err) => {
        setCountByCategory([]);
      });
    setLoading(false);
  }, [filters, changed, vendorName]);

  useEffect(() => {
    getCommonIssues()
      .then((res) => {
        setIssues(res.common_issues);
      })
      .catch((err) => {
      });
  }, []);

  const handleInputChange = (e) => {
    const newValue = e.target.value === "" ? 0 : parseInt(e.target.value, 10);
    setTempValue(parseInt(newValue));
  };

  const handleBlur = () => {
    setPageIndex(tempValue);
  };

  return (
    <>
      <NavHeader view="engineeringIssues" />
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey="all"
        variant="pills"
        justify
      >
        <Nav
          variant="pills"
          style={{
            display: "flex",
            justifyContent: "between",
            alignItems: "left",
            marginTop: "100px",
            gap: "10px",
          }}
          className="d-flex bg-nav p-2 rounded-2 align-items-center mx-5"
        >
          <div className="col-sm-2" />
          <Nav.Item>
            <Nav.Link
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Engineering Issues
            </Nav.Link>
          </Nav.Item>
          <div className="d-flex ms-auto">
            <CategoryDropdown items ={items}/>
            <TagsDropdown />
            <StatusDropdown />
          </div>
        </Nav>

        <Tab.Content></Tab.Content>
      </Tab.Container>
      <ToastContainer />
      {loading && <Spinner />}
      {!loading && engIssuesData.length === 0 && (
        <div className="text-center">
          <Spinner />
        </div>
      )}
      {!loading && engIssuesData.length > 0 && (
      <div
        className="text-center"
        style={{
          gap: "10px",
          marginTop: "15px",
          marginLeft: "35px",
          marginRight: "35px",
        }}
      >
        <div className="row">
          <ListTable data={engIssuesData} items= {items} />
          <SideAccordion data={countByCategory} />
        </div>
        <div className="row">
          <div className="my-4">
            <button
              className="my-4 mx-2 btn bg-transparent p-0 btn-outline border-0"
              disabled={tempValue - 1 <= 0}
              onClick={() => {
                setPageIndex(tempValue - 1);
                setTempValue(tempValue - 1);
              }}
            >
              <ArrowLeftCircleFill size={40} />
            </button>
            <span className="my-4 mx-2">
              <input
                value={tempValue}
                onChange={handleInputChange}
                onBlur={handleBlur}
                className="btn btn-secondary"
                style={{ width: "7ch" }}
              />
              <span className="my-4">
                {" "}
                <strong>/</strong>{" "}
                <input
                  disabled
                  value={`${totalPages || 10}`}
                  className="btn btn-secondary"
                  style={{ width: "7ch", cursor: "default" }}
                />
              </span>
            </span>
            <button
              className="my-4 mx-2 btn bg-transparent p-0 btn-outline border-0"
              disabled={totalPages <= tempValue}
              onClick={() => {
                setPageIndex(tempValue + 1);
                setTempValue(tempValue + 1);
              }}
            >
              <ArrowRightCircleFill size={40} />
            </button>
          </div>
        </div>
      </div>
      )}
    </>
  );
};
