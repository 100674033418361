
import { axiosInstance } from "lib/axios";
export async function login(data) {
  const response = await axiosInstance.post(`/login`, data);
  if (response.data.token) {
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("username", response.data.username);
    localStorage.setItem("role", response.data.user_role);
    localStorage.setItem("user_email", response.data.user_email);
  }
  return response;
}
