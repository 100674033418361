import React from "react";

const ContextMenu = ({
  xPos,
  yPos,
  handleClose,
  handleAddCell,
  handleAddRow,
  handleDeleteCell,
  handleCopyRowUp,
  handleDeleteRow,
  rowIndex,
  columnIndex,
  handleCopyRowDown,
  shiftRowUp,
  shiftRowDown,
  handleColumnAdd
}) => {
  return (
    <div
      className="context-menu p-2 border rounded"
      style={{
        position: "absolute",
        top: yPos,
        left: xPos,
        zIndex: 1000,
        backgroundColor: "#fff",
      }}
    >
      <div>
        <div
          onClick={() => {
            handleAddCell(rowIndex, columnIndex);
          }}
          style={{
            cursor: "pointer",
          }}
        >
          Add New Cell
        </div>
        <hr className="featurette-divider my-1" />
        <div
          onClick={() => {
            handleDeleteCell(rowIndex, columnIndex);
          }}
          style={{
            cursor: "pointer",
          }}
        >
          Delete Cell
        </div>
        <hr className="featurette-divider my-1" />
        <div
          onClick={() => {
            handleAddRow(rowIndex);
          }}
          style={{
            cursor: "pointer",
          }}
        >
          Add New Row
        </div>
        <hr className="featurette-divider my-1" />
        <div
          onClick={() => {
            handleDeleteRow(rowIndex - 1);
            handleClose();
          }}
          style={{
            cursor: "pointer",
          }}
        >
          Delete Row
        </div>
        <hr className="featurette-divider my-1" />
        <div
          onClick={() => {
            handleCopyRowUp(rowIndex);
            handleClose();
          }}
          style={{
            cursor: "pointer",
          }}
        >
          Copy Row Up
        </div>
        <hr className="featurette-divider my-1" />
        <div
          onClick={() => {
            shiftRowUp(rowIndex);
            handleClose();
          }}
          style={{
            cursor: "pointer",
          }}
        >
          Shift Row Up
        </div>
        <hr className="featurette-divider my-1" />
        <div
          onClick={() => {
            handleCopyRowDown(rowIndex);
            handleClose();
          }}
          style={{
            cursor: "pointer",
          }}
        >
          Copy Row Down
        </div>
        <div
          onClick={() => {
            shiftRowDown(rowIndex);
            handleClose();
          }}
          style={{
            cursor: "pointer",
          }}
        >
          Shift Row Down
        </div>
        <div
          onClick={() => {
            handleColumnAdd()
            handleClose();
          }}
          style={{
            cursor: "pointer",
          }}
        >
          Add Column
        </div>
      </div>
      
    </div>
  );
};

export default ContextMenu;
