import { shared } from "use-broadcast-ts";
import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
import { resetCommonIssues } from "./commonIssues";

const useInvoicePagination = createWithEqualityFn(
  shared(
    (set) => ({
      tempValue: 1,
      totalInvoices: 0,
      pageNumber: 1,
      // actions: {
      setPageNumber: (pageNumber) => {
        set({ pageNumber });
        resetCommonIssues();
      },
      setTempValue: (tempValue) => set({ tempValue }),
      setTotalInvoices: (totalInvoices) => set({ totalInvoices }),
      // },
    }),
    { name: "useInvoicePagination" }
  ),
  Object.is
);

// This hook will not trigger re-render even if any of the state change
// passing all the actions individually instead of (state)=>state.actions because use-broadcast-ts does not support nested object
export const useInvoicePaginationActions = () =>
  useInvoicePagination(
    (state) => ({
      setPageNumber: state.setPageNumber,
      setTempValue: state.setTempValue,
      setTotalInvoices: state.setTotalInvoices,
    }),
    shallow
  );

export default useInvoicePagination;
