import useGlobalStore from "store/global";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import {
  ArrowClockwise,
  ArrowCounterclockwise,
  ZoomIn,
  ZoomOut,
  Download
} from "react-bootstrap-icons";
import { useState } from "react";
import { ButtonGroup } from "react-bootstrap";
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export const PDFComponent = () => {
  const {
    numPages,
    setNumPages,
    pdfUrl,
    pageNum,
    setPageNum,
    // scale,
    pdfSource,
  } = useGlobalStore();

  const [pdfScale, setPdfScale] = useState(1.0);
  const [rotation, setRotation] = useState(0);

  // const url =
  //   pdfSource === "azure_blob"
  //     ? `https://drive.google.com/viewerng/viewer?url=${pdfUrl}?rsct=application%2Fpdf&embedded=true`
  //     : pdfUrl;

  function changePage(offset) {
    const newPageNum = pageNum + offset;
    setPageNum(newPageNum);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNum(1);
  }

  return (
    <>
    {pdfSource === "azure_blob" && (
       <div className="ml-50 p-2 rounded" style={{zIndex:'50', backgroundColor:'rgb(240, 240, 240)'}}>
       <div className="d-flex align-items-center justify-content-center" style={{gap: "16px"}}>
         <ZoomIn
         height={20}
         width={20}
           disabled={pdfScale >= 8}
           className="cursor-pointer"
           onClick={() => setPdfScale((s) => s * 2)}
         />
         <ZoomOut
          height={20}
          width={20}
           disabled={pdfScale <= 0.1}
           className="cursor-pointer"
           onClick={() => setPdfScale((s) => s / 2)}
         />
         <button
         
           type="button"
           disabled={pageNum <= 1}
           onClick={previousPage}
           className="btn btn-sm btn-outline-secondary"
         >
           Previous
         </button>
         <button
           type="button"
           disabled={pageNum >= numPages}
           onClick={nextPage}
           className="btn btn-sm btn-outline-secondary"
         >
           Next
         </button>
         <ArrowClockwise
          height={20}
          width={20}
           className="cursor-pointer"
           onClick={() => setRotation((r) => (r === 270 ? 0 : r + 90))}
         />
         <ArrowCounterclockwise
          height={20}
          width={20}
           className="cursor-pointer"
           onClick={() => setRotation((r) => (r === 0 ? 270 : r - 90))}
         />
         <div>
         <span>
           Page {pageNum} of {numPages}
         </span>
          <Download
            height={20}
            width={20}
            className="cursor-pointer mx-4"
            onClick={() => window.open(pdfUrl, "_blank")}
          />
       </div>
       </div>
       
     </div>
     )}
      <div style={{ height: "580px", overflow: "auto" }}>
        {pdfSource === "azure_blob" ? (
          <>
            <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNum} scale={pdfScale} rotate={rotation} />
            </Document>
          
          </>
        ) : (
          <iframe
            title="pdf"
            src={pdfUrl}
            width="100%"
            height="570"
            allow="autoplay"
          ></iframe>
        )}
      </div>
      {/* <EngReview /> */}
      <div>{/* <Pagination simple defaultCurrent={2} total={50} /> */}</div>
    </>
  );
};
