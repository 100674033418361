import React, { useState, useEffect } from "react";
import { NavHeader } from "components/layout/NavHeader";
import Header from "./components/Header";
import { getVendorDetails, saveVendorDetails } from "./api";
import { useParams } from "react-router-dom";
import { Spinner } from "components/common/Spinner";
import VendorDetailsTable from "./components/VendorDetails";
import { toast, ToastContainer } from "react-toastify";
import { transformInitialMapping } from "./transformationFunctions";
import useAppStore from "store/global";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { JournalCheck } from "react-bootstrap-icons";
import Notes from "components/Comments/Notes";

export default function VendorDetails() {
  const [loading, setLoading] = useState(false);
  const [vendorDetails, setvendorDetails] = useState([]);
  const { vendorID } = useParams();
  const {
    sessionStartTime, 
    setSessionStartTime
  } = useAppStore();
  
  const getvendorDetails = () => {
    setLoading(true);
    getVendorDetails(vendorID)
      .then((res) => {
        const desiredOrder = [
          "vendor_id",
          "vendor_name",
          "human_verified",
          "auto_approve_invoices",
          "document_count",
          "vendor_branches_count",
          "verified_branches_count",
          "total_items",
          "verified_items_count",
          "vendor_category",
          "vendor_gl_code",
          "vendor_name_synonyms",
          "vendor_payment_terms",
          "metadata",
          "item_master_uniqueness_level",
          "vendor_document_type",
          "required_invoice_columns_for_item_master",
          "invoice_header_names_mapping",
        ];
        const orderedVendorDetails = {};
        desiredOrder.forEach((key) => {
          if (res.hasOwnProperty(key)) {
            orderedVendorDetails[key] = res[key];
          }
        });
        orderedVendorDetails.invoice_header_names_mapping =
          transformInitialMapping(
            orderedVendorDetails.invoice_header_names_mapping,
            false
          );
        setvendorDetails(orderedVendorDetails);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };


  useEffect(() => {
    const nt = new Date().getTime()
    setSessionStartTime(nt);

    return () => {
     setSessionStartTime(null);
    }
  }, []);

  const handleSaveVendorDetails = (data) => {
    setLoading(true);
    data.invoice_header_names_mapping = transformInitialMapping(
      data.invoice_header_names_mapping,
      true
    );
    data.verification_time = (new Date().getTime() - sessionStartTime) / 1000;
    const nt = new Date().getTime()
    setSessionStartTime(nt);
    saveVendorDetails(data)
      .then((res) => {
        toast.success(res.message);
        getvendorDetails();
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Error saving vendor details");
        setLoading(false);
      });
  };

  useEffect(() => {
    getvendorDetails();
  }, []);
  const tooltip = (
    <Tooltip id="tooltip">
   View Vendor Notes
    </Tooltip>
  );
  const [showNotes,setShowNotes]=useState(false)
  return (
    <>
      <NavHeader view="Vendor Details" />
   
      {loading && (
        <div
          className="d-flex justify-content-center h-100"
          style={{
            marginTop: "85px"
          }}
        >
          <Spinner />
        </div>
      )}
      {!loading && (
        <>
          <Header text={`Vendor Details for ${vendorDetails?.vendor_name}`} >
          <OverlayTrigger placement="left" overlay={tooltip}>
              <Button
                variant="success"
                className="mx-2"
                onClick={() => setShowNotes(true)}
                disabled = {vendorID === null}
              >
                <JournalCheck

                  height={20}
                  width={20}
                  fill="white"
                  className="cursor-pointer warning"
                />
              </Button>
              </OverlayTrigger>
          </Header>
        
          <VendorDetailsTable
            data={vendorDetails}
            handleSaveVendorDetails={handleSaveVendorDetails}
          />
            <Notes show={showNotes} setShow={setShowNotes} name="Vendor Notes" data={[]} vendorId={vendorID} />
        </>
      )}
      <ToastContainer />
    </>
  );
}
