import React from "react";
import Table from "react-bootstrap/Table";
import { Status } from "assets/status";
import { useParams } from "react-router-dom";


export default function RerunTable({ data, setUuids, uuids }) {
  const handleCheckboxChange = (event, uuid) => {
    const isChecked = event.target.checked;

    setUuids((prevUuids) => {
      if (isChecked) {
        return [...prevUuids, uuid];
      } else {
        return prevUuids.filter((id) => id !== uuid);
      }
    });
  };
  const { vendorName } = useParams();
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Invoice Number</th>
          <th>Invoice UUID</th>
          <th>Vendor Name</th>
          <th>Status</th>
          <th>Select</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.uuid}>
            <td>{item.invoice_number}</td>
            <td>{item.uuid}</td>
            <td>
                {item.vendor_name?.map((vendorName) => (
                  <li>{vendorName}</li>
                ))}
              
            </td>
            <td>
              {vendorName==='NA'?<Status>{false}</Status>:
              <Status>{!item.human_verification_required}</Status>}
              
              </td>
            <td>
              <input
                type="checkbox"
                className="mx-auto"
                style={{ width: "20px", height: "20px", cursor: "pointer" }}
                checked={uuids.includes(item.uuid)}
                onChange={(event) => handleCheckboxChange(event, item.uuid)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
