import React, { useState } from "react";
import { Container, Row, Col, Form} from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "lib/axios";
import { Spinner } from "components/common/Spinner";
import useAppStore from "store/global";
import useGetNestedFilterValue from "hooks/useGetNestedFilterValue";
export const useVendorList = (restId) =>
  useQuery({
    queryKey: ["vendors", restId],
    queryFn: async () => {
      if (restId === undefined){
        restId = null
      }
      const res = await axiosInstance.get(`/get_vendor_names?restaurant_id=${restId}`);
      return res.data.vendor_names;
    },
  });


const MetaData = ({ invoice_metadata, setInvoiceMetadata }) => {
  const getNestedFilterValue = useGetNestedFilterValue();
  const rest_id = getNestedFilterValue(["filters", "restaurant_id"])
  const { data, isPending, error, isError } = useVendorList(rest_id);
  const [vendorBranches, setVendorBranches] = useState([]);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleChange = async (key, value) => {
    if (key === "vendor_details") {
      value = JSON.parse(value);
      setInvoiceMetadata({
        ...invoice_metadata,
        vendor_details: value,
      });
      setLoading(true);
      const res = await axiosInstance.post("/vendor_page/get_all_vendor_branch_data", {
        vendor_id: value.vendor_id,
        page_index: null,
        page_size: null,
        address_search: null,
      });
      setVendorBranches(res.data.vendors_branch_data);
      setLoading(false);
      return;
    }
    if (key === "branch_details") {
      value = JSON.parse(value);
      // in the invoice metadata in the vendor details, store the branch details
      setInvoiceMetadata({
        ...invoice_metadata,
        vendor_details: {
          ...invoice_metadata.vendor_details,
          branch_id: value.branch_id,    
        },
      });
      return
      // const res = await axiosInstance.post("/item_master_lookup", {
      //   vendor_id: invoice_metadata.vendor_details.vendor_id,
      //   branch_id: value.branch_id,
      // });
    }
    setInvoiceMetadata({
      ...invoice_metadata,
      [key]: value,
    });
  };

  //console.log(invoice_metadata);
  const renderInputGroup = (keys) => (
    <>
      <Row className="m-2">
        {keys.map((key) => (
          <Col xs={12} md={4} key={key}>
            <Form.Group controlId={key}>
              <Form.Label style={{ textTransform: "capitalize" }}>
                {key.replace(/_/g, " ").replace(/([A-Z])/g, " $1")}
              </Form.Label>
              {Array.isArray(invoice_metadata[key]) ? (
                <Form.Control
                  type="text"
                  placeholder={`Enter ${key.replace(/_/g, " ").slice(0, -1)}`}
                  onChange={(e) =>
                    handleChange(
                      key,
                      e.target.value.split(",").map((item) => {
                        return key !== "invoice_global_addresses"
                          ? parseInt(item.trim())
                          : item.trim();
                      })
                    )
                  }
                />
              ) : key === "invoice_type" ? (
                <Form.Control
                  as="select"
                  onChange={(e) => handleChange(key, e.target.value)}
                >
                  <option value="">Select Invoice Type</option>
                  <option value="Summary Invoice">Summary Invoice</option>
                  <option value="Liquor Invoice">Liquor Invoice</option>
                  <option value="Normal Invoice">Normal Invoice</option>
                </Form.Control>
              ) : key === "vendor_name" ? (
                <Form.Control
                  as="select"
                  onChange={(e) =>
                    handleChange("vendor_details", e.target.value)
                  }
                >
                  <option value="">Select Vendor</option>
                  {data?.map((vendor) => (
                    <option
                      key={JSON.stringify(vendor)}
                      value={JSON.stringify(vendor)}
                    >
                      {vendor.vendor_name}
                    </option>
                  ))}
                </Form.Control>
              ) : key === "vendor_branch" ? (<>
              <Form.Control
                  as="select"
                  onChange={(e) =>
                    handleChange("branch_details", e.target.value)
                  }
                >
                  <option value="">Select Vendor Address</option>
                  {vendorBranches?.map((branch) => (
                    <option
                      key={JSON.stringify(branch)}
                      value={JSON.stringify(branch)}
                    >
                      {branch.vendor_address}
                    </option>
                  ))}
                </Form.Control>
              </>)
               : (
                <Form.Control
                  type={key.includes("date") ? "date" : "text"}
                  placeholder={`Enter ${key.replace(/_/g, " ")}`}
                  onChange={(e) => handleChange(key, e.target.value)}
                />
              )}
            </Form.Group>
          </Col>
        ))}
      </Row>
    </>
  );

  return (
    <>
      <Container fluid className={`mt-5 ${loading ? 'blur' : ''}`} style={{ textAlign: "left" }}>
        {loading && (
          <div className="loader-overlay">
            <Spinner/>
          </div>
        )}
        {renderInputGroup(["invoice_number", "invoice_type", "vendor_name"])}
        {renderInputGroup(["invoice_date", "invoice_total_amount", "due_date"])}
        {renderInputGroup([
          "extra_charges_added",
          "extra_discounts_added",
          "extra_fees_added",
        ])}
        {renderInputGroup([
          "invoice_payment_terms",
          "invoice_ship_to",
          "invoice_bill_to",
        ])}
        {renderInputGroup([
          "invoice_sold_to",
          "vendor_branch",
          "invoice_balance_due"
        ])}
        {renderInputGroup(["restaurant_id"])}
      </Container>
    </>
  );
};

export default MetaData;
