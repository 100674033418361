import React, { useState } from "react";
import { Table, Form, Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { PatchCheckFill } from "react-bootstrap-icons";

export default function VendorCombineTable({
  vendorData,
  handleCombineVendors,
  actualVendorName,
  setActualVendorName,
}) {
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [selectedVendorNames, setSelectedVendorNames] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const handleCheckboxChange = (vendorId, vendorName) => {
    setSelectedVendors((prevSelectedVendors) =>
      prevSelectedVendors.includes(vendorId)
        ? prevSelectedVendors.filter((id) => id !== vendorId)
        : [...prevSelectedVendors, vendorId]
    );

    setSelectedVendorNames((prevSelectedVendorNames) =>
      prevSelectedVendorNames.includes(vendorName)
        ? prevSelectedVendorNames.filter((name) => name !== vendorName)
        : [...prevSelectedVendorNames, vendorName]
    );
  };

  const handleCombineConfirmation = () => {
    // Close the modal and proceed with the combination
    setShowModal(false);
    handleCombineVendors(selectedVendors);
  };

  return (
    <div className="mx-5" style={{ marginTop: "50px" }}>
      <div className="d-flex justify-content-start align-items-center my-4 p-1">
        <label htmlFor="actualVendorName">Actual Vendor Name: </label>
        <input
          id="actualVendorName"
          name="actualVendorName"
          type="text"
          className="form-control mx-2"
          value={actualVendorName}
          onChange={(e) => {
            setActualVendorName(e.target.value);
          }}
          style={{
            width: "50%",
          }}
        />
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Vendor Name</th>
            <th>Document Count</th>
            <th>Matching Score</th>
            <th>Invoice</th>
            <th>Select</th>
          </tr>
        </thead>
        <tbody>
          {vendorData.map((vendor) => (
            <tr key={vendor.vendor_id}>
              <td>{vendor.vendor_name} {vendor.human_verified && <PatchCheckFill fill="blue"/>}</td>
              <td>{vendor.documents_count}</td>
              <td>{vendor.matching_score}</td>
              <td>
                <Link
                  target="_blank"
                  to={`/compare-invoices/${vendor.vendor_name}/${actualVendorName}`}
                >
                  <button type="button" className="btn btn-sm btn-primary">
                    View
                  </button>
                </Link>
              </td>
              <td>
                <Form.Check
                  type="checkbox"
                  checked={selectedVendors.includes(vendor.vendor_id)}
                  onChange={() =>
                    handleCheckboxChange(vendor.vendor_id, vendor.vendor_name)
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="mt-2">
        <Button
          variant="success"
          style={{ width: "50%" }}
          onClick={() => {
            if (selectedVendors.length > 0) {
              // Show the confirmation modal
              setShowModal(true);
            } else {
              toast.error("Please select a minimum of one vendor to combine.");
            }
          }}
        >
          Combine Vendors
        </Button>
      </div>

      {/* Confirmation Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Vendor Combination</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Actual Vendor Name: <strong>{actualVendorName}</strong>
          </p>
          <p>
            Selected Vendor Names:{" "}
            {selectedVendorNames.map((name) => (
              <span key={name} className="badge bg-primary mx-2">
                {name}
              </span>
            ))}
          </p>
          <p>Are you sure you want to combine these vendors?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleCombineConfirmation}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="mt-4 border border-2 rounded-lg mx-2 p-2">
        Selected Vendor Names:{" "}
        {selectedVendorNames.map((name) => (
          <span key={name} className="badge bg-primary mx-2">
            {name}
          </span>
        ))}
      </div>
    </div>
  );
}
