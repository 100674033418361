import { useSearchParams } from "react-router-dom";

/**
 * This hook is used to update the search params in the URL. 
 * It takes an object as an argument and updates the search params 
 * in the URL with the object. It uses the useSearchParams hook 
 * from react-router-dom to get the search params and setSearchParams 
 * to update the search params.
 */

const useUpdateSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateSearchParams = (updates) => {
    // Get the current search params and parse the 'filters' parameter
    const filters = JSON.parse(searchParams.get('filters') || '{}');

    // Function to deep merge the existing filters with the updates
    const deepMerge = (target, source) => {
      for (const key in source) {
        if (source[key] instanceof Object && key in target) {
          Object.assign(source[key], deepMerge(target[key], source[key]));
        }
      }
      // Merge the target and source objects
      return { ...target, ...source };
    };

    // Perform deep merge of the existing filters with the updates
    const updatedFilters = deepMerge(filters, updates);

    // Create a new instance of URLSearchParams
    const newSearchParams = new URLSearchParams(searchParams);

    // Stringify the updated filters object and set it back to search params
    newSearchParams.set('filters', JSON.stringify(updatedFilters));

    // Update the search params in the URL
    setSearchParams(newSearchParams);
  };

  return updateSearchParams;
};

export default useUpdateSearchParams;

