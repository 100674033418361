import React from "react";
import { NavHeader } from "components/layout/NavHeader";
import Header from "modules/vendorConsolidation/components/Header";
import { ToastContainer } from "react-bootstrap";
import Assignment from "./Components/Assignment";
export default function InvoiceAssignment() {
  return (
    <>
      <NavHeader view="User Activity" />
      <ToastContainer />
      <Header text="Invoice Assignment" children={<></>} />
      <Assignment />
    </>
  );
}
