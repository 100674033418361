import React from "react";
import { Dropdown } from "react-bootstrap";
import useReviewFilterStore from "../store";
import { useCommonIssuesStore } from "store/commonIssues";

export default function TagsDropdown() {
  const {issues} = useCommonIssuesStore();
  const {
    tags,
    actions: { setTags },
  } = useReviewFilterStore();
  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="outline"
        className="border w-100 bg-white"
        style={{
          lineHeight: "1.2",
          fontSize: "14px",
        }}
      >
        {tags.length === 0 ? "Select Tags" : tags.join(", ")}
      </Dropdown.Toggle>
      <Dropdown.Menu
        style={{
          minHeight: "100px",
          maxHeight: "200px",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <Dropdown.Item
          className={tags.length === 0 ? "bg-primary text-white" : ""}
          key={"all"}
          onClick={() => {
            setTags([]);
          }}
        >
          All
        </Dropdown.Item>
        {issues?.map((filter, i) => (
          <Dropdown.Item
            className={tags.includes(filter) ? "bg-primary text-white" : ""}
            key={i}
            onClick={() => {
              const oldTags = [...tags]
              if(oldTags.includes(filter)) {
                setTags(oldTags.filter((item) => item !== filter));
              } else {
                setTags([...oldTags, filter]);
              }
            }}
          >
            {filter}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
