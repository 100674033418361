import { Nav, Tab } from "react-bootstrap";
import { BarChart } from "./components/BarChart";
import { VendorDropDown } from "./components/VendorDropDown";
import useStatisticsStore, { useStatisticsActions } from "./store";
import { StatisticsTable } from "./components/Table";
import { NavHeader } from "components/layout/NavHeader";
import { TableDropDown } from "./components/TableDropDown";

export const StatisticsPage = () => {
  const { setActiveTab} = useStatisticsActions()
  const activeTab = useStatisticsStore(state=>state.activeTab)

  return (
    <>
    <NavHeader view="statistics"/>
    <Tab.Container
      id="left-tabs-example"
      defaultActiveKey="all"
      variant="pills"
      justify
    >
      <Nav
        variant="pills"
        style={{
          display: "flex",
          justifyContent: "left",
          alignItems: "left",
          marginTop: "100px",
          gap: "10px"
        }}
        className="d-flex bg-nav p-2 rounded-2 align-items-center mx-5"
      >
        <div className="col-sm-2" />
        <Nav.Item>
          <Nav.Link
            active={activeTab === "all"}
            onPointerDown={() => setActiveTab("all")}
          >
            All invoices
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            active={activeTab === "vendor"}
            onPointerDown={() => setActiveTab("vendor")}
          >
            Vendor based invoices
          </Nav.Link>
        </Nav.Item>

        <div className="col" />
        
        {/* {activeTab === "vendor" && <VendorDropDown />}
        <TableDropDown/> */}
        <div className="col-sm-3" />
      </Nav>

      <Tab.Content>
        {/* <Tab.Pane eventKey="all"> */}
        <BarChart />
        <StatisticsTable/>
        {/* </Tab.Pane> */}
      </Tab.Content>
    </Tab.Container></>
  );
};
