import React from "react";
import { Button } from "react-bootstrap";
import { useGetInvoiceAssignment } from "../api";
import { Skeleton } from "antd";
import calculateTimeDifference from "../helpers/calculateLeftTime";
export default function Assignment() {
  const { data, isLoading, isError } = useGetInvoiceAssignment();
  return (
    <div
      className="border border-white shadow-sm p-3 mx-5 my-3 text-center container"
      style={{
        minHeight: "500px",
        overflowY: "auto",
        overflowX: "auto",
      }}
    >
      {isLoading ? (
        <Skeleton active />
      ) : isError ? (
        <div className="text-center">
          <h3>Something went wrong</h3>
        </div>
      ) : (
        <table className="table table-striped table-hover table-bordered shadow-sm table-responsive">
          <thead>
            <tr>
              <th style={{ verticalAlign: "middle" }}>S.No</th>
              <th style={{ verticalAlign: "middle" }}>Load Date</th>
              <th style={{ verticalAlign: "middle" }}>Restaurant</th>
              <th style={{ verticalAlign: "middle" }}>Invoice URL</th>
              <th
                style={{
                  width: "10%",
                  verticalAlign: "middle",
                }}
              >
                Status
              </th>
              <th style={{ verticalAlign: "middle" }}>Due Time</th>
            </tr>
          </thead>
          <tbody>
            {data.balancer_reports.map((invoice, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{new Date(invoice.load_date).toLocaleDateString()}</td>
                <td>{invoice.restaurant_id}</td>
                <td>
                  <a
                    href={
                      window.location.origin +
                      "/invoice-details/" +
                      invoice.document_uuid
                    }
                    target="_blank"
                  >
                    View
                  </a>
                </td>
                <td>
                  <Button
                    variant={
                      invoice.status === "Balanced" ? "success" : "danger"
                    }
                  >
                    {invoice.status}
                  </Button>
                </td>
                <td
                  className={
                    calculateTimeDifference(
                      invoice.load_date,
                      data.max_limit
                    ).startsWith("-")
                      ? "text-danger"
                      : ""
                  }
                >
                  {calculateTimeDifference(
                    invoice.load_date,
                    data.max_limit
                  ).replace("-", "")}
                  {
                    calculateTimeDifference(
                      invoice.load_date,
                      data.max_limit
                    ).startsWith("-")
                      ? " Overdue"
                      : " Left"
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
