import React, { useState, useEffect, useRef } from "react";
import { Tooltip } from "react-tooltip";
import { axiosInstance } from "lib/axios";
import { toast } from "react-toastify";
import { PlusCircleFill, Textarea } from "react-bootstrap-icons";
import CombinedCols from "../Modals/CombinedCols";
import UnrecognizedCols from "../Modals/UnrecognizedCols";
import ResizableCell from "../Utility/ResizableCell";
import { getColumnsWiseData, getRowWiseData } from "utils/transform";
import useAppStore from "store/global";
import RejectModal from "components/Modals/RejectModal";
import { Button, ButtonGroup, Dropdown, Modal, Table } from "react-bootstrap";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import { InvoiceComponent } from "components/invoice/InvoiceComponent";
import { EngReview } from "components/EngReview";
import { Spinner, FormControl } from "react-bootstrap";
import {
  ArrowBarUp,
  ArrowBarDown,
  ArrowCounterclockwise,
  Sliders,
} from "react-bootstrap-icons";
import ContextMenu from "components/layout/ContextMenu";
import { PatchCheckFill } from "react-bootstrap-icons";
import { PatchExclamationFill } from "react-bootstrap-icons";
import CustomDropdown from "components/CustomDropdown";
const objectMap = (obj, fn) =>
  Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));

export default function HumanVerification({
  width,
  rowDataForExtendedPrice,
  stopTimer,
  timer,
  setVerificationTime,
  stopVerificationTime,
  onCellHover,
  multipleBoundingBoxes,
  setMultipleBoundingBoxes,
  toggleMultipleBoundingBoxes,
}) {
  const {
    invoiceTotal,
    setInvoiceTotal,
    invoiceTotalFromtable,
    combinedTableData,
    setCombinedTableData,
    extraChargesAdded,
    setExtraChargesAdded,
    extraFeesAdded,
    setExtraFeesAdded,
    extraDiscountsAdded,
    setExtraDiscountsAdded,
    respData,
    setAdditionalCols,
    additionalCols,
    additionalHeaders,
    numberOfRows,
    additionalColsTables,
    setSaved,
    invoiceType,
    categoryChoices,
    invoiceNum,
    dueDate,
    invoiceDate,
    vendorName,
    verification_time,
    invoiceShipTo,
    invoiceBillTo,
    invoiceSoldTo,
    setCategoryWiseSum,
    quickBooksDocType,
    invoiceUUID,
    documentTypePrediction,
    vendorAddress,
    restaurantsId,
    vendorId,
    branchId,
    creditCardName,
    creditCardNumber,
    latest_update_info,
    lineItemMetaData,
    sessionStartTime,
    setSessionStartTime,
    pageNum,
    acceptAndRejectControl,
  } = useAppStore();

  const contextMenuRef = useRef(null);
  const [extraDiscountsSum, setExtraDiscountsSum] = useState(0);
  const [invoiceTaxesSum, setInvoiceTaxesSum] = useState(0);
  const [extraFeesSum, setExtraFeesSum] = useState(0);
  const [discounts, setDiscounts] = useState([]);
  const [matchingItems, setMatchingItems] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [fees, setFees] = useState([]);
  const [changed, setChanged] = useState(false);
  const [rowId, setRowId] = useState(-1);
  const [editableRow, setEditableRow] = useState(-1);
  const [changedInputs, setChangedInputs] = useState([]);
  const [newPayload, setPayload] = useState({ row_id: null, row_data: {} });
  const [editDiscount, setEditDiscount] = useState(false);
  const [editTax, setEditTax] = useState(false);
  const [editFees, setEditFees] = useState(false);
  const [sum, setSum] = useState(0);
  const [extendedPriceColIndex, setExtendedPriceColIndex] = useState(0);
  const [dataForEditabletable, setDataForEditableTable] = useState([]);
  const [invNewTableheaders, setInvNewTableHeaders] = useState([]);
  const [dataForAdditionaltable, setDataForAdditionalTable] = useState([]);
  const [invAdditionalTableheaders, setInvAdditionalTableHeaders] = useState(
    []
  );
  const [dataForTableSpecificAddTab, setDataForTableSpecificAddTab] = useState(
    []
  );
  const [additionalTableheaders, setAdditionalTableHeaders] = useState([]);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const [tableNames, setTableNames] = useState([]);
  const [selectedTable, setSelectedTable] = useState(false);
  const [selectedTableName, setSelectedTableName] = useState("");
  const [addTabData, setAddTabData] = useState([]);
  const [headerIndex, setHeaderIndex] = useState(null);
  const [taxEdit, setTaxEdit] = useState(false);
  const [discountEdit, setDiscountEdit] = useState(false);
  const [feeEdit, setFeeEdit] = useState(false);
  const [calculatedSum, setCalculatedSum] = useState(0);
  const [additionIndex, setAdditionIndex] = useState(null);
  const [addTax, setAddTax] = useState(false);
  const [addDiscount, setAddDiscount] = useState(false);
  const [addFee, setAddFee] = useState(false);
  const [newTax, setNewTax] = useState("");
  const [newDiscount, setNewDiscount] = useState("");
  const [newFee, setNewFee] = useState("");
  const [editTotal, setEditTotal] = useState(false);
  const [humanVerified, setHumanVerified] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [rejectedReason, setRejectedReason] = useState("");
  const [isReview, setisReview] = useState(false);
  // const [needsVerification, setNeedsVerification] = useState(false);
  const dropdownRef = useRef(null);
  const tableRef = useRef(null);
  const containerRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [autoCalculate, setAutoCalculate] = useState(true);
  const [viewVerificationColumn, setViewVerificationColumn] = useState(true);
  const [viewDeleteColumn, setViewDeleteColumn] = useState(false);
  const [viewShiftColumn, setViewShiftColumn] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    xPos: 0,
    yPos: 0,
  });
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [indexOfRow, setIndexOfRow] = useState(-1);
  const [indexOfColumn, setIndexOfColumn] = useState(-1);
  const [ignoreBlur, setIgnoreBlur] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const handleContextMenu = (e, row, col) => {
    e.preventDefault();
    setIndexOfColumn(col);
    setIndexOfRow(row);
    console.log("first", row, col);
    setContextMenuPosition({ xPos: e.pageX, yPos: e.pageY });
    setShowContextMenu(true);
  };

  const handleCloseContextMenu = () => {
    setShowContextMenu(false);
    setIndexOfColumn(-1);
    setIndexOfRow(-1);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseTwo = () => setShowTwo(false);
  const handleShowTwo = () => setShowTwo(true);

  useEffect(() => {
    const calculateSum = () => {
      let updatedSum = 0;
      Object.keys(dataForEditabletable).forEach((key) => {
        let value = dataForEditabletable[key][extendedPriceColIndex]?.text;
        if (value) {
          // if (typeof value === "string") {
          //   if (value.includes(",")) {
          //   setNeedsVerification(true);
          //   }
          // }
          updatedSum += parseFloat(value);
        }
      });

      setSum(updatedSum.toFixed(2));
    };

    if (discountEdit) {
      return;
    } else if (
      extraDiscountsAdded?.[0] === "NA" ||
      extraDiscountsAdded?.length === 0 ||
      isNaN(extraDiscountsAdded?.[0])
    ) {
      setDiscounts([0]);
    } else {
      setDiscounts(extraDiscountsAdded);
    }

    if (taxEdit) {
      return;
    } else if (
      extraChargesAdded?.[0] === "NA" ||
      extraChargesAdded?.length === 0 ||
      isNaN(extraChargesAdded?.[0])
    ) {
      setTaxes([0]);
    } else {
      setTaxes(extraChargesAdded);
    }

    if (feeEdit) {
      return;
    } else if (
      extraFeesAdded?.[0] === "NA" ||
      extraFeesAdded?.length === 0 ||
      isNaN(extraFeesAdded?.[0])
    ) {
      setFees([0]);
    } else {
      setFees(extraFeesAdded);
    }

    if (!additionalCols[0] || Object.keys(additionalCols[0]).length === 0) {
      setInvAdditionalTableHeaders([]);
      setDataForAdditionalTable([]);
    } else {
      setInvAdditionalTableHeaders(
        Object.values(additionalCols[0]).map((entry) => entry.text)
      );
      setDataForAdditionalTable(additionalCols.slice(1, additionalCols.length));
    }

    setTableNames(Object.keys(numberOfRows));

    calculateSum();
    findCalculatedSum();

    if (documentTypePrediction === "receipt") {
      setAutoCalculate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataForEditabletable,
    extendedPriceColIndex,
    combinedTableData,
    extraChargesAdded,
    extraDiscountsAdded,
    extraFeesAdded,
    documentTypePrediction,
  ]);
  let indexes = [];
  useEffect(() => {
    const handleClickOutsideContextMenu = (e) => {
      if (
        contextMenuRef.current &&
        !contextMenuRef.current.contains(e.target)
      ) {
        setShowContextMenu(false);
      }
    };

    document.addEventListener("click", handleClickOutsideContextMenu);

    return () => {
      document.removeEventListener("click", handleClickOutsideContextMenu);
    };
  }, []);

  useEffect(() => {
    const extendedPriceHeader = invNewTableheaders.find(
      (header) => header.text === "Extended Price"
    );
    if (extendedPriceHeader) {
      setExtendedPriceColIndex(extendedPriceHeader.key);
    } else {
      setExtendedPriceColIndex(-1);
    }
    // setExtendedPriceColIndex(
    //   invNewTableheaders.findIndex((header) => header.text === "Extended Price")
    // );
  }, [invNewTableheaders]);

  useEffect(() => {
    setDataForEditableTable(
      combinedTableData.slice(1, combinedTableData.length)
    );
    setInvNewTableHeaders(
      Object.keys(combinedTableData[0])
        .filter((key) => combinedTableData[0][key].selected_column)
        .map((key) => ({ ...combinedTableData[0][key], key }))
    );
    if (autoCalculate == false) {
      setAutoCalculate((prev) => false);
    }
  }, [combinedTableData]);

  useEffect(() => {
    setInvoiceTaxesSum(
      taxes?.[0] === "NA" || taxes?.length === 0 || isNaN(taxes?.[0])
        ? 0
        : taxes?.reduce((acc, tax) => acc + Number(tax), 0)
    );
  }, [taxes]);

  useEffect(() => {
    setExtraDiscountsSum(
      discounts?.[0] === "NA" ||
        discounts?.length === 0 ||
        isNaN(discounts?.[0])
        ? 0
        : discounts?.reduce((acc, discount) => acc + Number(discount), 0)
    );
  }, [discounts]);

  useEffect(() => {
    setExtraFeesSum(
      fees?.[0] === "NA" || fees?.length === 0 || isNaN(fees?.[0])
        ? 0
        : fees?.reduce((acc, fee) => acc + Number(fee), 0)
    );
  }, [fees]);

  useEffect(() => {
    const resetHumanVerificationState = () => {
      const verification = respData["human_verification_info"];
      setHumanVerified(verification["human_verified"]);
      setRejected(verification["rejected"]);
      setRejectedReason(verification["rejected_reason"] ?? "");
    };
    resetHumanVerificationState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respData]);

  const handleInputChangeTaxAddition = (event) => {
    setNewTax(event.target.value);
  };

  const addNewTax = () => {
    setTaxEdit(false);
    if (newTax.trim() !== "") {
      const taxArray = [...extraChargesAdded];
      if (taxArray[0] === "NA") {
        taxArray.shift();
        // taxArray[0] = 0
      }
      taxArray.push(parseFloat(newTax));
      setExtraChargesAdded(taxArray);
      setAddTax(false);
      setEditTax(false);
      setNewTax("");
    }
  };

  const handleInputChangeDiscountAddition = (event) => {
    setNewDiscount(event.target.value);
  };

  const addNewDiscount = () => {
    setDiscountEdit(false);
    if (newDiscount.trim() !== "") {
      const discountArray = [...extraDiscountsAdded];
      if (discountArray[0] === "NA") {
        discountArray.shift();
        // discountArray[0] = 0
      }
      discountArray.push(parseFloat(newDiscount));
      setExtraDiscountsAdded(discountArray);
      setNewDiscount("");
    } else {
    }
  };

  const handleInputChangeFeeAddition = (event) => {
    setNewFee(event.target.value);
  };

  const addNewFee = () => {
    setFeeEdit(false);
    if (newFee.trim() !== "") {
      const feeArray = [...extraFeesAdded];
      if (feeArray[0] === "NA") {
        feeArray.shift();
        // feeArray[0] = 0
      }
      feeArray.push(parseFloat(newFee));
      setExtraFeesAdded(feeArray);
      setNewFee("");
    }
  };

  const handleDiscountChange = (e) => {
    let discountValue;
    if (isNaN(e.target.value) || !e.target.value) {
      discountValue = 0;
    } else {
      discountValue = parseFloat(e.target.value);
    }
    setExtraDiscountsSum(discountValue);
    setDiscountEdit(true);
    setDiscounts([discountValue]);
  };

  const handleTaxChange = (e) => {
    let taxValue;
    if (isNaN(e.target.value) || !e.target.value) {
      taxValue = 0;
    } else {
      taxValue = parseFloat(e.target.value);
    }

    setInvoiceTaxesSum(taxValue);
    setTaxEdit(true);
    setTaxes([taxValue]);
  };

  const handleFeeChange = (e) => {
    let feeValue;
    if (isNaN(e.target.value) || !e.target.value) {
      feeValue = 0;
    } else {
      feeValue = parseFloat(e.target.value);
    }

    setExtraFeesSum(feeValue);
    setFeeEdit(true);
    setFees([feeValue]);
  };

  const findCalculatedSum = () => {
    const calcSum = (
      sum -
      extraDiscountsSum +
      parseFloat(invoiceTaxesSum) +
      parseFloat(extraFeesSum)
    ).toFixed(2);
    setCalculatedSum(calcSum);
    return calcSum;
  };

  useEffect(() => {
    findCalculatedSum();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    discounts,
    taxes,
    fees,
    sum,
    extraDiscountsSum,
    invoiceTaxesSum,
    extraFeesSum,
  ]);

  const setDataForTableSpecificTable = (tableName) => {
    setSelectedTable(true);
    setSelectedTableName(tableName);
    const data2 = additionalColsTables[tableName];
    if (data2 && Object.keys(data2).length === 0) {
      setAdditionalTableHeaders([]);
      setDataForTableSpecificAddTab([]);
      setAddTabData([]);
      return;
    }
    if (data2 && Object.keys(data2).length > 0) {
      const keys2 = Object.keys(data2);
      const additionalTableSpecificCols = [];

      for (let i = 0; i < Object.values(data2[keys2[0]]).length; i++) {
        const obj = {};
        for (const key of keys2) {
          obj[key] = data2[key][i];
        }
        additionalTableSpecificCols.push(obj);
        setAdditionalTableHeaders(
          Object.values(additionalTableSpecificCols[0]).map(
            (entry) => entry.text
          )
        );
        setAddTabData(additionalTableSpecificCols);
        setDataForTableSpecificAddTab(
          additionalTableSpecificCols.slice(
            1,
            additionalTableSpecificCols.length
          )
        );
      }
    } else {
      setAdditionalTableHeaders([]);
      setDataForTableSpecificAddTab([]);
    }
  };

  const generateEmptyRow = () => {
    const emptyRow = {};
    for (let i = 0; i < invNewTableheaders.length; i++) {
      emptyRow[i] = {
        confidence: 100,
        text: "",
        actual_text: "",
        selected_column: true,
        geometry: {},
      };
    }
    //console.log("the row generated is", emptyRow);
    return emptyRow;
  };

  const getEditRowData = async (changedInputs) => {
    const rowData = changedInputs.reduce((acc, currentItem) => {
      const existingItem = acc.find(
        (item) => item.indexId === currentItem.indexId
      );
      if (!existingItem) {
        acc.push(currentItem);
      } else {
        acc[acc.indexOf(existingItem)] = currentItem;
      }

      return acc;
    }, []);
    let payLoadData = {
      item_code: "",
      item_description: "",
    };

    let headerIndexes = [];
    invNewTableheaders.forEach((header, index) => {
      const headerText = header.text.toLowerCase().split(" ").join("_");
      if (headerText == "item_description" || headerText == "item_code") {
        headerIndexes.push({ index, headerText });
      }
    });
    console.log(rowData, "rowdata");
    console.log(headerIndexes, "headerindexex");
    rowData.map(({ indexId, value }) => {
      headerIndexes.map(({ headerText, index }) => {
        if (index == indexId) {
          if (headerText == "item_code") {
            payLoadData.item_code = value;
          } else if (headerText == "item_description") {
            payLoadData.item_description = value;
          } else if (headerText == "item_description") {
            if (indexId == 7) {
              payLoadData.item_description = value;
            }
          }
        }
      });
    });
    const payload = {
      vendor_id: vendorId,
      branch_id: branchId,
      item_code: payLoadData.item_code,
      item_description: payLoadData.item_description,
    };
    const response = await axiosInstance.post(`/item_master_lookup`, payload);

    const data = response?.data;
    const matching_items = data.matching_items;
    setMatchingItems(matching_items);

    setShowModal(true);
  };

  const handleKeyDown = (e) => {
    // Check for Ctrl + Shift + Enter key combination
    if (e.ctrlKey && e.shiftKey && e.key === "Enter") {
      setIgnoreBlur(true);
      getEditRowData(changedInputs);
    }
  };

  // document.addEventListener("keydown", function (event) {
  //   if (event.key == "z" && event.ctrlKey) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //     undoHandler();
  //   }
  // });

  const [undoStack, setUndoStack] = useState([]);

  const undoHandler = () => {
    // Clone the undoStack array
    const newUndoStack = [...undoStack];

    // Check if undoStack is empty
    if (newUndoStack.length === 0) {
      toast.warning("Cannot undo further!");
      return;
    }

    // Pop the last state from undoStack
    const lastState = newUndoStack.pop();

    // Update combinedTableData with the last state
    setCombinedTableData(lastState);

    // Update the undoStack with the newUndoStack
    setUndoStack(newUndoStack);
  };

  // Example of useEffect to handle side effects after state updates
  useEffect(() => {
    // Log current state of combinedTableData after it updates
    console.log(combinedTableData, "updated combined table data");

    // Any additional logic dependent on combinedTableData can go here

    // Ensure to include combinedTableData in the dependency array if needed
  }, [combinedTableData]);

  const handleKeyPress = (event) => {
    if (event.key === "z" && (event.ctrlKey || event.metaKey)) {
      event.preventDefault();
      event.stopPropagation();
      undoHandler();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [undoStack]);

  // const selectItemMasterLookUp = (index, matchingItem) => {

  //   const updated = [...dataForEditabletable];
  //   updated[editableRow][0]?.text = matchingItem.category;
  //   updated[editableRow][0]?.actual_text = matchingItem.category;
  //   updated[editableRow][1]?.text = matchingItem.item_code;
  //   updated[editableRow][1]?.actual_text = matchingItem.item_code;
  //   updated[editableRow][7]?.text = matchingItem.item_description;
  //   updated[editableRow][7]?.actual_text = matchingItem.item_description;

  //   setDataForEditableTable(updated);
  //   setEditableRow(-1);
  //   setRowId(-1);
  //   setChanged(false);
  //   setChangedInputs([]);
  //   setPayload({ row_id: null, row_data: {} });
  //   setShowModal(false);
  // };

  const addEmptyRow = () => {
    setCombinedTableData([...combinedTableData, generateEmptyRow()]);
    console.log("set here 1");
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
    let previousUndoStack = [...undoStack];
    previousUndoStack.push(combinedTableData);
    setUndoStack(previousUndoStack);
  };
  const handleCopyRowUp = (index) => {
    let previousUndoStack = [...undoStack];
    previousUndoStack.push(combinedTableData);
    setUndoStack(previousUndoStack);

    if (index === 1 || index < 1) {
      handleCopyRowDown(index);
      return;
    }
    const updatedData = [...combinedTableData];
    const copiedRow = JSON.parse(JSON.stringify(combinedTableData[index]));
    updatedData.splice(index + 1, 0, copiedRow);
    setCombinedTableData(updatedData);

    handleCloseContextMenu();
  };
  const handleCopyRowDown = (index) => {
    let previousUndoStack = [...undoStack];
    previousUndoStack.push(combinedTableData);
    setUndoStack(previousUndoStack);

    const updatedData = [...combinedTableData];
    const copiedRow = JSON.parse(JSON.stringify(combinedTableData[index]));
    updatedData.splice(index + 1, 0, copiedRow);
    setCombinedTableData(updatedData);

    handleCloseContextMenu();
  };
  const addRowAtIndex = (index) => {
    let previousUndoStack = [...undoStack];
    previousUndoStack.push(combinedTableData);
    setUndoStack(previousUndoStack);
    const updatedData = [...combinedTableData];
    updatedData.splice(index + 1, 0, generateEmptyRow());
    setCombinedTableData(updatedData);

    handleCloseContextMenu();
  };

  const shiftRowUp = (index) => {
    if (index === 1 || index < 1 || index >= combinedTableData.length) {
      toast.warning("Cannot shift row up!");
      return;
    }
    let previousUndoStack = [...undoStack];
    previousUndoStack.push(combinedTableData);
    setUndoStack(previousUndoStack);
    const updatedData = [...combinedTableData];
    const temp = updatedData[index];
    updatedData[index] = updatedData[index - 1];
    updatedData[index - 1] = temp;
    setCombinedTableData(updatedData);

    toast.success(`Row ${index} shifted up successfully!`);
  };

  const shiftRowDown = (index) => {
    if (
      index === combinedTableData.length - 1 ||
      index < 0 ||
      index >= combinedTableData.length - 1
    ) {
      toast.warning("Cannot shift row down!");
      return;
    }
    let previousUndoStack = [...undoStack];
    previousUndoStack.push(combinedTableData);
    setUndoStack(previousUndoStack);
    const updatedData = [...combinedTableData];
    const temp = updatedData[index];
    updatedData[index] = updatedData[index + 1];
    updatedData[index + 1] = temp;
    setCombinedTableData(updatedData);

    toast.success(`Row ${index} shifted down successfully!`);
  };

  const deleteRow = (deleteRowId) => {
    let previousUndoStack = [...undoStack];
    previousUndoStack.push(combinedTableData);
    setUndoStack(previousUndoStack);
    const updatedData = [...combinedTableData];
    updatedData.splice(deleteRowId + 1, 1);
    setCombinedTableData(updatedData);

    toast.success(`Row  ${deleteRowId} deleted successfully!,`);
  };

  const addCellAtIndexAndShiftColumnDown = (rowIndex, columnIndex) => {
    console.log("row index is", rowIndex, columnIndex);
    if (rowIndex < 0 || rowIndex >= combinedTableData.length) {
      toast.warning("Cannot add cell at this index!");
      return;
    }
    const updatedData = [...combinedTableData, generateEmptyRow()];
    for (let i = updatedData.length - 1; i >= rowIndex; i--) {
      if (i === rowIndex) {
        updatedData[i][columnIndex] = {
          text: "",
          actual_text: "",
          confidence: 100,
          selected_column: true,
        };
      } else {
        updatedData[i][columnIndex] = updatedData[i - 1][columnIndex];
      }
    }
    let previousUndoStack = [...undoStack];
    previousUndoStack.push(combinedTableData);
    setUndoStack(previousUndoStack);
    setCombinedTableData(updatedData);

    handleCloseContextMenu();
  };

  const deleteCellAtIndexAndShiftColumnUp = (rowIndex, columnIndex) => {
    if (rowIndex < 0 || rowIndex >= combinedTableData.length) {
      toast.warning("Cannot delete cell at this index!");
      return;
    }
    let previousUndoStack = [...undoStack];
    previousUndoStack.push(combinedTableData);
    setUndoStack(previousUndoStack);
    const updatedData = [...combinedTableData];
    for (let i = rowIndex; i < updatedData.length; i++) {
      if (i === updatedData.length - 1) {
        delete updatedData[i][columnIndex];
      } else {
        updatedData[i][columnIndex] = updatedData[i + 1][columnIndex];
      }
    }
    setCombinedTableData(updatedData);

    handleCloseContextMenu();
  };

  // Function to handle the edit click
  const handleEditIconClick = (rowId) => {
    setEditableRow(rowId);
    const initialRowData = {};
    invNewTableheaders.forEach((header) => {
      initialRowData[header.text] = String(
        dataForEditabletable[rowId][header.key]?.text ?? ""
      );
    });
    setPayload({ row_id: rowId, row_data: initialRowData });
  };

  // Function to handle input changes

  const handleInputChange = (header, value) => {
    const headerText = header.text;
    setPayload((prevPayload) => ({
      ...prevPayload,
      row_data: {
        ...prevPayload.row_data,
        [headerText]: String(value),
      },
    }));
  };

  const calculateExtendedPrice = async (changedInputs) => {
    let previousUndoStack = [...undoStack];
    previousUndoStack.push(combinedTableData);
    setUndoStack(previousUndoStack);

    if (autoCalculate == false) {
      const updatedDataForEditableTable = [...dataForEditabletable];
      changedInputs.forEach((entry) => {
        updatedDataForEditableTable[rowId][entry.indexId].text = entry.value;
      });
      setDataForEditableTable(updatedDataForEditableTable);
      setEditableRow(-1);
      setRowId(-1);
      setChanged(false);
      setChangedInputs([]);
      setPayload({ row_id: null, row_data: {} });
      return;
    }

    try {
      //console.log("sending payload as", newPayload);
      const response = await axiosInstance.post(
        `/update_extended_price`,
        newPayload
      );
      const updatedDataForEditableTable = [...dataForEditabletable];
      //console.log(" the extended price column index is", extendedPriceColIndex);
      if (updatedDataForEditableTable[rowId]?.[extendedPriceColIndex]) {
        updatedDataForEditableTable[rowId][extendedPriceColIndex].text =
          response.data["Extended Price"].text;
      } else {
        updatedDataForEditableTable[rowId][extendedPriceColIndex] = {
          text: response.data["Extended Price"].text,
          confidence: response.data["Extended Price"].confidence,
          selected_column: true,
        };
      }
      if (updatedDataForEditableTable[rowId]?.[extendedPriceColIndex]) {
        updatedDataForEditableTable[rowId][extendedPriceColIndex].confidence =
          response.data["Extended Price"].confidence;
      } else {
        updatedDataForEditableTable[rowId][extendedPriceColIndex] = {
          text: response.data["Extended Price"].text,
          confidence: response.data["Extended Price"].confidence,
          selected_column: true,
        };
      }
      changedInputs.forEach((entry) => {
        updatedDataForEditableTable[rowId][entry.indexId].text = entry.value;
      });

      // Update the state with the new data
      setDataForEditableTable(updatedDataForEditableTable);

      toast.success("Extended Price Calculated Successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      setEditableRow(-1);
      setRowId(-1);
      setChanged(false);
      setChangedInputs([]);
      setPayload({ row_id: null, row_data: {} });
    } catch (error) {
      toast.error("Error calculating Extended Price.");
    }
  };

  const autoCategorize = async () => {
    // Also created reversed data for combined table data
    const reversedCombinedTableData = {};
    const combinedTableDataKeys = Object.keys(combinedTableData[0]);
    for (const key of combinedTableDataKeys) {
      reversedCombinedTableData[key] = {};
    }
    // Iterate over the data and populate the reversedData object
    for (const obj of combinedTableData) {
      for (const key of combinedTableDataKeys) {
        reversedCombinedTableData[key] = {
          ...reversedCombinedTableData[key],
          [combinedTableData.indexOf(obj)]: obj[key],
        };
      }
    }
    const body = {
      restaurant_id: restaurantsId,
      vendor_id: vendorId,
      branch_id: branchId,
      table: reversedCombinedTableData,
    };

    try {
      const response = await axiosInstance.post(
        "/categorize_table_line_items",
        body
      );
      let previousUndoStack = [...undoStack];
      previousUndoStack.push(combinedTableData);
      setUndoStack(previousUndoStack);
      const processedTable = getRowWiseData(response.data.table);
      setCombinedTableData(processedTable);
      toast.success("Line items auto-categorized successfully!");
    } catch (error) {
      toast.error("An error occurred while auto-categorizing the line items");
    }
  };
  // Function to handle the save click
  const handleSaveClick = async ({
    humanVerified,
    rejected,
    rejectedReason,
    action,
  } = {}) => {
    setLoading(true);
    try {
      const payload = { ...respData };
      const updatedData = [...dataForEditabletable];
      updatedData.unshift(combinedTableData[0]);
      const reversedData = {};
      const keys = Object.keys(updatedData[0]);
      for (const key of keys) {
        reversedData[key] = {};
      }

      // Iterate over the data and populate the reversedData object
      for (const obj of updatedData) {
        for (const key of keys) {
          reversedData[key] = {
            ...reversedData[key],
            [updatedData.indexOf(obj)]: obj[key],
          };
        }
      }

      // Also created reversed data for combined table data
      const reversedCombinedTableData = {};
      const combinedTableDataKeys = Object.keys(combinedTableData[0]);
      for (const key of combinedTableDataKeys) {
        reversedCombinedTableData[key] = {};
      }
      // Iterate over the data and populate the reversedData object
      for (const obj of combinedTableData) {
        for (const key of combinedTableDataKeys) {
          reversedCombinedTableData[key] = {
            ...reversedCombinedTableData[key],
            [combinedTableData.indexOf(obj)]: obj[key],
          };
        }
      }

      payload["invoice_1"] = reversedData;
      payload["combined_invoice"] = reversedCombinedTableData;
      payload["invoice_metadata"]["extra_discounts_added"] =
        extraDiscountsAdded.map((c) => Number(c));
      payload["invoice_metadata"]["invoice_sold_to"] = invoiceSoldTo;
      payload["invoice_metadata"]["credit_card_name"] = creditCardName;
      payload["invoice_metadata"]["credit_card_number"] = creditCardNumber;
      payload["invoice_metadata"]["vendor_address"] = vendorAddress;
      payload["invoice_metadata"]["invoice_ship_to"] = invoiceShipTo;
      payload["invoice_metadata"]["invoice_bill_to"] = invoiceBillTo;
      payload["invoice_metadata"]["invoice_type"] = invoiceType;
      payload["invoice_metadata"]["invoice_number"] = invoiceNum;
      payload["invoice_metadata"]["invoice_due_date"] = dueDate;
      payload["invoice_metadata"]["invoice_date"] = invoiceDate;
      payload["invoice_metadata"]["vendor_name"] = vendorName;

      payload["invoice_metadata"]["invoice_total_from_table"] = sum;
      payload["invoice_metadata"]["invoice_total_amount"] = invoiceTotal;
      payload["invoice_metadata"]["quick_book_document_type"] =
        quickBooksDocType;
      payload["document_type_prediction"] = documentTypePrediction;
      payload["invoice_metadata"]["extra_charges_added"] =
        extraChargesAdded.map((c) => Number(c));
      payload["invoice_metadata"]["extra_fees_added"] = extraFeesAdded.map(
        (c) => Number(c)
      );
      humanVerified !== undefined &&
        (payload["human_verification_info"]["human_verified"] = humanVerified);
      rejected && (payload["human_verification_info"]["rejected"] = rejected);
      rejectedReason &&
        (payload["human_verification_info"]["rejected_reason"] =
          rejectedReason);
      payload["invoice_type"] = invoiceType;

      let message = "Invoice saved successfully!";
      if (humanVerified) {
        if (rejected) {
          message = "Invoice rejected successfully!";
        } else {
          message = "Invoice accepted successfully!";
        }
      }
      payload.last_action = action;
      if (action === "accept") {
        payload.verification_time =
          (new Date().getTime() - sessionStartTime) / 1000;
        const nt = new Date().getTime();
        setSessionStartTime(nt);
      }
      await axiosInstance
        .post(`/save_invoice`, payload)
        .then((res) => {
          toast.success(message);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast.error("Error in saving invoice.");
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Something went wrong.");
    }
  };

  const handleHeaderChange = async (newHeader, index) => {
    let previousUndoStack = [...undoStack];
    previousUndoStack.push(combinedTableData);
    setUndoStack(previousUndoStack);
    const updatedData = [...combinedTableData];

    if (newHeader === "-1") {
      updatedData.forEach((obj, i) => {
        const keys = Object.keys(obj);
        if (index >= 0 && index < keys.length) {
          delete obj[keys[index]];
          for (let i = index + 1; i < keys.length; i++) {
            obj[keys[i - 1]] = obj[keys[i]];
            delete obj[keys[i]];
          }
        }
      });
    } else {
      updatedData[0][index].text = newHeader;
      updatedData[0][index].confidence = 100;
    }

    if (autoCalculate == false) {
      let previousUndoStack = [...undoStack];
      previousUndoStack.push(combinedTableData);
      setUndoStack(previousUndoStack);
      setCombinedTableData(updatedData);
      console.log("set here n");
      return;
    }
    // setCombinedTableData(updatedData);

    try {
      const table = getColumnsWiseData(updatedData);
      const response = await axiosInstance.post("/validate_table", { table });
      if (response.data?.error) {
        toast.error("An error occurred while validating the table.");
      }
      const processedTable = getRowWiseData(response.data.processed_table);
      setCombinedTableData(processedTable);
      console.log("set here n0");
      toast.success("Table validated successfully!");
    } catch (error) {
      toast.error("An error occurred while validating the table.");
    }
  };

  const verifyTable = async () => {
    let updatedData = [...combinedTableData];
    try {
      const table = getColumnsWiseData(updatedData);
      const response = await axiosInstance.post("/validate_table", { table });
      if (response.data?.error) {
        toast.error("An error occurred while validating the table.");
      }
      const processedTable = getRowWiseData(response.data.processed_table);
      setCombinedTableData(processedTable);
      toast.success("Table validated successfully!");
    } catch (error) {
      toast.error("An error occurred while validating the table.");
    }
  };
  // useEffect(() => {
  //   if (needsVerification == true) {
  //     verifyTable();
  //   }
  // }, [needsVerification]);

  const handleColumnAdd = (e) => {
    let previousUndoStack = [...undoStack];
    previousUndoStack.push(combinedTableData);
    setUndoStack(previousUndoStack);
    const updatedData = [...combinedTableData];
    updatedData[0][Object.keys(updatedData[0]).length] = {
      text: "",
      confidence: 100,
      selected_column: true,
    };
    for (let i = 1; i < updatedData.length; i++) {
      updatedData[i][Object.keys(updatedData[0]).length - 1] = {
        text: "",
        confidence: 100,
        selected_column: true,
      };
    }
    setCombinedTableData(updatedData);

    // scroll to the right
    if (containerRef.current) {
      containerRef.current.scrollLeft = containerRef.current.scrollWidth;
    }
  };

  const addNewColumn = async (e, index) => {
    try {
      let previousUndoStack = [...undoStack];
      previousUndoStack.push(combinedTableData);
      setUndoStack(previousUndoStack);
      const updatedData = [...combinedTableData];
      updatedData[0][Object.keys(updatedData[0]).length] =
        additionalCols[0][index];
      for (let i = 1; i < updatedData.length; i++) {
        updatedData[i][Object.keys(updatedData[0]).length - 1] =
          additionalCols[i][index];
      }
      const table = getColumnsWiseData(updatedData);
      const response = await axiosInstance.post("/validate_table", { table });
      if (response.data?.error) {
        throw new Error(response.data?.message);
      }
      const processedTable = getRowWiseData(response.data.processed_table);
      // // Remove the added column from additionalCols
      // const updatedAdditionalCols = additionalCols.map((col) => {
      //   const updatedCol = { ...col };
      //   delete updatedCol[index];
      //   return updatedCol;
      // });
      toast.success("Column added successfully!");
      setShow(false);
      setShowTwo(false);
      setCombinedTableData(processedTable);
      console.log("setting here");
    } catch (error) {
      toast.error("An error occurred while adding the column.");
    }
  };

  const addColumnFromTableSpecificAdditionalColumns = async (e, index) => {
    let startIndex = 0;
    const tableStartIndex = {};
    Object.keys(numberOfRows).forEach((tableName) => {
      tableStartIndex[tableName] = startIndex;
      startIndex += numberOfRows[tableName];
    });
    const updatedData = [...combinedTableData];
    if (selectedTableName === "table_1") {
      let previousUndoStack = [...undoStack];
      previousUndoStack.push(combinedTableData);
      setUndoStack(previousUndoStack);
      updatedData[0][Object.keys(updatedData[0]).length] = addTabData[0][index];
      for (let i = 1; i < updatedData.length; i++) {
        const columnIndex = Object.keys(updatedData[0]).length - 1;
        if (
          dataForTableSpecificAddTab[i] &&
          dataForTableSpecificAddTab[i].length > index
        ) {
          updatedData[i][columnIndex] = dataForTableSpecificAddTab[i][index];
        } else {
          updatedData[i][columnIndex] = { text: "", confidence: 1 };
        }
      }
      toast.success("Column added successfully!");
      setShowTwo(false);
      setCombinedTableData(updatedData);
      console.log("set here x");
    } else {
      let previousUndoStack = [...undoStack];
      previousUndoStack.push(combinedTableData);
      setUndoStack(previousUndoStack);
      const startingIndex = tableStartIndex[selectedTableName];
      const nextStartingIndex =
        tableStartIndex[selectedTableName] + numberOfRows[selectedTableName];
      for (let i = startingIndex; i < nextStartingIndex - 1; i++) {
        const columnIndex = headerIndex;
        const dataIndex = i === startingIndex ? 0 : i - startingIndex;
        if (dataIndex < dataForTableSpecificAddTab.length) {
          if (!updatedData[i]) {
            updatedData[i] = {};
          }
          updatedData[i][columnIndex] =
            dataForTableSpecificAddTab[dataIndex][index];
        } else {
          if (!updatedData[i]) {
            updatedData[i] = {};
          }
          updatedData[i][columnIndex] = { text: "", confidence: 1 };
        }
      }
      toast.success("Column merged successfully!");
      setAdditionIndex(null);
      setHeaderIndex(null);
      setShowTwo(false);
      setCombinedTableData(updatedData);
      console.log("set here y");
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      const { key } = event;
      if (!dropdownRef.current) return;
      const dropdownItems =
        dropdownRef.current.querySelectorAll(".dropdown-item");
      const firstMatchingItem = Array.from(dropdownItems).find((item) =>
        item.textContent.trim().toLowerCase().startsWith(key.toLowerCase())
      );

      if (firstMatchingItem) {
        firstMatchingItem.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  function createCategoryExtendedPriceObject(tableData, header) {
    // Get the index of the category column
    let categoryIndex;

    const categoryHeader = header.find((header) => header.text === "Category");
    if (categoryHeader) {
      categoryIndex = categoryHeader.key;
    } else {
      categoryIndex = -1;
    }

    // Get the extended price column index
    let extendedPriceIndex;

    const extendedPriceHeader = header.find(
      (header) => header.text === "Extended Price"
    );
    if (extendedPriceHeader) {
      extendedPriceIndex = extendedPriceHeader.key;
    } else {
      extendedPriceIndex = -1;
    }

    if (categoryIndex === -1 || extendedPriceIndex === -1) {
      return null;
    }

    const categoryExtendedPriceObject = {};

    tableData.forEach((row) => {
      const category = row[categoryIndex]?.text;
      const extendedPrice = parseFloat(row[extendedPriceIndex]?.text);

      if (!isNaN(extendedPrice)) {
        if (category in categoryExtendedPriceObject) {
          categoryExtendedPriceObject[category] += extendedPrice;
        } else {
          categoryExtendedPriceObject[category] = extendedPrice;
        }
      }
    });

    return categoryExtendedPriceObject;
  }

  useEffect(() => {
    const obj = createCategoryExtendedPriceObject(
      dataForEditabletable,
      invNewTableheaders
    );
    setCategoryWiseSum(obj);
  }, [dataForEditabletable, invNewTableheaders]);

  const [filteredCategoryChoices, setFilteredCategoryChoices] = useState([]);

  useEffect(() => {
    if (!categoryChoices) return;
    const lowercaseSearchQuery = searchQuery.toLowerCase();
    const filtered = categoryChoices.filter((category) =>
      category.toLowerCase().trim().includes(lowercaseSearchQuery.trim())
    );
    setFilteredCategoryChoices((prev) => filtered);
  }, [searchQuery, categoryChoices]);

  return (
    <>
      {invoiceType !== "Summary Invoice" ? (
        <>
          <div className="d-flex justify-content-end mb-1 mt-1">
            <button
              className="btn btn-sm mx-1"
              style={{
                backgroundColor: "rgb(255, 242, 205)",
              }}
            >
              {" "}
              <PlusCircleFill
                height={15}
                width={15}
                onClick={handleColumnAdd}
              />
            </button>
            <Dropdown className="">
              <Dropdown.Toggle
                variant="outline"
                className="border w-90 bg-white"
                style={{
                  zIndex: 9999,
                  position: "relative",
                  lineHeight: "0.5",
                  verticalAlign: "middle",
                }}
              >
                <Sliders height={15} width={15} />
              </Dropdown.Toggle>
              <Dropdown.Menu
                style={{
                  width: "100%",
                  maxHeight: " 200px",
                  overflowY: "auto",
                  maxHeight: "200px",
                  overflowX: "scroll",
                  zIndex: 9999,
                  position: "absolute",
                }}
              >
                <Dropdown.Item
                  style={{
                    backgroundColor: viewDeleteColumn ? "#F8C8BE" : null,
                  }}
                  onClick={() => {
                    setViewDeleteColumn(!viewDeleteColumn);
                  }}
                >
                  View Row Delete Column
                </Dropdown.Item>
                <Dropdown.Item
                  style={{
                    backgroundColor: viewVerificationColumn ? "#F8C8BE" : null,
                  }}
                  onClick={() => {
                    setViewVerificationColumn(!viewVerificationColumn);
                  }}
                >
                  View Verification Column
                </Dropdown.Item>
                <Dropdown.Item
                  style={{
                    backgroundColor: viewShiftColumn ? "#F8C8BE" : null,
                  }}
                  onClick={() => {
                    setViewShiftColumn(!viewShiftColumn);
                  }}
                >
                  View Row Shift Column
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div
            ref={containerRef}
            style={{
              width: width || "100%",
              height: "471px",
              overflowX: "auto",
              overflowY: "auto",
            }}
          >
            <table
              ref={tableRef}
              className="table table-striped table-responsive table-bordered"
            >
              <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
                <tr>
                  {/* <th>S.No</th> */}
                  {invNewTableheaders.map((header, index) => (
                    <th
                      style={{
                        // width: "100px",
                        padding: 6,
                        paddingLeft: 40,
                        paddingRight: 40,
                        backgroundColor: "#FFF2CD",
                        textTransform: "capitalize",
                        verticalAlign: "middle",
                      }}
                      key={index}
                      className="resizable-header"
                    >
                      <ResizableCell width={100} style={{ minWidth: "100px" }}>
                        <CustomDropdown
                          data={additionalHeaders.sort()}
                          Objectkey={""}
                          displayKey={""}
                          className="w-auto"
                          maxWidth="150px"
                          minWidth="150px"
                          maxHeight="25px"
                          value={header.text}
                          showVerificationBadge={true}
                          verification_key={"human_verified"}
                          onSelect={(e) => {
                            handleHeaderChange(e, header.key);
                          }}
                        />
                      </ResizableCell>
                    </th>
                  ))}
                  {viewVerificationColumn && (
                    <th
                      style={{
                        padding: 3,
                        backgroundColor: "#FFF2CD",
                        textTransform: "capitalize",
                        verticalAlign: "middle",
                        fontWeight: "initial",
                        position: "sticky",
                        right: 0,
                        backgroundColor: "#FFF2CD",
                        zIndex: 1000,
                      }}
                    >
                      Item Status
                    </th>
                  )}
                  {viewDeleteColumn && (
                    <th
                      style={{
                        padding: 6,
                        backgroundColor: "#FFF2CD",
                        textTransform: "capitalize",
                        verticalAlign: "middle",
                        fontWeight: "initial",
                        backgroundColor: "#FFF2CD",
                        zIndex: 1000,
                      }}
                    >
                      <DeleteIcon height={20} width={20} />
                    </th>
                  )}
                  {viewShiftColumn && (
                    <th
                      style={{
                        padding: 6,
                        backgroundColor: "#FFF2CD",
                        textTransform: "capitalize",
                        verticalAlign: "middle",
                        fontWeight: "initial",
                        backgroundColor: "#FFF2CD",
                        zIndex: 1000,
                      }}
                    >
                      <ButtonGroup vertical>
                        <Button variant="outline-secondary btn-sm p-1">
                          <ArrowBarUp height={15} width={15} />
                        </Button>
                        <Button variant="outline-secondary btn-sm py-0 px-1">
                          <ArrowBarDown height={15} width={15} />
                        </Button>
                      </ButtonGroup>
                    </th>
                  )}
                  {/* 
                  <th
                    style={{
                      padding: 4,
                      verticalAlign: "middle",
                    }}
                  >
                    <Dropdown className="">
                      <Dropdown.Toggle
                        variant="outline"
                        className="border w-90 bg-white"
                        style={{
                          zIndex: 9999,
                          position: "relative",
                          lineHeight: "1.2",
                          verticalAlign: "middle",
                        }}
                      >
                        <Sliders height={15} width={15} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        style={{
                          width: "100%",
                          maxHeight: " 200px",
                          overflowY: "auto",
                          maxHeight: "200px",
                          overflowX: "scroll",
                          zIndex: 9999,
                          position: "absolute",
                        }}
                      >
                        <Dropdown.Item
                          style={{
                            backgroundColor: viewDeleteColumn
                              ? "#F8C8BE"
                              : null,
                          }}
                          onClick={() => {
                            setViewDeleteColumn(!viewDeleteColumn);
                          }}
                        >
                          View Delete Column
                        </Dropdown.Item>
                        <Dropdown.Item
                          style={{
                            backgroundColor: viewVerificationColumn
                              ? "#F8C8BE"
                              : null,
                          }}
                          onClick={() => {
                            setViewVerificationColumn(!viewVerificationColumn);
                          }}
                        >
                          View Verification Column
                        </Dropdown.Item>
                        <Dropdown.Item
                          style={{
                            backgroundColor: viewShiftColumn ? "#F8C8BE" : null,
                          }}
                          onClick={() => {
                            setViewShiftColumn(!viewShiftColumn);
                          }}
                        >
                          View Row Shift Column
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </th> */}
                </tr>
              </thead>
              <tbody
                onMouseLeave={() => {
                  onCellHover(null, null);
                  if (multipleBoundingBoxes) {
                    setMultipleBoundingBoxes(false);
                    toggleMultipleBoundingBoxes(null, 1);
                    return;
                  }
                }}
              >
                {Object.keys(dataForEditabletable).map((key, rowIndex) => (
                  <tr
                    key={rowIndex}
                    onMouseEnter={() => {
                      setMultipleBoundingBoxes(true);
                      //set the bounding box for the row
                      const rowPolygons = [];
                      for (const header of invNewTableheaders) {
                        if (
                          dataForEditabletable[key][header.key]?.geometry
                            ?.Polygon
                        ) {
                          rowPolygons.push(
                            dataForEditabletable[key][header.key]?.geometry
                              ?.Polygon
                          );
                        }
                      }
                      toggleMultipleBoundingBoxes(
                        rowPolygons,
                        dataForEditabletable[key][0]?.page_index + 1
                      );
                    }}
                  >
                    {/* <td>{rowIndex}</td> */}
                    {invNewTableheaders.map((header, colIndex) => (
                      <td
                        onContextMenu={(e) => {
                          handleContextMenu(e, rowIndex + 1, header.key);
                        }}
                        onMouseEnter={() => {
                          onCellHover(
                            dataForEditabletable[key][header.key].geometry
                              ?.Polygon,
                            dataForEditabletable[key][header.key]?.page_index +
                              1
                          );
                        }}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={`Confidence: ${
                          dataForEditabletable[key][header.key]?.confidence
                        }`}
                        key={colIndex}
                        onClick={() => {
                          setRowId(key);
                          setEditableRow(key);
                          handleEditIconClick(key);
                        }}
                        style={{
                          width: "100px",
                          fontSize: "14px",
                          cursor: "pointer",
                          backgroundColor: `${
                            dataForEditabletable[key][header.key]?.confidence <
                            60
                              ? "#F8C8BE"
                              : null
                          }`,
                        }}
                        className={`${
                          dataForEditabletable[key][header.key]?.confidence < 60
                            ? "border border-danger"
                            : "border border-success"
                        }`}
                      >
                        {editableRow === key ? (
                          header.text == "Category" ? (
                            <Dropdown
                              onSelect={(e) => {
                                handleInputChange(header, e);
                                setChanged(true);
                                setChangedInputs([
                                  ...changedInputs,
                                  {
                                    indexId: colIndex,
                                    value: e,
                                  },
                                ]);
                                calculateExtendedPrice([
                                  {
                                    indexId: colIndex,
                                    value: e,
                                  },
                                ]);
                              }}
                            >
                              <Dropdown.Toggle
                                variant="outline"
                                className="border w-100 bg-white"
                                style={{
                                  lineHeight: "1.2",
                                  fontSize: "14px",
                                }}
                              >
                                {dataForEditabletable[key][header.key]?.text}
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                style={{
                                  maxHeight: "200px",
                                  overflowY: "scroll",
                                  overflowX: "hidden",
                                }}
                              >
                                <FormControl
                                  type="text"
                                  className="form-control mx-auto mb-4"
                                  placeholder="Search"
                                  onInput={(e) => {
                                    setSearchQuery(e.target.value);
                                  }}
                                  style={{
                                    width: "90%",
                                  }}
                                />
                                <Dropdown.Item
                                  style={{
                                    lineHeight: "1",
                                    fontSize: "14px",
                                  }}
                                  eventKey={header.key}
                                >
                                  {dataForEditabletable[key][header.key]?.text}
                                </Dropdown.Item>

                                {filteredCategoryChoices.map(
                                  (option, index) => (
                                    <Dropdown.Item
                                      style={{
                                        lineHeight: "1",
                                        fontSize: "14px",
                                      }}
                                      key={index}
                                      eventKey={option}
                                    >
                                      {option}
                                    </Dropdown.Item>
                                  )
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          ) : (
                            ///////////////////////////////////////////////////////Input for adding descrption and Item code
                            <textarea
                              className="form-control"
                              width={"100%"}
                              value={
                                changed
                                  ? rowDataForExtendedPrice.header
                                  : dataForEditabletable[key][header.key]?.text
                              }
                              disabled={
                                header === "Extended Price" ? true : false
                              }
                              onChange={(e) => {
                                handleInputChange(header, e.target.value);
                                setChanged(true);
                                setChangedInputs([
                                  ...changedInputs,
                                  {
                                    indexId: header.key,
                                    value: e.target.value,
                                  },
                                ]);
                              }}
                              onKeyDown={handleKeyDown}
                              onBlur={(e) => {
                                // if input is blurred because I pressed ctrl + shift + enter, then return
                                if (ignoreBlur === true) {
                                  return;
                                }

                                calculateExtendedPrice([
                                  {
                                    indexId: header.key,
                                    value: e.target.value,
                                  },
                                ]);
                              }}
                            ></textarea>
                          )
                        ) : (
                          <>
                            <span>
                              {dataForEditabletable[key][header.key]?.text}
                              <Tooltip id={colIndex} />
                            </span>
                          </>
                        )}
                      </td>
                    ))}
                    {viewVerificationColumn && (
                      <td
                        style={{
                          position: "sticky",
                          right: 0,
                          backgroundColor: "#FFF2CD",
                        }}
                      >
                        {lineItemMetaData[rowIndex + 1]?.["human_verified"] ===
                        true ? (
                          <PatchCheckFill height={15} width={15} fill="blue" />
                        ) : (
                          <PatchExclamationFill height={15} width={15} />
                        )}
                      </td>
                    )}
                    {viewDeleteColumn && (
                      <td
                        style={{
                          padding: 6,
                          backgroundColor: "#FFF2CD",
                          textTransform: "capitalize",
                          verticalAlign: "middle",
                          fontWeight: "initial",
                          backgroundColor: "#FFF2CD",
                          zIndex: 1000,
                        }}
                      >
                        <DeleteIcon
                          height={20}
                          width={20}
                          onClick={() => deleteRow(rowIndex + 1)}
                        />
                      </td>
                    )}
                    {viewShiftColumn && (
                      <td
                        style={{
                          padding: 6,
                          backgroundColor: "#FFF2CD",
                          textTransform: "capitalize",
                          verticalAlign: "middle",
                          fontWeight: "initial",
                          backgroundColor: "#FFF2CD",
                          zIndex: 1,
                        }}
                      >
                        <ButtonGroup vertical>
                          <Button variant="outline-secondary btn-sm p-1">
                            <ArrowBarUp
                              height={15}
                              width={15}
                              onClick={() => shiftRowUp(rowIndex + 1)}
                            />
                          </Button>
                          <Button variant="outline-secondary btn-sm py-0 px-1">
                            <ArrowBarDown
                              height={15}
                              width={15}
                              onClick={() => shiftRowDown(rowIndex + 1)}
                            />
                          </Button>
                        </ButtonGroup>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            {showContextMenu && (
              <div ref={contextMenuRef}>
                <ContextMenu
                  xPos={contextMenuPosition.xPos}
                  handleCopyRowUp={handleCopyRowUp}
                  handleCopyRowDown={handleCopyRowDown}
                  yPos={contextMenuPosition.yPos}
                  handleClose={handleCloseContextMenu}
                  handleAddCell={addCellAtIndexAndShiftColumnDown}
                  handleAddRow={addRowAtIndex}
                  handleDeleteRow={deleteRow}
                  handleDeleteCell={deleteCellAtIndexAndShiftColumnUp}
                  shiftRowUp={shiftRowUp}
                  shiftRowDown={shiftRowDown}
                  handleColumnAdd={handleColumnAdd}
                  rowIndex={indexOfRow}
                  columnIndex={indexOfColumn}
                />
              </div>
            )}
          </div>
          <div className="mx-2 my-2bg-white border-rounded border-white rounded shadow-sm p-3 d-flex justify-content-end align-items-center">
            {/* Render a toggle button to turn on and off auto calculate */}
            <div>
              {undoStack.length > 0 && (
                <button
                  className="btn btn-sm btn-secondary mx-2"
                  style={{
                    borderRadius: "45%",
                  }}
                  onClick={undoHandler}
                >
                  <ArrowCounterclockwise height={15} width={15} fill="white" />
                </button>
              )}
            </div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                checked={autoCalculate}
                onChange={(e) => setAutoCalculate(e.target.checked)}
              />
              <label
                className="form-check-label"
                htmlFor="flexSwitchCheckDefault"
              >
                Auto Calculate
              </label>
            </div>
            <div className="form-check form-switch mx-2">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                checked={multipleBoundingBoxes}
                onChange={(e) => {
                  setMultipleBoundingBoxes(e.target.checked);
                  if (!e.target.checked) {
                    toggleMultipleBoundingBoxes(null, 0);
                    return;
                  }
                  const polygons = [];
                  dataForEditabletable.forEach((item) => {
                    Object.values(item).forEach((value) => {
                      if (
                        value.geometry &&
                        value.geometry.Polygon &&
                        value.page_index + 1 === pageNum
                      ) {
                        polygons.push(value.geometry.Polygon);
                      }
                    });
                  });
                  toggleMultipleBoundingBoxes(polygons, pageNum);
                }}
              />
              <label
                className="form-check-label"
                htmlFor="flexSwitchCheckDefault"
              >
                View Detected
              </label>
            </div>
          </div>
        </>
      ) : null}
      <div className="d-flex justify-content-center align-items-center border border-white shadow-sm rounded p-1 bg-white my-2">
        <div className="col-3 mx-2 text-center">
          <PlusCircleFill
            onClick={() => {
              addEmptyRow();
            }}
            className="mx-auto fs-32 text-warning"
            style={{
              fontSize: "2rem",
              cursor: "pointer",
            }}
          ></PlusCircleFill>
          <p className="mx-auto text-center">Add Row</p>
        </div>
      </div>

      <div>
        <div className="row justify-content-around mt-2 p-2 border border-gray rounded mx-2 text-center">
          {/* <div className="col-3 mx-2 text-center">
                <PlusCircleFill
                  onClick={() => {
                    addEmptyRow();
                  }}
                  className="mx-auto fs-32 text-warning"
                  style={{
                    fontSize: "2rem",
                    cursor: "pointer"
                  }}
                ></PlusCircleFill>
                <p className="mx-auto text-center">Add Row</p>
              </div> */}
          <table className="col-12">
            <thead>
              <tr>
                <th>Action</th>
                <th>Updated At</th>
                <th>Updated By</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    textTransform: "capitalize",
                  }}
                >
                  {latest_update_info?.action}
                </td>
                <td>{latest_update_info?.updated_at?.toLocaleUpperCase()}</td>
                <td>{latest_update_info?.updated_by}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {!isReview ? (
        <InvoiceComponent
          sum={sum}
          extraDiscountsSum={extraDiscountsSum}
          invoiceTaxesSum={invoiceTaxesSum}
          extraFeesSum={extraFeesSum}
          calculatedSum={calculatedSum}
          invoiceTotalFromtable={invoiceTotalFromtable}
          invoiceTotal={invoiceTotal}
          setInvoiceTotal={setInvoiceTotal}
          editTotal={editTotal}
          setEditTotal={setEditTotal}
          editDiscount={editDiscount}
          setEditDiscount={setEditDiscount}
          editTax={editTax}
          setEditTax={setEditTax}
          editFees={editFees}
          setEditFees={setEditFees}
          addDiscount={addDiscount}
          setAddDiscount={setAddDiscount}
          addTax={addTax}
          setAddTax={setAddTax}
          addFee={addFee}
          setAddFee={setAddFee}
          handleDiscountChange={handleDiscountChange}
          handleTaxChange={handleTaxChange}
          handleFeeChange={handleFeeChange}
          taxes={taxes}
          discounts={discounts}
          fees={fees}
          setFees={setFees}
          newFee={newFee}
          handleInputChangeFeeAddition={handleInputChangeFeeAddition}
          addNewFee={addNewFee}
          setDiscounts={setDiscounts}
          newDiscount={newDiscount}
          handleInputChangeDiscountAddition={handleInputChangeDiscountAddition}
          addNewDiscount={addNewDiscount}
          newTax={newTax}
          handleInputChangeTaxAddition={handleInputChangeTaxAddition}
          addNewTax={addNewTax}
          extraDiscountsAdded={extraDiscountsAdded}
          setExtraDiscountsAdded={setExtraDiscountsAdded}
          extraChargesAdded={extraChargesAdded}
          setExtraChargesAdded={setExtraChargesAdded}
          extraFeesAdded={extraFeesAdded}
          setExtraFeesAdded={setExtraFeesAdded}
        />
      ) : (
        <EngReview />
      )}

      <div className="d-flex justify-content-end my-2 mx-2 mb-4">
        <button
          className="shadow-lg btn mx-1 btn-sm mb-2"
          style={{ backgroundColor: "rgb(255, 242, 205)" }}
          onClick={() => {
            setisReview(!isReview);
          }}
        >
          Engineering Issues
        </button>
        <button
          className="shadow-lg btn btn-success mx-1 btn-sm mb-2"
          onClick={() => {
            autoCategorize();
          }}
        >
          Auto Categorize
        </button>
        <span
         data-bs-toggle="tooltip"
         data-bs-placement="top"
         title={
           acceptAndRejectControl?.accept_disabled
             ? acceptAndRejectControl?.accept_disabled_reason
             : "Click to Accept"
         }
        >
        <Tooltip id="" 
        />
        <button
          className="shadow-lg btn mx-1 btn-sm mb-2"
          style={{ backgroundColor: "rgb(255, 242, 205)" }}
          onClick={() => {
            // setRejected(false);
            // setHumanVerified(true);
            handleSaveClick({ humanVerified: true, action: "accept" });
          }}
          disabled={acceptAndRejectControl?.accept_disabled}
        >
          Accept
        </button>
        </span>
        <span
         data-bs-toggle="tooltip"
         data-bs-placement="top"
         title={
          acceptAndRejectControl?.reject_disabled
            ? acceptAndRejectControl?.reject_disabled_reason
            : "Click to Reject"
        }
        >
        <Tooltip id="" />
        <button
          className="shadow-lg btn mx-1 btn-sm btn-danger mb-2"
          onClick={() => setRejectModal(true)}
          disabled={acceptAndRejectControl?.reject_disabled}
        >
          Reject
        </button>
        </span>
        <button
          className="shadow-lg btn mx-1 btn-sm btn-warning mb-2"
          onClick={() => handleSaveClick({ action: "save" })}
        >
          {loading && (
            <Spinner
              className="mx-1"
              animation="border"
              role="status"
              size="sm"
            />
          )}
          Save
        </button>
      </div>

      <CombinedCols
        show={show}
        handleClose={handleClose}
        invAdditionalTableheaders={invAdditionalTableheaders}
        dataForAdditionaltable={dataForAdditionaltable}
        addNewColumn={addNewColumn}
      />

      <UnrecognizedCols
        showTwo={showTwo}
        handleCloseTwo={handleCloseTwo}
        tableNames={tableNames}
        setDataForTableSpecificTable={setDataForTableSpecificTable}
        additionalTableheaders={additionalTableheaders}
        dataForTableSpecificAddTab={dataForTableSpecificAddTab}
        addColumnFromTableSpecificAdditionalColumns={
          addColumnFromTableSpecificAdditionalColumns
        }
        additionalHeaders={additionalHeaders}
        invNewTableheaders={invNewTableheaders}
        addTabData={addTabData}
        headerIndex={headerIndex}
        setHeaderIndex={setHeaderIndex}
        additionIndex={additionIndex}
        setAdditionIndex={setAdditionIndex}
        selectedTableName={selectedTableName}
        selectedTable={selectedTable}
      />

      <RejectModal
        show={rejectModal}
        reason={rejectedReason}
        handleClose={(v) => {
          if (typeof v === "string") {
            setRejectedReason(v);
            setRejected(true);
            setHumanVerified(true);
            handleSaveClick({
              humanVerified: true,
              rejected: true,
              rejectedReason: v,
              action: "reject",
            });
          }
          setRejectModal(false);
        }}
      />
      <Modal show={showModal} onHide={() => setShowModal(false)} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Item Master Lookup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Item Master Lookup</p>
          <Table bordered responsive striped>
            <thead>
              <tr>
                <th>Category</th>
                <th>Item Code</th>
                <th>Item Description</th>
                <th>Unit Price</th>
              </tr>
            </thead>
            <tbody>
              {matchingItems?.map((item, index) => (
                <tr
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    const newData = [...dataForEditabletable];

                    invNewTableheaders.forEach((header, i) => {
                      const headerText = header.text
                        .toLowerCase()
                        .split(" ")
                        .join("_");

                      // Check if headerText matches any condition
                      if (
                        [
                          "category",
                          "item_code",
                          "item_description",
                          "unit_price",
                        ].includes(headerText)
                      ) {
                        indexes.push({ i, headerText }); // Push the index if condition is met
                      }
                    });
                    let headerIndexes = [];
                    invNewTableheaders.forEach((header, index) => {
                      const headerText = header.text
                        .toLowerCase()
                        .split(" ")
                        .join("_");
                      if (
                        headerText == "item_description" ||
                        headerText == "item_code"
                      ) {
                        headerIndexes.push({ index, headerText });
                      }
                    });

                    console.log(indexes);
                    let item_des_index = "";
                    newData.map((row, i) => {
                      if (row[indexes[2].i].text == item.item_description) {
                        item_des_index = indexes[2].i;
                      } else if (row[7] == item.item_description) {
                        item_des_index = 7;
                      }
                    });
                    console.log(newData);
                    console.log("editableRow", editableRow);
                    console.log(item_des_index, "indem des");
                    newData[editableRow][indexes[0].i].text = item.category;
                    newData[editableRow][indexes[0].i].actual_text =
                      item?.category;
                    newData[editableRow][indexes[1].i].text = item.item_code;

                    // newData[editableRow][indexes[2].i].text =
                    if (item_des_index == "") {
                      newData[editableRow][7 || indexes[2].i].text =
                        item.item_description;
                    } else {
                      newData[editableRow][item_des_index].text =
                        item.item_description;
                    }
                    if (item.unit_price !== null) {
                      newData[editableRow][indexes[3]?.i].text =
                        item?.unit_price;
                    }
                    // newData[editableRow][indexes[3]]?.actual_text =
                    //   item?.unit_price;

                    indexes = []; // Output newData array for debugging

                    setDataForEditableTable(newData);
                    setEditableRow(-1);
                    setRowId(-1);
                    setChanged(false);
                    setChangedInputs([]);
                    setPayload({ row_id: null, row_data: {} });
                    setShowModal(false);
                    setIgnoreBlur(false);

                    // Perform other actions if needed
                  }}
                >
                  <td>{item.category}</td>
                  <td>{item.item_code}</td>
                  <td>{item.item_description}</td>
                  <td>{item.unit_price}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
