import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import TableComponent from "./TableComponent";
import { axiosInstance } from "lib/axios";
import { ColorRing } from "react-loader-spinner";
import { toast } from "react-toastify";
import { pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import useAppStore from "../../../store/global";
import { NavHeader } from "../../layout/NavHeader";
import { InvoiceHeader } from "../../layout/InvoiceHeader";
import { PDFComponent } from "./PDFComponent";
import { useCommonIssuesActions } from "../../../store/commonIssues";
import { InvoicePagination } from "../../layout/InvoicePagination";
import useReviewFilterStore from "../store";
import { useParams } from 'react-router-dom';
import Assignment from "./Assignment";
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default function Main() {
    const {
      tableData,
      setTableData,
      pdfUrl,
      setPdfUrl,
      setInvoiceNum,
      setInvoiceDate,
      setdueDate,
      setvendorName,
      setInvoiceBalance,
      setInvoiceTotal,
      setInvoicePaymentTerms,
      setInvoiceRemitTo,
      setInvoiceRoute,
      setInvoiceShipTo,
      setInvoiceBillTo,
      setInvoiceType,
      setInvoiceGlobalAddresses,
      setInvoiceSoldTo,
      setTotalPagesRcvd,
      setTotalPagesInInvoice,
      setTotalPagesInInvoiceFromGlobal,
      setvendorNamesSource,
      setTotalPagesProcessed,
      setHumanVerificationReqd,
      setInvoiceTotalFromtable,
      setInvoiceDiscount,
      setInvoiceTaxes,
      setFailedReasons,
      setVerdict,
      setConcerns,
      loading,
      setLoading,
      invoiceTableData,
      setInvoiceTableData,
      combinedTableData,
      setCombinedTableData,
      setExtraChargesAdded,
      setExtraDiscountsAdded,
      setRespData,
      selectedFilter,
      setAdditionalCols,
      setAdditionalHeaders,
      setCategoryChoices,
      setNumberOfRows,
      setAdditionalColsTables,
      saved,
      setSaved,
      getVerified,
      setStatus,
      showVertical,
      setBlobPath,
      branchId,
      vendorId,
      setPdfSource,
      setVersion,
      setDocumentTypePrediction,
      setInvoiceUUID,
      setBranchId,
      setRerunStatus,
      serVersion
    } = useAppStore();
  
    const {setIssues, setUUID} = useCommonIssuesActions()
    const { uuid, page } = useParams();
    useEffect(() => {
      setLoading(true);
      const body = {
        uuid: uuid,
      };
      const apiUrl = "/get_extracted_invoices_by_uuid";
      axiosInstance
        .post(apiUrl, body)
        .then((response) => {
          // Get data for table
          console.log(response?.data)
          const data = response.data.response.invoice;
          setPdfUrl(response.data?.response?.pdf_link);
          setBranchId(response?.data?.response?.vendor_details.branch_id)
          setBlobPath(response.data?.response?.blob_path);
          setPdfSource(response.data?.response?.pdf_source);
          
          const issues = response.data?.response?.common_issues_in_invoice_extraction
          issues && setIssues(issues)
          setUUID(response.data?.response?.uuid)
          if (Object.keys(data).length === 0) {
            toast.error("No data found for this invoice");
            setStatus(false);
            setTableData([]);
            setLoading(false);
            // setPdfUrl(response.data.response.pdf_link);
            setInvoiceNum(response.data.response.invoice_number);
            return;
          }
  
          const keys = Object.keys(data);
          const tableData = [];
  
          for (let i = 0; i < Object.values(data[keys[0]]).length; i++) {
            const obj = {};
            for (const key of keys) {
              obj[key] = data[key][i];
            }
            tableData.push(obj);
          }
  
          setTableData(tableData);
  
          // Get data for invoice table
          const data0 = response.data.response.invoice_1;
          if (Object.keys(data0).length === 0) {
            setInvoiceTableData([]);
            setLoading(false);
            // setPdfUrl(response.data.response.pdf_link);
            setInvoiceNum(response.data.response.invoice_number);
          }
  
          const keys0 = Object.keys(data0);
          const invoicetableData = [];
  
          for (let i = 0; i < Object.values(data0[keys0[0]]).length; i++) {
            const obj = {};
            for (const key of keys0) {
              obj[key] = data0[key][i];
            }
            invoicetableData.push(obj);
          }
          setInvoiceTableData(invoicetableData);
  
          // Get data for additional table
          const data1 =
            response.data.response.additional_columns[
              "combined_additional_columns"
            ];
          if (data1 && Object.keys(data1).length === 0) {
            setAdditionalCols([]);
            setLoading(false);
          }
          if (data1 && Object.keys(data1).length > 0) {
            const keys1 = Object.keys(data1);
            const additionaltableData = [];
  
            for (let i = 0; i < Object.values(data1[keys1[0]]).length; i++) {
              const obj = {};
              for (const key of keys1) {
                obj[key] = data1[key][i];
              }
              additionaltableData.push(obj);
            }
            setAdditionalCols(additionaltableData);
          } else {
            setAdditionalCols([]);
          }
  
          //Get data for the combined table
          const data2 =
            response.data.response.combined_invoice
          if (data2 && Object.keys(data2).length === 0) {
            setCombinedTableData([]);
            setLoading(false);
          }
          if (data2 && Object.keys(data2).length > 0) {
            const keys2 = Object.keys(data2);
            const combinedData = [];
  
            for (let i = 0; i < Object.values(data2[keys2[0]]).length; i++) {
              const obj = {};
              for (const key of keys2) {
                obj[key] = data2[key][i];
              }
              combinedData.push(obj);
            }
            setCombinedTableData(combinedData);
          } else {
            setCombinedTableData([]);
          }
        
          setAdditionalColsTables(
            response.data.response.additional_columns[
              "table_specific_additional_columns"
            ]
          );
          // setPdfUrl(response.data.response.pdf_link);
          setInvoiceNum(response.data.response.invoice_metadata.invoice_number);
          setCategoryChoices(response.data.response.category_choices)
          setInvoiceDate(response.data.response.invoice_metadata.invoice_date);
          setvendorName(response.data.response.invoice_metadata.vendor_name);
          setdueDate(response.data.response.invoice_metadata.invoice_due_date);
          setInvoiceBalance(
            response.data.response.invoice_metadata.invoice_balance_due
          );
          setInvoiceTotal(
            response.data.response.invoice_metadata.invoice_total_amount
          );
          setInvoicePaymentTerms(
            response.data.response.invoice_metadata.invoice_payment_terms
          );
          setInvoiceRemitTo(
            response.data.response.invoice_metadata.invoice_remit_to
          );
          setInvoiceRoute(response.data.response.invoice_metadata.invoice_route);
          setInvoiceShipTo(
            response.data.response.invoice_metadata.invoice_ship_to
          );
          setInvoiceBillTo(
            response.data.response.invoice_metadata.invoice_bill_to
          );
          setInvoiceType(response.data.response.invoice_type);
          setInvoiceGlobalAddresses(
            response.data.response.invoice_metadata.invoice_global_addresses
          );
          setInvoiceSoldTo(
            response.data.response.invoice_metadata.invoice_sold_to
          );
          setTotalPagesRcvd(
            response.data.response.invoice_metadata.total_number_of_pages_received
          );
          setTotalPagesInInvoice(
            response.data.response.invoice_metadata.total_pages_in_invoice
          );
          setTotalPagesInInvoiceFromGlobal(
            response.data.response.invoice_metadata
              .total_pages_in_invoice_from_global
          );
          setvendorNamesSource(
            response.data.response.invoice_metadata.vendor_name_source
          );
          setTotalPagesProcessed(
            response.data.response.invoice_metadata
              .total_number_of_pages_processed
          );
          setHumanVerificationReqd(
            response.data.response.human_verification_required
          );
          setInvoiceTotalFromtable(
            response.data.response.invoice_metadata.invoice_total_from_table
          );
          setInvoiceDiscount(
            response.data.response.invoice_metadata.invoice_discount
          );
          setInvoiceTaxes(response.data.response.invoice_metadata.invoice_taxes);
          setVerdict(response.data.response.human_verification_info.verdict);
          setFailedReasons(
            response.data.response.human_verification_info.failed_reasons
          );
          setConcerns(response.data.response.human_verification_info.concerns);
          let extra_charges_added =
            response.data.response.invoice_metadata.extra_charges_added;
          if (!extra_charges_added || extra_charges_added[0] === "NA") {
            extra_charges_added = [0];
          }
          setExtraChargesAdded(extra_charges_added);
          let extra_discounts_added =
            response.data.response.invoice_metadata.extra_discounts_added;
          if (!extra_discounts_added || extra_discounts_added[0] === "NA") {
            extra_discounts_added = [0];
          }
          setExtraDiscountsAdded(extra_discounts_added);
          setRespData(response.data.response);
          setAdditionalHeaders(
            response.data.response.invoice_metadata
              .processed_table_header_candidates
          );
          setNumberOfRows(
            response.data.response.invoice_metadata.number_of_rows_in_tables
          );
          setVersion(response.data.response.version);
          setDocumentTypePrediction(response.data.response.document_type_prediction)
          setInvoiceUUID(response.data.response.uuid)
          setRerunStatus(response.data.response.rerun_status)
          setLoading(false);
          setSaved(false);
        })
        .catch((error) => {
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  return (
    <>
    <NavHeader view="details" />
    <InvoiceHeader />
    <div
      className="mx-5"
      style={{
        marginTop: loading || getVerified === "false" ? "0px" : "35px",
      }}
    >
      {loading ? (
        <>
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={[
              "#F57E37",
              "#1BBEE9",
              "#F57E37",
              "#1BBEE9",
              "#F57E37",
              "#1BBEE9",
            ]}
          />
        </>
      ) : (
        <>
          {page === "pdf" ? (
            <><PDFComponent /><InvoicePagination/></>
          ) : page === "table" ? (
            <div className="mb-4" style={{ height: "530px" }}>
              <TableComponent
                data={tableData}
                invoiceTableData={invoiceTableData}
                combinedTableData = {combinedTableData}
                setCombinedTableData = {setCombinedTableData}
              />
              <InvoicePagination/>
            </div>
          ) : (
            <Row>
              <Col md={showVertical ? 12 : 6}>
                <PDFComponent  pagination={true}/>
              </Col>
              <Col md={showVertical ? 12 : 6}>
                <div className="mb-4" style={{ height: "530px" }}>
                  <TableComponent
                    data={tableData}
                    invoiceTableData={invoiceTableData}
                    combinedTableData = {combinedTableData}
                    setCombinedTableData = {setCombinedTableData}
                  />
                </div>
              </Col>
            </Row>
          )}
          <Assignment/>
        </>
      )}
    </div>
  </>
  )
}
