import React from "react";
import { Dropdown } from "react-bootstrap";
import { statusFilterOptions as items } from "../store";
import useReviewFilterStore from "../store";

export default function StatusDropdown() {
  const {
    status,
    actions: { setStatus },
  } = useReviewFilterStore();
  const changeStatus = (status) => {
    setStatus(status);
  };
  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="outline"
        className="border w-100 bg-white"
        style={{
          lineHeight: "1.2",
          fontSize: "14px",
        }}
      >
        {status == 'null' ? "Select Status" : status}
      </Dropdown.Toggle>
      <Dropdown.Menu
        style={{
          minHeight: "100px",
          maxHeight: "200px",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <Dropdown.Item>
           
        </Dropdown.Item>

        {items?.map((filter, i) => (
          <Dropdown.Item
            key={i}
            onClick={() => {
              changeStatus(filter);
            }}
          >
            {filter}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
