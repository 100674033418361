import { useState, useEffect } from "react";
import {
  Button,
  Dropdown,
  FloatingLabel,
  Modal,
  Spinner,
} from "react-bootstrap";
import { useCommonIssuesStore } from "store/commonIssues";
import { Form } from "react-bootstrap";
import { axiosInstance } from "lib/axios";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { useCommonIssuesActions } from "store/commonIssues";
import useAppStore from "store/global";
import { useReviewFilterParams } from "./engissues/store";
import { useGetEngineeringIssues } from "./engissues/api";
import { formatDate } from "utils/format";
import { set } from "date-fns";
import { queryClient } from "lib/react-query";
import EngIssuesNewTable from "./engissues/engIssuesNewTable";
const useAddIssue = () => {
  const { addIssue } = useCommonIssuesActions();
  return useMutation({
    mutationFn: (data) => axiosInstance.post("/add_issue", data),
    onError: (error) => toast.error(error),
    onSuccess: (_data, variables) => {
      addIssue(variables.issue);
      toast.success("Added an Issue");
    },
  });
};

function AddIssueModal({ show, handleClose, submit }) {
  const [validated, setValidated] = useState(false);
  const { mutateAsync, isPending } = useAddIssue();

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    const data = {
      issue: event.target.issue.value,
      issue_description: event.target.issue_description.value || null,
    };
    if (data.issue) {
      await mutateAsync(data);
      handleClose();
    }
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add An Issue1
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isPending ? (
          <div className="d-flex h-100 w-100 justify-content-center align-items-center">
            <Spinner />
          </div>
        ) : (
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            id="add_issue_form"
          >
            <FloatingLabel
              controlId="floatingIssue"
              label="Issue"
              className="mb-4"
            >
              <Form.Control
                required
                name="issue"
                placeholder="Leave a comment here"
                minLength={1}
              />
              <Form.Control.Feedback type="invalid">
                Add an Issue
              </Form.Control.Feedback>
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingIssueDescription"
              label="Issue Description (Optional)"
            >
              <Form.Control
                as="textarea"
                name="issue_description"
                placeholder="Leave a comment here"
                style={{ height: "100px" }}
                minLength={1}
              />
            </FloatingLabel>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={isPending}
          type="submit"
          form="add_issue_form"
          variant="success"
          className="mx-2"
        >
          Add
        </Button>
        <Button
          disabled={isPending}
          className="btn btn-warning"
          type="button"
          onClick={() => handleClose(false)}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export const EngReview = () => {
  const { issues, uuid } = useCommonIssuesStore();
  const [selectedIssues, setSelectedIssues] = useState([]);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [required, setRequired] = useState(false);
  const [validated, setValidated] = useState(false);
  const [review, setReview] = useState("");
  const [oldIssues, setOldIssues] = useState([]);
  const { vendorName, uuid: x } = useAppStore();
  const filters = useReviewFilterParams();
  const [view, setView] = useState("current_user_issues");
  const { data, isLoading, isError } = useGetEngineeringIssues(uuid);

  const useSubmitReview = () => {
    return useMutation({
      mutationFn: (data) =>
        axiosInstance.post(
          "/engineering_issues/create_or_update_invoice_issue",
          {
            document_uuid: data.document_uuid,
            issue_uuid: null,
            tag: ["unknown"],
            issue: data.issue,
            status: "open",
          }
        ),
      onError: (error) => toast.error(error),
      onSuccess: () => {
        toast.success("Added for review");
        setReview("");
        queryClient.invalidateQueries("engineeringIssues");
      },
    });
  };
  const { mutate, isPending } = useSubmitReview();

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    let review = event.target.review.value ?? "";
    if (review.length < 1) review = null;
    const payload = {
      document_uuid: uuid,
      issue: review,
    };
    mutate(payload);
  };

  const handleIssueModal = (data) => {
    if (data) {
    }
  };

  const toggleMultiSelect = (option) => {
    if (selectedIssues.includes(option)) {
      setSelectedIssues(selectedIssues.filter((item) => item !== option));
    } else {
      setSelectedIssues([...selectedIssues, option]);
    }
  };

  return (
    <div
      className="gap-2 pb-4 w-100 my-4 mx-2 border border-gray rounded p-2"
      style={{ minHeight: "200px", overflow: "auto" }}
    >
      <div className="d-flex justify-content-end mx-2">
        <Dropdown>
          <Dropdown.Toggle
            variant="light"
            id="dropdown-basic"
            style={{
              textTransform: "capitalize",
            }}
          >
            {view.replace(/_/g, " ")}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {["current_user_issues", "other_user_issues"]?.map(
              (option, index) => (
                <Dropdown.Item
                  style={{
                    textTransform: "capitalize",
                  }}
                  key={index}
                  onClick={() => setView(option)}
                >
                  {option.replace(/_/g, " ")}
                </Dropdown.Item>
              )
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <EngIssuesNewTable
          data={data}
          view={view}
          setView={setView}
          desiredOrder={[
            "tag",
            "issue",
            "status",
            "username",
            "created_date",
            "last_modified_date",
          ]}
        />
        // <div
        //   className="p-2 my-2 border-white shadow-sm rounded-2"
        //   style={{
        //     overflow: "auto",
        //     maxHeight: "400px",
        //     width: "100%",
        //   }}
        // >
        //   <table className="table table-striped table-responsive">
        //     <thead>
        //       <tr>
        //         {data[view] && data[view].length > 0 &&
        //           Object.keys(data[view][0]).map(
        //             (key) =>
        //               key !== "issue_uuid" && (
        //                 <th
        //                   key={key}
        //                   style={{
        //                     textTransform: "capitalize",
        //                     verticalAlign: "middle",
        //                   }}
        //                 >
        //                   {key.replace(/_/g, " ")}
        //                 </th>
        //               )
        //           )}
        //       </tr>
        //     </thead>
        //     <tbody>
        //       {data[view] && data[view].length > 0 && data[view]?.map((row, index) => (
        //         <tr
        //           key={index}
        //           style={{
        //             textTransform: "capitalize",
        //           }}
        //         >
        //           {Object.keys(row).map(
        //             (key, index) =>
        //               key !== "issue_uuid" && (
        //                 <td key={index}>
        //                   {key === "created_date" ||
        //                   key === "last_modified_date"
        //                     ? formatDate(row[key])
        //                     : row[key]}
        //                 </td>
        //               )
        //           )}
        //         </tr>
        //       ))}
        //     </tbody>
        //   </table>
        // </div>
      )}
      <div className="d-flex flex-row">
        <Dropdown
          className="my-2"
          onToggle={() => setShow((s) => !s)}
          show={show}
        >
          <Dropdown.Toggle variant="warning" id="dropdown-issues">
            Select Issues
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ maxHeight: 200, overflowY: "auto" }}>
            {["unknown"]?.map((option, index) => (
              <Dropdown.Item
                key={index}
                onClick={() => toggleMultiSelect(option)}
                active={selectedIssues.includes(option)}
              >
                {option}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        {/* <Button variant="secondary mx-4" onClick={() => setShowModal(true)}>
              Add Issue
            </Button> */}
      </div>
      {selectedIssues.length > 0 && (
        <div className="d-flex flex-row flex-wrap gap-2 justify-content-center">
          <span className="fw-bold">Selected Issues:</span>
          {selectedIssues.map((issue, index) => (
            <span key={index} className="badge bg-warning text-black">
              {issue}
            </span>
          ))}
        </div>
      )}
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        id="reject_form"
        className="w-100"
      >
        <FloatingLabel
          controlId="floatingTextarea2"
          label="Review"
          style={{ height: "120px", width: "80%", minWidth: "200px" }}
          className="mx-auto"
        >
          <Form.Control
            as="textarea"
            name="review"
            defaultValue={""}
            className="h-100 w-100"
            placeholder="Leave a comment here"
          />
          <Form.Control.Feedback type="invalid">
            Please add a review
          </Form.Control.Feedback>
        </FloatingLabel>
        <Button
          disabled={!uuid}
          type="submit"
          variant="success"
          className="mt-2"
        >
          {isPending ? "Submitting..." : "Submit"}
        </Button>
      </Form>
      <AddIssueModal
        show={showModal}
        handleClose={() => {
          setShowModal(false);
        }}
      />
    </div>
  );
};
