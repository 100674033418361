import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { formatDate } from "utils/format";

export default function EngIssuesNewTable({
  data,
  view,
  scrollable = true,
  desiredOrder,
}) {
  const [newData, setNewData] = useState(data);
  // use the desired order to render the table
  useEffect(() => {
    if (data && data[view] && data[view].length > 0) {
      const sortedData = data[view].map((row) => {
        const newRow = {};
        desiredOrder.forEach((key) => {
          newRow[key] = row[key];
        });
        return newRow;
      });
      setNewData({ ...data, [view]: sortedData });
    }
  }, [data, view]);

  return (
    <>
      {!newData || !newData[view] || !newData[view]?.length  ? (
        <>
          <div className="p-2 my-2 border-white shadow-sm rounded-2">
            <p className="text-center text-muted">No data available</p>
          </div>
        </>
      ) : (
        <div
          className="p-2 my-2 border-white shadow-sm rounded-2"
          style={{
            overflow: "auto",
            height: scrollable ? "50vh" : "auto",
            width: "100%",
          }}
        >
          <table className="table table-striped table-responsive">
            <thead>
              <tr>
                {newData[view] &&
                  newData[view].length > 0 &&
                  Object.keys(newData[view][0]).map(
                    (key) =>
                      key !== "issue_uuid" && (
                        <th
                          key={key}
                          style={{
                            textTransform: "capitalize",
                            verticalAlign: "middle",
                          }}
                        >
                          {key.replace(/_/g, " ")}
                        </th>
                      )
                  )}
              </tr>
            </thead>
            <tbody>
              {newData[view] &&
                newData[view].length > 0 &&
                newData[view]?.map((row, index) => (
                  <tr
                    key={index}
                    style={{
                      textTransform: "capitalize",
                    }}
                  >
                    {Object.keys(row).map(
                      (key, index) =>
                        key !== "issue_uuid" && (
                          <td key={index}>
                            {key === "created_date" ||
                            key === "last_modified_date" ? (
                              formatDate(row[key])
                            ) : key === "document_uuid" ? (
                              <>
                                <Link
                                  to={`/invoice-details/${row["document_uuid"]}`}
                                  target="_blank"
                                >
                                  View
                                </Link>
                              </>
                            ) : (
                              row[key]
                            )}
                          </td>
                        )
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}
