import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";

export default function SideAccordion({ data }) {
  if (!data) return null;
  return (
    <div className="col-sm-4 p-2">
      <Accordion defaultActiveKey="0">
        {data?.map((item, index) => (
          <Accordion.Item eventKey={index}>
            <Accordion.Header>
              {item.category} - {item.count}
            </Accordion.Header>
            <Accordion.Body>
              Invoices {" "} <br></br>
              {item.invoices.map((invoice) => (
                <Link to={`/engineering-issues/invoice/${invoice}`}>
                <div className="btn btn-link">
                  {invoice}
                </div>
                </Link>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
}
