import React, { useState, useEffect } from "react";
import { Table, Form, Dropdown, FormControl } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useGlobalFilterActions } from "store/globalFIlter";
import { deleteVendor, disapproveAllItems } from "../api";
import { Modal } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { ListGroup } from "react-bootstrap";
import { Newspaper, PlusCircleFill, Trash3Fill } from "react-bootstrap-icons";
import useAppStore from "store/global";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "lib/react-query";
import { ToastContainer } from "react-toastify";
import Popout from "react-popout";
import {
  useGetInvoiceHeaderExceptionsForVendor,
  useUpdateInvoiceHeaderExceptionsForVendor,
} from "../api";
import { getAdditionalData } from "../api";
import CustomDropdown from "components/CustomDropdown";
const VendorDetailsTable = ({ data, handleSaveVendorDetails }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(data);
  const [newItem, setNewItem] = useState("");
  const { setVendorName } = useGlobalFilterActions();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showWindow, setShowWindow] = useState(false);
  const [categoryChoices, setCategoryChoices] = useState([]);
  const [windowUrl, setWindowUrl] = useState("");
  const {
    data: invoiceHeaderExceptions,
    isLoading,
    isError,
  } = useGetInvoiceHeaderExceptionsForVendor(data.vendor_id);
  const { mutate: updateExceptions, isPending: mutatingExceptions } =
    useUpdateInvoiceHeaderExceptionsForVendor();
  const handleViewInvoice = (vendorName) => {
    setVendorName(vendorName);
    const filters = {
      pdf_index: 1,
      page_index: 1,
      invoice_size: 8,
      filters: {
        human_verified: "both",
        human_verification: "both",
        invoice_detection_status: "all",
        rerun_status: "both",
        invoice_type: "all",
        start_date: null,
        end_date: null,
        vendor_name: vendorName,
        restaurant_id: null,
      },
    };
    navigate(`/details?filters=${JSON.stringify(filters)}`);
  };

  useEffect(() => {
    setFormData(data);
    getAdditionalData()
      .then((res) => {
        setCategoryChoices(res.processed_table_header_candidates);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [data]);

  const handleInputChange = (e, key) => {
    setFormData({
      ...formData,
      [key]: e.target.value,
    });
  };

  const handleDropdownChange = (value, key) => {
    if (key === "vendor_name_synonyms") {
      return;
    } else if (key === "vendor_gl_code") {
      setFormData({
        ...formData,
        [key]: JSON.parse(value),
      });
    } else {
      setFormData({
        ...formData,
        [key]: value,
      });
    }
  };

  const addItem = (key) => {
    if (key === "vendor_name_synonyms") {
      let oldSynonyms = formData.vendor_name_synonyms;
      if (!oldSynonyms.includes(newItem)) {
        oldSynonyms.push(newItem);
      }
      setFormData({
        ...formData,
        vendor_name_synonyms: oldSynonyms,
      });
      setNewItem("");
      toast.success("Synonym added successfully");
    } else if (key === "vendor_payment_terms") {
      const allPaymentTerms = formData.metadata.all_payment_terms;
      if (!allPaymentTerms.includes(newItem)) {
        allPaymentTerms.push(newItem);
      }
      setFormData({
        ...formData,
        metadata: {
          ...formData.metadata,
          all_payment_terms: allPaymentTerms,
        },
      });
      setNewItem("");
      toast.success("Payment term added successfully");
    } else if (key === "vendor_category") {
      const allCategories = formData.metadata.all_categories;
      if (!allCategories.includes(newItem)) {
        allCategories.push(newItem);
      }
      setFormData({
        ...formData,
        metadata: {
          ...formData.metadata,
          all_categories: allCategories,
        },
      });
      setNewItem("");
      toast.success("Category added successfully");
    }
  };

  const [filteredGlCodes, setFilteredGlcodes] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handleDelete = (vendorId) => {
    setDeleting(true);
    deleteVendor(vendorId)
      .then((res) => {
        toast.success(res.message);
        setDeleting(false);
        navigate("/vendor-consolidation");
      })
      .catch((err) => {
        setDeleting(false);
        toast.error("Error deleting vendor");
      });
  };

  useEffect(() => {
    if (!formData) return;
    const lowercaseSearchQuery = searchQuery.toLowerCase();
    const filtered = formData?.metadata?.all_gl_codes.filter((gl_code) =>
      gl_code?.name
        ?.toLowerCase()
        ?.trim()
        ?.includes(lowercaseSearchQuery.trim())
    );
    setFilteredGlcodes((prev) => filtered);
  }, [searchQuery, data]);
  const synonymDeleteHandler = (index) => {
    const newSynonyms = formData.vendor_name_synonyms.filter(
      (sy, i) => i !== index
    );

    setFormData({ ...formData, vendor_name_synonyms: newSynonyms });

    console.log("formData", formData);
  };

  const { mutate, isPending } = useMutation({
    mutationFn: disapproveAllItems,
    onSuccess: (data) => {
      toast.success(data.message);
    },
    onError: (data) => {
      toast.error(data?.response?.data.message || "Error disapproving items");
    },
  });

  const handleDisapproveAllItems = (vendorId) => {
    mutate(vendorId);
  };

  const handleButtonClick = (vendorName) => {
      const filters = {
        pdf_index: 1,
        page_index: 1,
        invoice_size: 8,
        filters: {
          human_verified: "both",
          human_verification: "both",
          invoice_detection_status: "all",
          rerun_status: "both",
          invoice_type: "all",
          start_date: null,
          end_date: null,
          vendor_name: vendorName,
          restaurant_id: null,
        },
      };
      const filtersString = JSON.stringify(filters);
      const encodedFilters = encodeURIComponent(filtersString);
      const baseUrl = window.location.origin;
      const route = `/details?filters=${encodedFilters}`;
      setWindowUrl(`${baseUrl}${route}`);
      setShowWindow(true);
      window.open(route, "_blank");
  };

  const [newRawHeader, setNewRawHeader] = useState("");
  console.log("invoiceHeaderExceptions", invoiceHeaderExceptions);
  return (
    <div
      className="mx-5"
      style={{
        marginTop: "50px",
      }}
    >
      <ToastContainer />
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Field</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(formData).map(
            ([key, value]) =>
              !key.startsWith("metadata") &&
              key !== "error" &&
              key !== "required_invoice_columns_for_item_master" &&
              key != "invoice_header_names_mapping" && (
                <tr
                  key={key}
                  style={{
                    textTransform: "capitalize",
                  }}
                >
                  <td>
                    {key !== "vendor_gl_code"
                      ? key.replace(/_/g, " ")
                      : "Account Category"}
                  </td>
                  <td>
                    {key === "vendor_name_synonyms" ||
                    key === "vendor_payment_terms" ||
                    key === "vendor_gl_code" ||
                    key === "item_master_uniqueness_level" ||
                    key === "vendor_document_type" ||
                    key === "vendor_category" ? (
                      <Dropdown
                        onSelect={(value) => handleDropdownChange(value, key)}
                      >
                        <Dropdown.Toggle variant="success">
                          {Array.isArray(formData[key])
                            ? formData[key].length > 0
                              ? formData[key][0]
                              : "Select"
                            : formData[key]
                            ? typeof formData[key] === "object"
                              ? formData[key]?.name
                                ? formData[key].name
                                : "None"
                              : formData[key]
                            : "None"}
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          style={{
                            maxHeight: "200px",
                            overflowY: "scroll",
                          }}
                        >
                          <FormControl
                            type="text"
                            className="form-control mx-auto mb-4"
                            placeholder="Search"
                            onInput={(e) => {
                              setSearchQuery(e.target.value);
                            }}
                            style={{
                              width: "90%",
                            }}
                          />
                          {key === "vendor_payment_terms" ? (
                            formData.metadata.all_payment_terms.map(
                              (term, index) => (
                                <Dropdown.Item key={index} eventKey={term}>
                                  {term}
                                </Dropdown.Item>
                              )
                            )
                          ) : key === "vendor_category" ? (
                            formData.metadata.all_categories.map(
                              (category, index) => (
                                <Dropdown.Item key={index} eventKey={category}>
                                  {category === null ? "Null" : category}
                                </Dropdown.Item>
                              )
                            )
                          ) : key === "vendor_gl_code" ? (
                            <>
                              {filteredGlCodes.map((glCode, index) => (
                                <Dropdown.Item
                                  key={glCode.id}
                                  eventKey={JSON.stringify(glCode)}
                                >
                                  {glCode.name === null ? "None" : glCode.name}
                                </Dropdown.Item>
                              ))}
                              <Dropdown.Item
                                key={null}
                                eventKey={JSON.stringify({
                                  id: null,
                                  name: null,
                                })}
                              >
                                None
                              </Dropdown.Item>
                            </>
                          ) : key === "item_master_uniqueness_level" ? (
                            <>
                              {formData?.metadata?.all_item_master_uniqueness_levels.map(
                                (item, index) => (
                                  <Dropdown.Item key={item} eventKey={item}>
                                    {item}
                                  </Dropdown.Item>
                                )
                              )}
                            </>
                          ) : key === "vendor_document_type" ? (
                            <>
                              {formData?.metadata?.all_vendor_document_types.map(
                                (item, index) => (
                                  <Dropdown.Item key={item} eventKey={item}>
                                    {item}
                                  </Dropdown.Item>
                                )
                              )}
                              <Dropdown.Item
                                key={null}
                                eventKey={null}
                              >
                                None
                              </Dropdown.Item>
                            </>
                          ) : (
                            formData.vendor_name_synonyms.map(
                              (synonym, index) => (
                                <Dropdown.Item key={index} eventKey={synonym}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      margin: "0",
                                      padding: "5px 0",
                                    }}
                                  >
                                    <p style={{ margin: "0" }}>{synonym}</p>
                                    <p
                                      onClick={() => {
                                        synonymDeleteHandler(index);
                                      }}
                                      style={{
                                        margin: "0",
                                        marginLeft: "30px",
                                        border: "1px solid gray",
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                        borderRadius: 5,
                                        backgroundColor: "#E5E4E2",
                                      }}
                                    >
                                      Remove
                                    </p>
                                  </div>
                                </Dropdown.Item>
                              )
                            )
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : key === "human_verified" ||
                      key === "auto_approve_invoices" ? (
                      <Form.Switch
                        id="toggleButton"
                        checked={value}
                        onChange={(e) =>
                          handleDropdownChange(e.target.checked, key)
                        }
                      />
                    ) : (
                      <Form.Control
                        type="text"
                        style={{
                          borderColor:
                            key === "verified_branches_count" ||
                            key === "verified_items_count"
                              ? "green"
                              : "",
                        }}
                        disabled={
                          key === "document_count" ||
                          key === "vendor_branches_count" ||
                          key === "vendor_id" ||
                          key === "verified_branches_count" ||
                          key === "total_items" ||
                          key === "verified_items_count"
                        }
                        value={value}
                        onChange={(e) => handleInputChange(e, key)}
                        hidden={
                          key === "required_invoice_columns_for_item_master" ||
                          key === "invoice_header_names_mapping"
                        }
                      />
                    )}
                  </td>
                </tr>
              )
          )}
        </tbody>
      </Table>
      <Accordion className="my-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Vendor Invoice Columns Data</Accordion.Header>
          <Accordion.Body>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <div className="d-flex justify-content-center border-white rounded shadow-sm p-3">
                  <div className="col-md-12">
                    <div className="text-center">All Invoice Columns</div>
                    <hr className="featurette-divider" />

                    <div className="d-flex justify-content-center">
                      {Array.from({ length: 4 }, (_, columnIndex) => (
                        <div key={columnIndex} className="col-md-3">
                          {formData?.metadata?.all_invoice_column_names
                            .filter((_, index) => index % 4 === columnIndex)
                            .map((column, index) => (
                              <div
                                key={index}
                                className="d-flex mx-5 border-bottom py-2"
                                style={{
                                  textTransform: "capitalize",
                                  textAlign: "left",
                                }}
                              >
                                <input
                                  className="form-check-input mx-2"
                                  type="checkbox"
                                  checked={formData.required_invoice_columns_for_item_master.includes(
                                    column
                                  )}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      const updatedColumns = [
                                        ...formData.required_invoice_columns_for_item_master,
                                        column,
                                      ];
                                      setFormData({
                                        ...formData,
                                        required_invoice_columns_for_item_master:
                                          updatedColumns,
                                      });
                                    } else {
                                      const updatedColumns =
                                        formData.required_invoice_columns_for_item_master.filter(
                                          (col) => col !== column
                                        );
                                      setFormData({
                                        ...formData,
                                        required_invoice_columns_for_item_master:
                                          updatedColumns,
                                      });
                                    }
                                  }}
                                />
                                {column}
                              </div>
                            ))}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </ListGroup.Item>
            </ListGroup>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Accordion className="my-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Invoice Header Mapping</Accordion.Header>
          <Accordion.Body>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <div className="border-white rounded shadow-sm p-3">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Header Display Name</th>
                        <th>Actual Header Name</th>
                        <th>Actual Header Position</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {formData &&
                        Array.isArray(formData?.invoice_header_names_mapping) &&
                        formData?.invoice_header_names_mapping?.map(
                          (header, index) => (
                            <tr key={index}>
                              <td>
                                <Dropdown
                                  variant="light"
                                  onSelect={(value) => {
                                    const updatedHeaders = [
                                      ...formData.invoice_header_names_mapping,
                                    ];
                                    updatedHeaders[index].name = value;
                                    setFormData({
                                      ...formData,
                                      invoice_header_names_mapping:
                                        updatedHeaders,
                                    });
                                  }}
                                >
                                  <Dropdown.Toggle
                                    variant="light"
                                    style={{
                                      textTransform: "capitalize",
                                      width: "100%",
                                      textAlign: "left",
                                    }}
                                  >
                                    {header?.name !== ""
                                      ? header.name
                                      : "Select"}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {formData?.metadata?.all_invoice_column_names?.map(
                                      (column, index) => (
                                        <Dropdown.Item
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                          key={index}
                                          eventKey={column}
                                        >
                                          {column}
                                        </Dropdown.Item>
                                      )
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={header.actual_header_name}
                                  onChange={(e) => {
                                    const updatedHeaders = [
                                      ...formData.invoice_header_names_mapping,
                                    ];
                                    updatedHeaders[index].actual_header_name =
                                      e.target.value;
                                    setFormData({
                                      ...formData,
                                      invoice_header_names_mapping:
                                        updatedHeaders,
                                    });
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  className="form-control"
                                  min={1}
                                  type="number"
                                  value={header.actual_header_position}
                                  onChange={(e) => {
                                    const updatedHeaders = [
                                      ...formData.invoice_header_names_mapping,
                                    ];
                                    updatedHeaders[
                                      index
                                    ].actual_header_position = parseInt(
                                      e.target.value
                                    );
                                    setFormData({
                                      ...formData,
                                      invoice_header_names_mapping:
                                        updatedHeaders,
                                    });
                                  }}
                                />
                              </td>
                              <td>
                                <button
                                  className="btn btn-sm btn-danger"
                                  onClick={() => {
                                    const updatedHeaders = [
                                      ...formData.invoice_header_names_mapping,
                                    ];
                                    updatedHeaders.splice(index, 1);
                                    setFormData({
                                      ...formData,
                                      invoice_header_names_mapping:
                                        updatedHeaders,
                                    });
                                    toast.warning("Header deleted");
                                  }}
                                >
                                  <Trash3Fill />
                                </button>
                              </td>
                            </tr>
                          )
                        )}
                    </tbody>
                  </table>

                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      className="btn btn-sm btn-info w-75"
                      onClick={() => {
                        let updatedHeaders = [
                          ...formData.invoice_header_names_mapping,
                        ];
                        updatedHeaders.push({
                          name: "",
                          actual_header_name: "",
                          actual_header_position: "",
                        });
                        setFormData({
                          ...formData,
                          invoice_header_names_mapping: updatedHeaders,
                        });
                        toast.info("New header added");
                      }}
                    >
                      <PlusCircleFill className="mx-2 mb-1" fill="white" />
                      Add New Header
                    </button>
                  </div>
                </div>
              </ListGroup.Item>
            </ListGroup>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Accordion className="my-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            Invoice Header Mapping - Exceptions
          </Accordion.Header>
          <Accordion.Body>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <div className="border-white rounded shadow-sm p-3">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Header Display Name</th>
                        <th>Header Raw Name</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isLoading &&
                        invoiceHeaderExceptions &&
                        Array.isArray(
                          invoiceHeaderExceptions?.invoice_header_exceptions
                        ) &&
                        invoiceHeaderExceptions?.invoice_header_exceptions?.map(
                          (header, index) => (
                            <tr key={index}>
                              <td>
                                <CustomDropdown
                                  data={categoryChoices?.sort()}
                                  Objectkey={""}
                                  displayKey={""}
                                  className="w-auto"
                                  maxWidth="350px"
                                  minWidth="350px"
                                  value={
                                    header?.header_display_name ||
                                    "Select a header name"
                                  }
                                  showVerificationBadge={true}
                                  verification_key={"human_verified"}
                                  onSelect={(value) => {
                                    const updatedHeaders =
                                      invoiceHeaderExceptions.invoice_header_exceptions.map(
                                        (header, i) =>
                                          i === index
                                            ? {
                                                ...header,
                                                header_display_name: value,
                                              }
                                            : header
                                      );

                                    queryClient.setQueryData(
                                      [
                                        "invoiceHeaderExceptions",
                                        data?.vendor_id,
                                      ],
                                      {
                                        ...invoiceHeaderExceptions,
                                        invoice_header_exceptions:
                                          updatedHeaders,
                                      }
                                    );
                                  }}
                                />
                              </td>
                              <td>
                                <Dropdown
                                  variant="light"
                                  onSelect={(value) => {
                                    const updatedHeaders =
                                      invoiceHeaderExceptions.invoice_header_exceptions.map(
                                        (header, i) => {
                                          if (i === index) {
                                            // Create a new object for the specific header
                                            const newHeaderRawNames =
                                              header.header_raw_names.includes(
                                                value
                                              )
                                                ? header.header_raw_names.filter(
                                                    (headerName) =>
                                                      headerName !== value
                                                  )
                                                : [
                                                    ...header.header_raw_names,
                                                    value,
                                                  ];

                                            return {
                                              ...header,
                                              header_raw_names:
                                                newHeaderRawNames,
                                            };
                                          }
                                          return header;
                                        }
                                      );

                                    queryClient.setQueryData(
                                      [
                                        "invoiceHeaderExceptions",
                                        data?.vendor_id,
                                      ],
                                      {
                                        ...invoiceHeaderExceptions,
                                        invoice_header_exceptions:
                                          updatedHeaders,
                                      }
                                    );
                                  }}
                                >
                                  <Dropdown.Toggle
                                    variant="light"
                                    style={{
                                      textTransform: "capitalize",
                                      width: "100%",
                                      textAlign: "left",
                                    }}
                                  >
                                    {header?.header_raw_names?.length > 0
                                      ? header?.header_raw_names.join(",")
                                      : "Select"}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <div className="d-flex justify-content-between mx-2">
                                      <input
                                        className="form-control mx-2"
                                        type="text"
                                        value={newRawHeader}
                                        onChange={(e) => {
                                          setNewRawHeader(e.target.value);
                                        }}
                                      />
                                      <button
                                        className="btn btn-sm btn-primary"
                                        onClick={() => {
                                          const updatedHeaders = [
                                            ...invoiceHeaderExceptions.invoice_header_exceptions,
                                          ];
                                          if (
                                            updatedHeaders[
                                              index
                                            ].header_raw_names?.includes(
                                              newRawHeader
                                            )
                                          ) {
                                            toast.warning(
                                              "Header already exists"
                                            );
                                          } else if (
                                            updatedHeaders[index]
                                              .header_raw_names?.length === 0 ||
                                            !updatedHeaders[index]
                                              .header_raw_names
                                          ) {
                                            updatedHeaders[
                                              index
                                            ].header_raw_names = [newRawHeader];
                                          } else {
                                            updatedHeaders[
                                              index
                                            ].header_raw_names?.push(
                                              newRawHeader
                                            );
                                          }

                                          queryClient.setQueryData(
                                            [
                                              "invoiceHeaderExceptions",
                                              data?.vendor_id,
                                            ],
                                            {
                                              ...invoiceHeaderExceptions,
                                              invoice_header_exceptions:
                                                updatedHeaders,
                                            }
                                          );
                                          setNewRawHeader("");
                                        }}
                                      >
                                        Add
                                      </button>
                                    </div>
                                    {invoiceHeaderExceptions
                                      ?.invoice_header_exceptions[index] &&
                                      invoiceHeaderExceptions?.invoice_header_exceptions[
                                        index
                                      ]?.header_raw_names?.map(
                                        (header, index) => (
                                          <Dropdown.Item
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                            key={index}
                                            eventKey={header}
                                          >
                                            {header}
                                          </Dropdown.Item>
                                        )
                                      )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>
                                <button
                                  className="btn btn-sm btn-danger"
                                  onClick={() => {
                                    const updatedHeaders = [
                                      ...invoiceHeaderExceptions.invoice_header_exceptions,
                                    ];
                                    updatedHeaders.splice(index, 1);
                                    queryClient.setQueryData(
                                      [
                                        "invoiceHeaderExceptions",
                                        data?.vendor_id,
                                      ],
                                      {
                                        ...invoiceHeaderExceptions,
                                        invoice_header_exceptions:
                                          updatedHeaders,
                                      }
                                    );
                                    toast.warning("Header deleted");
                                  }}
                                >
                                  <Trash3Fill />
                                </button>
                              </td>
                            </tr>
                          )
                        )}
                    </tbody>
                  </table>

                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      className="btn btn-sm btn-info w-50 mx-2"
                      onClick={() => {
                        let updatedHeaders = [
                          ...invoiceHeaderExceptions.invoice_header_exceptions,
                        ];
                        updatedHeaders.push({
                          name: "",
                          raw_header_names: [],
                        });
                        queryClient.setQueryData(
                          ["invoiceHeaderExceptions", data?.vendor_id],
                          {
                            ...invoiceHeaderExceptions,
                            invoice_header_exceptions: updatedHeaders,
                          }
                        );
                        toast.info("New header added");
                      }}
                    >
                      <PlusCircleFill className="mx-2 mb-1" fill="white" />
                      Add New Header
                    </button>
                    <button
                      className="btn btn-sm btn-primary w-50 mx-2"
                      disabled={mutatingExceptions}
                      onClick={() => {
                        const payload = {
                          vendor_id: data.vendor_id,
                          invoice_header_exceptions:
                            invoiceHeaderExceptions.invoice_header_exceptions,
                        };
                        updateExceptions(payload);
                      }}
                    >
                      <PlusCircleFill className="mx-2 mb-1" fill="white" />
                      {mutatingExceptions ? "Updating..." : "Update"}
                    </button>
                  </div>
                </div>
              </ListGroup.Item>
            </ListGroup>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="mt-4 mx-5 mb-3 d-flex flex-wrap justify-content-between">
        <div className="mb-3" style={{ width: "48%" }}>
          <button
            className="btn btn-success"
            style={{ width: "100%" }}
            onClick={() => handleSaveVendorDetails(formData)}
          >
            Save
          </button>
        </div>

        <div className="mb-3" style={{ width: "48%" }}>
          <button
            className="btn btn-danger"
            style={{ width: "100%" }}
            onClick={() => setShowDeleteModal(true)}
            disabled={deleting}
          >
            {deleting ? "Deleting..." : "Delete"}
          </button>
        </div>

        <div className="mb-3" style={{ width: "48%" }}>
          <button
            className="btn btn-primary"
            style={{ width: "100%" }}
            onClick={() => {
              navigate(
                `/vendor-consolidation/combine-vendors/${data.vendor_id}`
              );
            }}
          >
            Find Similar Vendors
          </button>
        </div>

        <div className="mb-3" style={{ width: "48%" }}>
          <button
            className="btn btn-warning"
            style={{ width: "100%" }}
            onClick={() => {
              handleButtonClick(data.vendor_name);
              // handleViewInvoice(data.vendor_name);
            }}
          >
            View Invoices
          </button>
        </div>

        <div className="mb-3" style={{ width: "48%" }}>
          <button
            className="btn btn-info"
            style={{ width: "100%" }}
            onClick={() => {
              navigate(`/vendor-consolidation/branches/${data.vendor_id}`);
            }}
          >
            View Branches
          </button>
        </div>

        {data?.vendor_category !== "Summary Invoice" && (
          <>
            <div className="mb-3" style={{ width: "48%" }}>
              <button
                className="btn btn-dark"
                style={{ width: "100%" }}
                onClick={() => {
                  navigate(
                    `/vendor-consolidation/vendor-item-master/${data.vendor_id}`
                  );
                }}
              >
                View Items
              </button>
            </div>

            <div className="mb-3" style={{ width: "48%" }}>
              <button
                className="btn btn-dark"
                style={{ width: "100%" }}
                onClick={() => {
                  navigate(
                    `/fast-item-master-verification/${data.vendor_id}?vendor_name=${data.vendor_name}`
                  );
                }}
              >
                Fast Item Verification
              </button>
            </div>
          </>
        )}
        <div className="mb-3" style={{ width: "48%" }}>
          <button
            className="btn btn-danger"
            style={{ width: "100%" }}
            onClick={() => {
              handleDisapproveAllItems(data.vendor_id);
            }}
            disabled={isPending}
          >
            {isPending ? "Disapproving ..." : "Disapprove All Items"}
          </button>
        </div>
      </div>
      <Modal
        centered
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Vendor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this vendor?
          <table className="table my-2 bordered responsive">
            <tbody>
              <tr>
                <td>Vendor Name</td>
                <td>{formData.vendor_name}</td>
              </tr>
              <tr>
                <td>Vendor ID</td>
                <td>{formData.vendor_id}</td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={() => handleDelete(formData.vendor_id)}
            disabled={deleting}
          >
            {deleting ? "Deleting..." : "Delete"}
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      {showWindow && (
        <Popout
          url={windowUrl}
          title="Vendor Invoices"
          onClosing={() => {
            setShowWindow(false);
            setWindowUrl("");
          }}
        ></Popout>
      )}
    </div>
  );
};

export default VendorDetailsTable;
