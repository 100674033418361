import React, { useState } from "react";
import { Table, Dropdown, Collapse } from "react-bootstrap";
import ResizableCell from "components/Utility/ResizableCell";
import { formatDate } from "utils/format";
import { changeStatus, addCategory } from "./api";
import { toast } from "react-toastify";
import useReviewFilterStore from "./store";
import { addCommonCategory } from "./api";
import { Link } from "react-router-dom";
export default function ListTable({ data, items }) {
  const [expandedRow, setExpandedRow] = useState(null);
  const [expandedRow2, setExpandedRow2] = useState(null);
  const [newCategory, setNewCategory] = useState("");
  const {
    changed,
    uuid,
    actions: { setChanged, setUUID },
  } = useReviewFilterStore();
  
  const handleExpandRow = (invoiceNum) => {
    if (expandedRow === invoiceNum) {
      setExpandedRow(null);
    } else {
      setExpandedRow(invoiceNum);
    }
  };

  const handleExpandRow2 = (invoiceNum) => {
    if (expandedRow2 === invoiceNum) {
      setExpandedRow2(null);
    } else {
      setExpandedRow2(invoiceNum);
    }
  };
  const getHeaders = () => {
    if (data.length === 0) {
      return [];
    }
    const headers = Object.keys(data[0]);
    const filteredHeaders = headers.filter(
      (header) => header !== "_id" && header !== "engineering_team_required"
    );

    return filteredHeaders;
  };

  const headers = getHeaders();
  const handleStatusChange = (invoice_uuid, selectedStatus) => {
    changeStatus(invoice_uuid, selectedStatus)
      .then((res) => {
        toast.success(res.message);
        setChanged(!changed);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleAddNewCategory = () => {
    addCommonCategory(newCategory)
      .then((res) => {
        toast.success(res.message);
        setChanged(!changed);
        setNewCategory("");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleAddCategory = (invoice_uuid, eventKey) => {
    addCategory(invoice_uuid, eventKey)
      .then((res) => {
        toast.success(res.message);
        setChanged(!changed);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  return (
    <div className="col-sm-8 rounded-4 p-2">
      <Table responsive striped bordered hover>
        <thead style={{ position: 'sticky', top: 0, zIndex: 1 }}>
          <tr>
            {headers.map((header) => (
              <th key={header} style={{ textTransform: "capitalize" }}>
                <ResizableCell width={130}>
                  <div
                    style={{
                      lineHeight: "1.5",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "140px",
                    }}
                  >
                    {header}
                  </div>
                </ResizableCell>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <React.Fragment key={item.invoice_uuid}>
              <tr
              style={{
                cursor: "pointer",
              }}
              >
                {headers.map((header) => (
                  <td key={header}>
                    {header === "status" ? (
                      <Dropdown
                        onSelect={(eventKey) =>
                          handleStatusChange(item.invoice_uuid, eventKey)
                        }
                      >
                        <Dropdown.Toggle variant="warning" id="dropdown-basic">
                          {item[header]}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item eventKey="open">Open</Dropdown.Item>
                          <Dropdown.Item eventKey="closed">
                            Closed
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="pending">
                            Pending
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (header === "category") ||
                      (header === "issues") ? (
                      <span>
                        {Array.isArray(item[header]) ? (
                        item[header]?.map((category, index) => (
                          <span key={index} className="badge bg-primary me-1">
                            {category}
                          </span>
                        ))
                        ) : (
                          <span className="badge bg-primary me-1">
                            {item[header]}
                          </span>
                        )
                      }

                        {header === "category" && (
                          <>
                            <Dropdown
                              className="my-2"
                              onSelect={(eventKey) =>
                                handleAddCategory(item.invoice_uuid, eventKey)
                              }
                            >
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                              >
                                Add
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <div eventKey="new" className="d-flex mx-1">
                                  <input
                                    className="form-control"
                                    type="text"
                                    onChange={(e) =>
                                      setNewCategory(e.target.value)
                                    }
                                    value={newCategory}
                                    placeholder="Add manually"
                                  />
                                  <button
                                    className="btn btn-sm btn-primary"
                                    onClick={handleAddNewCategory}
                                  >
                                    Add
                                  </button>
                                </div>
                                {items?.map((filter, i) => (
                                  <Dropdown.Item key={filter} eventKey={filter}>
                                    {filter}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </>
                        )}
                      </span>
                    ) : header === "review" ? (
                      <span>
                        {item[header]?.length > 50 &&
                        expandedRow2 !== item.invoice_uuid ? (
                          <span>
                            {item[header].slice(0, 50)}...
                            <button
                              onClick={() =>
                                handleExpandRow2(item.invoice_uuid)
                              }
                              className="btn btn-link ms-2"
                            >
                              Show More
                            </button>
                          </span>
                        ) : (
                          <span>{item[header]}</span>
                        )}
                      </span>
                    ) : header === "timestamp" ||
                      header === "last_modified_timestamp" ? (
                      formatDate(item[header])
                    ) : (
                      <Link to={`/engineering-issues/invoice/${item.invoice_uuid}`}>
                      {item[header]}  
                      </Link>
                    )}
                  </td>
                ))}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
