import React, { useEffect, useState } from "react";
import { NavHeader } from "components/layout/NavHeader";
import Header from "modules/vendorConsolidation/components/Header";
import { Spinner } from "components/common/Spinner";
import {
  useFetchAllUsers,
  useFetchUserActivity,
  useFetchUserInvoiceIssues,
  useFetchUserItemMasterIssues,
} from "./api";
import { useSearchParams } from "react-router-dom";
import Dashboard from "./InternalStruct";
import { DateRangePicker } from "react-date-range";
import { Dropdown } from "react-bootstrap";
import { axiosInstance } from "lib/axios";
import { toast, ToastContainer } from "react-toastify";
import useUpdateSearchParams from "hooks/useUpdateSearchParams";
export default function Activity() {
  const updateSearchParams = useUpdateSearchParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [state, setState] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const userUUID = searchParams.get("user_id");
  let pageIndex = searchParams.get("page_index") ?? 1;
  pageIndex = parseInt(pageIndex);
  const pageSize = searchParams.get("page_size") ?? 10;
  const [convertingToExcel, setConvertingToExcel] = useState(false);
  const { data, isLoading, isError } = useFetchUserActivity(userUUID, state);
  const {
    data: usersData,
    isLoading: userDataLoading,
    isError: usersIsError,
  } = useFetchAllUsers();
  const {
    data: userInvoiceIssues,
    isLoading: userInvoiceIssuesLoading,
    isError: userInvoiceIssuesError,
  } = useFetchUserInvoiceIssues(pageIndex, pageSize, userUUID);

  const {
    data: itemsData,
    isLoading: itemsLoading,
    error: itemsError,
  } = useFetchUserItemMasterIssues(pageIndex, pageSize, userUUID);

  const handleDownloadAsExcel = async () => {
    try {
      setConvertingToExcel(true);
      const body = {
        all_users: userUUID === "all" ? true : false,
        page_index: null,
        page_size: null,
      };
      const response = await axiosInstance.post(
        `/user_activity/get_user_invoice_issues`,
        body
      );
      let issues = response.data.issues;
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += `Invoice,Issue,Last Modified,Status,Tag,Username,Vendor Name\n`;
      issues.forEach((issue) => {
        let documentUUID = `${window.location.origin}/invoice-details/${issue.document_uuid}`;
        csvContent += `${documentUUID},${issue.issue},${
          issue.last_modified_date
        },${issue.status},${issue.tag.join("/")},${issue.username},${
          issue.vendor_name
        }\n`;
      });
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "user_invoice_issues.csv");
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(link.href);
      setConvertingToExcel(false);
    } catch (error) {
      toast.error("Something went wrong");
      setConvertingToExcel(false);
    }
  };

  const updateSearchParam = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  return (
    <>
      <NavHeader view="User Activity" />
      <ToastContainer />
      <Header
        text={`User Activity - ${data?.user?.username ? data?.user?.username : ""} `}
        children={
          <div className="d-flex justify-content-end">
            <Dropdown
              show={isDropdownOpen}
              onToggle={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                Filter
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {/* <div className="w-100">
                  <Dropdown className="w-100">
                    <Dropdown.Toggle
                      variant="light"
                      id="dropdown-basic"
                      className="w-100 text-start "
                    >
                      {isLoading
                        ? "Loading..."
                        : usersData?.users?.find(
                            (user) => user.user_uuid === userUUID
                          )?.username || "All"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="w-100">
                      <Dropdown.Item
                        className="cursor-pointer"
                        onClick={() => {
                          updateSearchParam("user_id", "all");
                          setIsDropdownOpen(false);
                        }}
                      >
                        All
                      </Dropdown.Item>
                      {usersIsError && <div>Something went wrong</div>}
                      {userDataLoading
                        ? "Loading..."
                        : usersData?.users?.map((user) => (
                            <Dropdown.Item
                              className="cursor-pointer"
                              key={user.user_uuid}
                              onClick={() => {
                                updateSearchParam("user_id", user.user_uuid);
                                setIsDropdownOpen(false);
                              }}
                            >
                              <td>{user.username}</td>
                            </Dropdown.Item>
                          ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div> */}

                <DateRangePicker
                  onChange={(item) => {
                    setState([item.selection])
                    updateSearchParams({user_activity_filters:{start_date: item.selection.startDate?.toLocaleDateString('en-CA'), end_date: item.selection.endDate?.toLocaleDateString('en-CA')}})
                  }}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={state}
                  direction="horizontal"
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        }
      />
      {isLoading && <Spinner />}
      {isError && <div>Something went wrong</div>}
      {data && (
        <Dashboard
          data={data}
          issuesData={userInvoiceIssues}
          userInvoiceIssuesLoading={userInvoiceIssuesLoading}
          userInvoiceIssuesError={userInvoiceIssuesError}
          handleDownloadAsExcel={handleDownloadAsExcel}
          convertingToExcel={convertingToExcel}
          itemsIssuesData={itemsData}
          itemsIssuesLoading={itemsLoading}
          itemsIssuesError={itemsError}
          userUUID={userUUID}
          state={state}
        />
      )}
    </>
  );
}
