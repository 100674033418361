import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import useAppStore from "store/global";
import { useInvoicePaginationActions } from "store/invoicePagination";
import { ArrowLeft, HouseFill } from "react-bootstrap-icons";
import React, { useState, useEffect } from "react";
import { useVerificationParams } from "store/global";
import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "lib/axios";
import { ButtonGroup, CloseButton, Dropdown, Spinner } from "react-bootstrap";
import { useGlobalFilterParams } from "store/globalFIlter";
import { FilterDropdown } from "./FilterDropdown";
import { VendorDropdown } from "./VendorDropdown";
import { RestaurantDropdown } from "./RestaurantDropdown";
import useNavigationHistory from "hooks/useNavigationHistory";
const useSearchQuery = () => {
  const filters = useGlobalFilterParams();
  const invoice_name = useAppStore((state) => state.searchInput);
  const payload = {
    invoice_number_search: invoice_name,
    filters,
  };
  return useQuery({
    enabled: Boolean(invoice_name),
    queryKey: ["invoice_search", invoice_name, ...Object.values(filters)],
    queryFn: () => axiosInstance.post("/invoice_search", payload),
  });
};

export const NavHeader = ({ view = "home" }) => {
  const { setPageNumber, setTempValue } = useInvoicePaginationActions();

  const filters = useGlobalFilterParams();
  const {
    searchInput,
    setSearchInput,
    searchResultVisible,
    setSearchResutsVisible,
    getVerified,
    showVertical,
    setShowVertical,
  } = useAppStore();
  const { page } = useParams();

  const navigate = useNavigate();
  const { data, isPending, isError, error } = useSearchQuery();
  const invoiceNumArray = data?.data?.matching_invoices;

  const [search, setSearch] = useState(searchInput ?? "");

  const handleSearchInputChange = (event) => {
    const inputValue = event.target.value;
    setSearch(inputValue);
  };

  return (
    <nav
      className="navbar p-3 shadow-sm"
      style={{
        backgroundColor: "#FDFFD0",
        position: "fixed",
        top: "0",
        bottom: "100",
        zIndex: "100",
        width: "100%",
      }}
    >
      <div
        className="row"
        style={{
          width: "100%",
        }}
      >
        <div
          className="navbar-brand mb-0 h1 m-auto col-md-3 float-start"
          style={{ fontSize: "1.4em", letterSpacing: "1px" }}
        >
          <HouseFill
            className="mx-2"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/home");
            }}
          />
          <ArrowLeft
            className="mx-2"
            onClick={() => {
              if (view === "home") {
                navigate("/");
                return;
              } else if (view === "details") {
                window.history.back();
                return;
              } else if (view === "Vendors") {
                navigate("/details");
                return;
              } else {
                window.history.back();
                return;
              }
            }}
          />

          <Link to="/" style={{ color: "black", textDecoration: "none" }}>
            Invoice Intelligence Platform
          </Link>
        </div>
        {view !== "Registration" && (
          <div className="col-md-4">
            <ButtonGroup className="w-100">
              <FilterDropdown />
              <RestaurantDropdown />
              <VendorDropdown />

              <Dropdown
                onToggle={(s) => setSearchResutsVisible(s)}
                drop="start"
                show={searchResultVisible}
                style={{ flex: "1.5 1 0%" }}
                className="position-relative w-100 flex-1.5"
              >
                <input
                  type="text"
                  className="form-control pr-5"
                  placeholder="Search Invoice"
                  aria-label="Search Invoice"
                  aria-describedby="basic-addon1"
                  onChange={handleSearchInputChange}
                  value={search}
                />
                <Dropdown.Toggle
                  disabled={!search}
                  onClick={() => {
                    if (search) {
                      setSearchResutsVisible(true);
                    } else {
                      setSearchResutsVisible(false);
                    }
                    setSearchInput(search);
                  }}
                  as={SearchToggle}
                  className="before-content-none h-100 position-absolute top-0 bottom-0 end-0"
                />
                <Dropdown.Menu
                  style={{
                    width: "100%",
                    minWidth: "300px",
                    maxWidth: "500px",
                    background: "white",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    maxHeight: "250px",
                    scrollbarWidth: "thin",
                    scrollbarColor: "#FDFFD0",
                    paddingRight: "12px",
                    marginRight: "-44px",
                    marginTop: "40px",
                  }}
                >
                  <div
                    className="d-flex flex-row justify-content-between mx-2"
                    style={{ height: "30px" }}
                  >
                    <span>Matching Invoice Numbers</span>
                    <CloseButton
                      onClick={() => setSearchResutsVisible(false)}
                    />
                  </div>
                  <hr className="featurette-divider mt-0 mb-0"></hr>
                  <div style={{ height: "140px", overflowY: "auto" }}>
                    {isPending ? (
                      <div className="d-flex h-100 w-100 justify-content-center align-items-center">
                        <Spinner />
                      </div>
                    ) : isError ? (
                      error.message
                    ) : (
                      invoiceNumArray.map((number) => (
                        <>
                          <div
                            style={{ height: "30px", cursor: "pointer" }}
                            key={number}
                            onClick={() => {
                              setSearchResutsVisible(false);
                              navigate(
                                `/invoice-details/${number.document_uuid}`
                              );
                            }}
                            className="d-flex justify-content-between align-items-center"
                          >
                            <div
                              className="mx-2 text-gray text-sm"
                              style={{
                                fontSize: "12px",
                              }}
                            >
                              Inv No.: {number.invoice_number}
                            </div>
                            <div
                              className="mx-2 text-muted fst-italic"
                              style={{ fontSize: "10px" }}
                            >
                              {number.vendor_name}
                            </div>
                          </div>
                          <hr className="featurette-divider mt-0 mb-0"></hr>
                        </>
                      ))
                    )}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </ButtonGroup>
          </div>
        )}
        <div
          className=" col-md-3"
          style={{ display: "flex", gap: "16px", alignItems: "center" }}
        >
          {view === "details" ? (
            <>
              <div className="form-check">
                <input
                  className="form-check-input mx-2"
                  type="checkbox"
                  id="inlineCheckbox1"
                  value={getVerified}
                  onChange={() => setShowVertical(!showVertical)}
                  style={{ float: "none" }}
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Change Layout
                </label>
              </div>
              {page !== "pdf" && (
                <Link
                  to="/details/pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    if (page === "table") {
                      localStorage.setItem(
                        "filters",
                        localStorage.getItem("filters")
                      );
                    } else {
                      localStorage.setItem("filters", JSON.stringify(filters));
                    }
                  }}
                >
                  PDF
                </Link>
              )}
              {page !== "table" && (
                <Link
                  to="/details/table"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    if (page === "pdf") {
                      localStorage.setItem(
                        "filters",
                        localStorage.getItem("filters")
                      );
                    } else {
                      localStorage.setItem("filters", JSON.stringify(filters));
                    }
                  }}
                >
                  Table
                </Link>
              )}
            </>
          ) : view === "home" ? (
            <>
              <Link to="/statistics">Statistics</Link>
              <Link to="/engineering-issues">Engineering Issues</Link>
            </>
          ) : view === "engineeringIssuuues" ? (
            <>
              <Link to="/rerun-invoices">Re-Run Invoices</Link>
            </>
          ) : null}

          <img
            src={`${process.env.PUBLIC_URL}/logout.png`}
            alt="Registration"
            className="img-fluid "
            onClick={() => {
              localStorage.removeItem("token");
              navigate("/login");
            }}
            style={{ height: "20px", marginLeft: "20px", cursor: "pointer" }}
          />
        </div>
      </div>
    </nav>
  );
};

const SearchToggle = React.forwardRef(
  (
    {
      children,
      style,
      className,
      disabled,
      "aria-labelledby": labeledBy,
      ...props
    },
    ref
  ) => {
    return (
      <>
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
          {...props}
        >
          <button
            disabled={disabled}
            className="input-group-text h-100"
            id="basic-addon1"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
            </svg>
          </button>
        </div>
      </>
    );
  }
);
