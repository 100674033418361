import { create } from "zustand";

export const useCommonIssuesStore = create((set, get) => ({
  issues: [],
  uuid: undefined,
  actions: {
    reset: () => set({ uuid: undefined }),
    setIssues: (issues) => set({ issues }),
    addIssue: (issue) => {
      const issues = [...get().issues];
      issues.push(issue);
      return set({ issues });
    },
    setUUID: (uuid) => set({ uuid }),
  },
}));

export const useCommonIssuesActions = () =>
  useCommonIssuesStore((state) => state.actions);

//  to use outside of component
export const resetCommonIssues = () =>
  useCommonIssuesStore.getState().actions.reset();
