import "./App.css";
import React from "react";
import { BrowserRouter} from "react-router-dom";
import Router from "./routes";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "lib/react-query";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

function App() { 

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </div>
    </QueryClientProvider>
  );
}

export default App;
