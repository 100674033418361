function transformInitialMapping(initialMapping, isreverse) {
  if (isreverse == false) {
    let arrayd = [];
    let mData = Object.keys(initialMapping).map((key) => {
      arrayd.push({
        name: key,
        actual_header_name: initialMapping[key]["actual_header_name"],
        actual_header_position: initialMapping[key]["actual_header_position"],
      });
    });
    return arrayd;
  } else {
    let finalObj = {};
    let reversedData = initialMapping.map((iter) => {
      finalObj[iter.name] = {
        actual_header_name: iter.actual_header_name,
        actual_header_position: iter.actual_header_position,
      };
    });
    return finalObj;
  }
}

export { transformInitialMapping };
