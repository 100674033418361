import { useSearchParams } from "react-router-dom";

/**
 * This hook is used to get the value of a nested key within the 'filters' parameter 
 * in the URL search params. It takes a path array as an argument, representing the 
 * keys path to the desired value within the 'filters' object.
 */

const useGetNestedFilterValue = () => {
  const [searchParams] = useSearchParams();

  const getNestedFilterValue = (pathArray) => {
    // Parse the 'filters' parameter
    const filtersParam = searchParams.get('filters');
    if (!filtersParam) {
      console.error('No filters parameter found in the URL');
      return undefined;
    }

    let filters;
    try {
      filters = JSON.parse(filtersParam);
    } catch (e) {
      console.error('Error parsing filters JSON:', e);
      return undefined;
    }


    // Helper function to recursively get the nested value
    const getNestedValue = (obj, path) => {
      return path.reduce((acc, key) => {
        return acc && acc[key] !== undefined ? acc[key] : undefined;
      }, obj);
    };

    // Get the nested value based on the pathArray
    return getNestedValue(filters, pathArray);
  };

  return getNestedFilterValue;
};

export default useGetNestedFilterValue;
