export const toChartData = (statistics, percentage) => {
  const data = [];
  data.push({
    group: "Detected",
    key: "Detected",
    value: statistics["detected_invoices"],
    percentage: percentage["detected_invoices"],
  });
  data.push({
    group: "Click Bacon",
    key: "Click Bacon",
    value: statistics["from_click_bacon"],
    percentage: percentage["from_click_bacon"],
  });
  data.push({
    group: "Manual",
    key: "Manual",
    value: statistics["from_manual"],
    percentage: percentage["from_manual"],
  });
  data.push({
    group: "Requiring Verification",
    key: "Requiring Verification",
    value: statistics["invoices_requiring_verification"],
    percentage: percentage["invoices_requiring_verification"],
  });
  data.push({
    group: "Total",
    key: "Total",
    value: statistics["total_invoice"],
    percentage: percentage["total_invoice"],
  });
  data.push({
    group: "Undetected",
    key: "Undetected",
    value: statistics["undetected_invoices"],
    percentage: percentage["undetected_invoices"],
  });
  data.push({
    group: "Successfull",
    key: "Successfull",
    value: statistics["verdict_successfull_invoices"],
    percentage: percentage["verdict_successfull_invoices"],
  });
  data.push({
    group: "Manually Balanced",
    key: "Manually Balanced",
    value: statistics["manually_balanced_invoices"],
    percentage: percentage["manually_balanced_invoices"],
  });
  data.push({
    group: "Auto Balanced",
    key: "Auto Balanced",
    value: statistics["auto_balanced_invoices"],
    percentage: percentage["auto_balanced_invoices"],
  });
  data.push({
    group: "Failed Due to Different Doc Type",
    key: "Failed Due to Different Doc Type",
    value: statistics["failed_due_to_different_type_document"],
    percentage: percentage["failed_due_to_different_type_document"],
  });
  data.push({
    group: "Failed Due to Page Missing",
    key: "Failed Due to Page Missing",
    value: statistics["failed_due_to_page_missing"],
    percentage: percentage["failed_due_to_page_missing"],
  });
  data.push({
    group: "Rerun Invoices",
    key: "Rerun Invoices",
    value: statistics["rerun_invoices"],
    percentage: percentage["rerun_invoices"],
  });
  data.push({
    group: "Rerun Failed",
    key: "Rerun Failed",
    value: statistics["rerun_failed"],
    percentage: percentage["rerun_failed"],
  });
  data.push({
    group: "Rerun Success",
    key: "Rerun Success",
    value: statistics["rerun_success"],
    percentage: percentage["rerun_success"],
  });
  data.push({
    group: "Accepted Invoices",
    key: "Accepted Invoices",
    value: statistics["accepted_invocies"],
    percentage: percentage["accepted_invocies"],
  });
  data.push({
    group: "Auto Accepted Invoices",
    key: "Auto Accepted Invoices",
    value: statistics["auto_accepted_invocies"],
    percentage: percentage["auto_accepted_invocies"],
  });

  return data;
};
