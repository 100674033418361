import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MetaDataTable from "../../Tables/MetaDataTable";
import ProcessedTable from "../../Tables/ProcessedTable";
import RawTable from "../../Tables/RawTable";
import EditMetaDataTable from "../../Tables/EditMetaDataTable";
import CombinedTable from "../../Tables/CombinedTable";

const Tab = ({ value, activeValue, onClick, ...props }) => (
  <button
    className="border p-2"
    style={{
      flex: "1 1 0%",
      minWidth: '120px',
      backgroundColor: activeValue === value ? "#FDFFD0" : "white",
      textTransform: "capitalize",
      cursor: "pointer",
    }}
    onClick={() => onClick(value)}
    {...props}
  >
    {value}
  </button>
);

const Table = ({ data, invoiceTableData, combinedTableData, setCombinedTableData }) => {
  const [activeTab, setActiveTab] = useState("Raw Metadata");

  if (!data || Object.keys(data).length === 0) {
    return <p>Invoice structure is not compatible for detection.</p>;
  }

  const handleTabChange = (tab) => setActiveTab(tab);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggaeble
        pauseOnHover
        theme="light"
      />
      <div className="d-flex border my-2" style={{overflowX: "auto"}}>
        <Tab
          activeValue={activeTab}
          onClick={handleTabChange}
          value={"Raw Metadata"}
        />
        <Tab
          activeValue={activeTab}
          onClick={handleTabChange}
          value={"Edit Metadata"}
        />
        <Tab
          activeValue={activeTab}
          onClick={handleTabChange}
          value={"Raw Table"}
        />
        <Tab
          activeValue={activeTab}
          onClick={handleTabChange}
          value={"Processed Table"}
        />
        <Tab
          activeValue={activeTab}
          onClick={handleTabChange}
          value={"Combined Table"}
        />
      </div>
      {activeTab === "Raw Table" ? (
        <RawTable data={data} />
      ) : activeTab === "Raw Metadata" ? (
        <MetaDataTable />
      ) : activeTab === "Processed Table" ? (
        <ProcessedTable invoiceTableData={invoiceTableData} />
      ) : activeTab === "Edit Metadata" ? (
        <EditMetaDataTable />
      ) : activeTab === "Combined Table"?
      (<CombinedTable combinedTableData={combinedTableData} setCombinedTableData = {setCombinedTableData}/>)
      :null}
    </>
  );
};

export default Table;
