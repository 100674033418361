import React from "react";
import { useEffect, useState } from "react";
import { NavHeader } from "components/layout/NavHeader";
import { Spinner } from "components/common/Spinner";
import Header from "./Header";
import RerunTable from "./RerunTable";
import { getInvoiceNumbersandUUIDs, reRunInvoices } from "./api";
import useAppStore from "store/global";
import { toast } from "react-toastify";
import { Spinner as Loaderr } from "react-bootstrap";
import { useParams } from "react-router-dom";
export default function InvoiceRerun() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rerunloading, setRerunLoading] = useState(false);
  const {invoiceUUID } = useAppStore();
  const { vendorName } = useParams();
  const [uuids, setUuids] = useState([]);
  useEffect(() => {
    setLoading(true);
    if(vendorName==='NA'){
      setLoading(false);
      setData([
        {
          invoice_number: "NA",
          uuid: invoiceUUID,
        },
      ]);
      return;
    }
    getInvoiceNumbersandUUIDs(vendorName)
      .then((res) => {
        setData(res.invoice_uuids_and_numbers);
        setLoading(false);
      })
      .catch((err) => {
        setData([]);
        setLoading(false);
      });
  }, []);

  const handleInvoiceRerun = () => {
    setRerunLoading(true);
    reRunInvoices(uuids)
      .then((res) => {
        setRerunLoading(false);
        toast.success(res.message);
      })
      .catch((err) => {
        setRerunLoading(false);
        toast.error('Error in re-running invoices');
      });
  };

  return (
    <div>
      <NavHeader view="invoiceRerun" />
      <Header vendorName={vendorName} />
      {loading ? (
        <Spinner />
      ) : (
        <div className="container my-4">
          <RerunTable data={data} setUuids={setUuids} uuids={uuids} />
          <div className="d-flex justify-content-end">
            <button type="button" className="btn btn-warning"
            onClick={handleInvoiceRerun}
            >
              {
                rerunloading ? <Loaderr
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mx-2"
              /> : null
              }
              Run
              
              </button>
          </div>
        </div>
      )}
    </div>
  );
}