import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import PDFTableComponent from '../components/PDFTableComponent';
import { HomeTable } from 'components/Tables/HomeTable';
import { StatisticsPage } from 'modules/statistics/routes';
import { EngineeringIssuesPage } from 'components/engissues/routes';
import InvoiceRerun from 'components/invoicererun/routes';
import Main from 'components/engissues/PDFAndTables/Main';
import Mainx from 'components/Details/Main';
import Home from 'modules/vendorConsolidation/Home';
import VendorDetails from 'modules/vendorConsolidation/VendorDetails';
import VendorBranches from 'modules/vendorConsolidation/VendorBranches';
import VendorCombine from 'modules/vendorConsolidation/VendorCombine';
import CreateInvoice from 'modules/invoiceCreation';
import InvoiceComparison from 'modules/vendorConsolidation/components/InvoiceComparison';
import BranchDetails from 'modules/vendorConsolidation/BranchDetails';
import VendorItemMaster from 'modules/vendorConsolidation/VendorItemMaster';
import FastItemMasterVerification from 'modules/vendorConsolidation/fastItemMasterVerification';
import LoginPage from 'components/Auth/Login/LoginPage';
import Direction from 'components/Direction/Direction';
import ProtectedRoute from './ProtectedRoute'; // Adjust the import path as needed
import UserActivity from 'modules/userActivity/userActivity';
import Activity from 'modules/userActivity/Activity';
import InvoiceAssignment from 'modules/invoiceAssignment';
const publicRoutes = [
  {
    path: '/',
    children: [
      {
        path: 'home',
        element: <ProtectedRoute element={<HomeTable />} />,
      },
      {
        path: 'statistics',
        element: <ProtectedRoute element={<StatisticsPage />} />,
      },
      {
        path: 'engineering-issues',
        element: <ProtectedRoute element={<EngineeringIssuesPage />} />,
      },
      {
        path: 'details/:page?',
        element: <ProtectedRoute element={<PDFTableComponent />} />,
      },
      {
        path: 'engineering-issues/invoice/:uuid',
        element: <ProtectedRoute element={<Main />} />,
      },
      {
        path: 'rerun-invoices/:vendorName',
        element: <ProtectedRoute element={<InvoiceRerun />} />,
      },
      {
        path: 'invoice-details/:uuid',
        element: <ProtectedRoute element={<Mainx />} />,
      },
      {
        path: 'vendor-consolidation',
        element: <ProtectedRoute element={<Home />} />,
      },
      {
        path: 'vendor-consolidation/:vendorID',
        element: <ProtectedRoute element={<VendorDetails />} />,
      },
      {
        path: 'vendor-consolidation/branches/:vendorID',
        element: <ProtectedRoute element={<VendorBranches />} />,
      },
      {
        path: 'vendor-consolidation/combine-vendors/:vendorID',
        element: <ProtectedRoute element={<VendorCombine />} />,
      },
      {
        path: 'vendor-consolidation/vendor-item-master/:vendorId',
        element: <ProtectedRoute element={<VendorItemMaster />} />,
      },
      {
        path: '/vendor/:vendorId/branch/:branchId',
        element: <ProtectedRoute element={<BranchDetails />} />,
      },
      {
        path: 'create-invoice',
        element: <ProtectedRoute element={<CreateInvoice />} />,
      },
      {
        path: 'compare-invoices/:actualVendorName/:similarVendorName',
        element: <ProtectedRoute element={<InvoiceComparison />} />,
      },
      {
        path: 'fast-item-master-verification/:vendorId',
        element: <ProtectedRoute element={<FastItemMasterVerification />} />,
      },
      {
        index: true,
        element: <ProtectedRoute element={<Direction />} />,
      },
      {
        path: 'user-activity',
        element: <ProtectedRoute element={<UserActivity />} />,
      },
      {
        path: 'user-activity-stats',
        element: <ProtectedRoute element={<Activity />} />,
      },
      {
        path: 'invoice-assignment',
        element: <ProtectedRoute element={<InvoiceAssignment />} />,
      },
      {
        path: '*',
        element: <Navigate to="/" />,
      },
    ],
  },
  {
    path: '/login',
    element: <LoginPage />,
  }
];

const Router = () => {
  const routes = publicRoutes;
  const element = useRoutes(routes);
  return <>{element}</>;
};

export default Router;
