import React, { useCallback, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import TableComponent from "./TableComponent";
import { axiosInstance } from "lib/axios";
import { ColorRing } from "react-loader-spinner";
import { toast } from "react-toastify";
import { pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import useAppStore from "store/global";
import { NavHeader } from "./layout/NavHeader";
import { InvoiceHeader } from "./layout/InvoiceHeader";
import { PDFComponent } from "./PDFComponent";
import { useParams } from "react-router";
import useInvoicePagination from "store/invoicePagination";
import { useInvoicePaginationActions } from "store/invoicePagination";
import { useCommonIssuesActions } from "store/commonIssues";
import { InvoicePagination } from "./layout/InvoicePagination";
import {
  useGlobalFilterParams,
  useGlobalFilterActions,
} from "store/globalFIlter";
import { useLocation, useSearchParams } from "react-router-dom";
import useUpdateSearchParams from "hooks/useUpdateSearchParams";
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const PDFTableComponent = () => {
  const updateSearchParams = useUpdateSearchParams();
  const pageNumber = useInvoicePagination((state) => state.pageNumber);
  const [searchParams, setSearchParams] = useSearchParams();
  const urlFilters = searchParams.get("filters") ?? "{}";
  if (urlFilters == "{}") {
    updateSearchParams({
      pdf_index: 1,
      invoice_size: 8,
      filters: {
        human_verified: "both",
        human_verification: "both",
        invoice_detection_status: "all",
        rerun_status: "both",
        invoice_type: "all",
        start_date: null,
        end_date: null,
        vendor_name: null,
        restaurant_id: null,
      },
    });
  }
  const scale = useAppStore((state) => state.scale);
  const { setTotalInvoices } = useInvoicePaginationActions();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const {
    tableData,
    setTableData,
    setPdfUrl,
    pdfUrl,
    rotation,
    setInvoiceNum,
    setInvoiceDate,
    setdueDate,
    setvendorName,
    setIsVendorVerified,
    setVendorPhone,
    setVendorAddress,
    setIsAddressVerified,
    setInvoiceBalance,
    setInvoiceTotal,
    setInvoicePaymentTerms,
    setInvoiceRemitTo,
    setInvoiceRoute,
    setInvoiceShipTo,
    setInvoiceBillTo,
    setInvoiceType,
    setInvoiceGlobalAddresses,
    setInvoiceSoldTo,
    setTotalPagesRcvd,
    setTotalPagesInInvoice,
    setTotalPagesInInvoiceFromGlobal,
    setvendorNamesSource,
    setTotalPagesProcessed,
    setHumanVerificationReqd,
    setInvoiceTotalFromtable,
    setInvoiceDiscount,
    setInvoiceTaxes,
    setFailedReasons,
    setVerdict,
    setConcerns,
    loading,
    setLoading,
    invoiceTableData,
    setInvoiceTableData,
    combinedTableData,
    setCombinedTableData,
    setExtraChargesAdded,
    setExtraFeesAdded,
    setExtraDiscountsAdded,
    setRespData,
    selectedFilter,
    setAdditionalCols,
    setAdditionalHeaders,
    setCategoryChoices,
    setNumberOfRows,
    setAdditionalColsTables,
    saved,
    setSaved,
    getVerified,
    setStatus,
    showVertical,
    setBlobPath,
    setPdfSource,
    setVersion,
    setDocumentTypePrediction,
    setInvoiceUUID,
    setRerunStatus,
    setCategoryWiseSum,
    setQuickBooksDocType,
    setRestaurantsId,
    setBranchId,
    setVendorId,
    setCreditCardName,
    isRunning,
    setIsRunning,
    setCreditCardNumber,
    setPageNum,
    setScale,
    lockZoomAndScroll,
    setLatestUpdateInfo,
    isTimerRunning,
    setVerificationTime,
    setIsTimerRunning,
    setLineItemMetaData,
    setSessionStartTime,
    setHumanVerified,
    setIsRejected,
    setRestaurantName,
    setAcceptAndRejectControl
  } = useAppStore();

  const filters = useGlobalFilterParams();
  const { setIssues, setUUID } = useCommonIssuesActions();
  const { page } = useParams();
  const isRedirectedFromRestaurantVendor = queryParams.get(
    "isRedirectedFromRestaurantVendor"
  );
  const isRedirectedFromRestaurant = queryParams.get(
    "isRedirectedFromRestaurant"
  );
  const queryFilters = queryParams.get("filters");
  const { setRestaurantId, setVendorName } = useGlobalFilterActions();
  const [responsestatus, setstatus] = useState("");
  const [message, setMessage] = useState("");
  const [hoveredCellCoordinates, setHoveredCellCoordinates] = useState(null);
  const [singleCellCoordinates, setSingleCellCoordinates] = useState(null);
  const [multipleboundingBoxes, setMultipleBoundingBoxes] = useState(false);
  const [isPdfFetched, setIsPdfFetched] = useState(false);
  const [pdfDocument, setPdfDocument] = useState(null);

  const rotateCoordinates = useCallback(
    (coordinates, rotation) => {
      const rotatedCoordinates = coordinates.map(({ x, y }) => {
        let newX = x;
        let newY = y;
        if (rotation === 90) {
          newX = y;
          newY = 1 - x;
        } else if (rotation === 180) {
          newX = 1 - x;
          newY = 1 - y;
        } else if (rotation === 270) {
          newX = 1 - y;
          newY = x;
        }
        return { x: newX, y: newY };
      });
      return rotatedCoordinates;
    },
    [rotation]
  );

  useEffect(() => {
    setIsTimerRunning(true);
  }, []);

  useEffect(() => {
    let interval;
    if (isTimerRunning) {
      interval = setInterval(() => {
        setVerificationTime();
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isTimerRunning, setVerificationTime]);

  const calculatePixelCoordinates = (
    normalizedCoordinates,
    pageWidth,
    pageHeight,
    scale
  ) => {
    const pixelCoordinates = {
      x: normalizedCoordinates.X * pageWidth * scale,
      y: normalizedCoordinates.Y * pageHeight * scale,
    };
    return pixelCoordinates;
  };

  function handlePdfPage(pdf, normalizedCoordinates, pageNumber, scale) {
    pdf
      .getPage(pageNumber)
      .then((page) => {
        const pageWidth = page.view[2];
        const viewport = page.getViewport({ scale });
        const pageHeight = page.view[3];
        let pixelCoordinates = []
        normalizedCoordinates.map((normalizedCoordinate) => {
          const pixelCoordinate = calculatePixelCoordinates(
            normalizedCoordinate,
            pageWidth,
            pageHeight,
            scale
          );
          pixelCoordinates.push(pixelCoordinate);
        })

        const boundingBox = {
          page: pageNumber,
          coordinates: pixelCoordinates,
        };

        const reactPdfWrapper = document.getElementById("react-pdf__Wrapper");
        if (reactPdfWrapper) {
          reactPdfWrapper.scroll({
            top: pixelCoordinates[0].y - 100,
            left: pixelCoordinates[0].x - 50,
            behavior: "smooth",
          });
        }
        setSingleCellCoordinates(boundingBox);
      })
      .catch((error) => {
        console.error("Error fetching PDF page dimensions:", error);
      });
  }

  function handleCellHover(normalizedCoordinates, pageNumber) {
    if (normalizedCoordinates == null) {
      setSingleCellCoordinates(null);
      if (lockZoomAndScroll === true) {
        return;
      }
      setScale(1.0);
      const reactPdfWrapper = document.getElementById("react-pdf__Wrapper");
      if (reactPdfWrapper) {
        reactPdfWrapper.scrollTop = 0;
      }
      return;
    }

    const HOVER_ZOOMIN_SCALE = lockZoomAndScroll
      ? scale
      : scale < 2
      ? 2.0
      : scale;
    setPageNum(pageNumber);
    setScale(HOVER_ZOOMIN_SCALE);

    // Check if the PDF document is already fetched
    if (isPdfFetched) {
      // Use the existing PDF document
      handlePdfPage(
        pdfDocument,
        normalizedCoordinates,
        pageNumber,
        HOVER_ZOOMIN_SCALE
      );
    } else {
      // Fetch the PDF document
      pdfjs
        .getDocument(pdfUrl)
        .promise.then((pdf) => {
          setPdfDocument(pdf); // Store the PDF document for reuse
          setIsPdfFetched(true); // Update the state variable
          handlePdfPage(
            pdf,
            normalizedCoordinates,
            pageNumber,
            HOVER_ZOOMIN_SCALE
          );
        })
        .catch((error) => {
          console.error("Error fetching PDF document:", error);
        });
    }
  }

  function handleMultipleCellHover(normalizedCoordinatesArray, pageNumber) {
    if (
      !normalizedCoordinatesArray ||
      normalizedCoordinatesArray.length === 0 ||
      normalizedCoordinatesArray === null
    ) {
      setHoveredCellCoordinates(null);
      if (lockZoomAndScroll === true) {
        return;
      }
      const reactPdfWrapper = document.getElementById("react-pdf__Wrapper");
      if (reactPdfWrapper) {
        reactPdfWrapper.scrollTop = 0;
      }
      return;
    }
    setPageNum(pageNumber);

    pdfjs
      .getDocument(pdfUrl)
      .promise.then((pdf) => {
        const promises = normalizedCoordinatesArray.map(
          async (row, rowIndex) => {
            const page = await pdf.getPage(pageNumber);
            const pageWidth = page.view[2];
            const pageHeight = page.view[3];

            console.log("coming here", row);
            // Use row.map instead of normalizedCoordinatesArray.map
            const coordinates = row.map((normalizedCoordinates) => {
              console.log("first normalized coordinates", normalizedCoordinates);
              const pixelCoordinates = calculatePixelCoordinates(
                normalizedCoordinates,
                pageWidth,
                pageHeight,
                scale
              );
              return {
                coordinates: pixelCoordinates,
              };
            });

            return coordinates;
          }
        );

        Promise.all(promises)
          .then((boundingBoxes) => {
            console.log("first bounding box", boundingBoxes);
            // Flatten the array of arrays into a single array of coordinates
            const flattenedCoordinates = boundingBoxes.flat();
            // console.log("The flattened coordinates are", flattenedCoordinates);
            // const reactPdfWrapper =
            //   document.getElementById("react-pdf__Wrapper");
            // if (reactPdfWrapper) {
            //   reactPdfWrapper.scroll({
            //     top: flattenedCoordinates[0].coordinates.y - 100,
            //     left: flattenedCoordinates[0].coordinates.x - 50,
            //     behavior: "smooth",
            //   });
            // }
            setHoveredCellCoordinates(flattenedCoordinates);
          })
          .catch((error) => {
            console.error("Error fetching PDF page dimensions:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching PDF:", error);
      });
  }


  useEffect(() => {
    let decodedF = decodeURIComponent(urlFilters);
    const decodedFilter = JSON.parse(decodedF);
    if (isRedirectedFromRestaurantVendor === "true") {
      setVendorName(decodedFilter.vendor_name);
      setRestaurantId(decodedFilter.restaurant_id);
    }
    if (isRedirectedFromRestaurant === "true") {
      setRestaurantId(decodedFilter.restaurant_id);
    }
  }, [
    isRedirectedFromRestaurantVendor,
    isRedirectedFromRestaurant,
    queryFilters,
  ]);

  const getExtractedInvoice = async (loadAgain) => {
    if (pageNumber === 0) {
      return;
    }
    if (loadAgain) {
      setLoading(true);
    }
    //setLoading(true);
    let body = {};
    if (page === "pdf" || page === "table") {
      body = {
        pdf_index: pageNumber,
        filters: JSON.parse(localStorage.getItem("filters")),
      };
    } else {
      body = urlFilters
        ? JSON.parse(urlFilters)
        : {
            pdf_index: pageNumber,
            filters: filters,
          };
    }

    const apiUrl = "/get_extracted_invoices";
    axiosInstance
      .post(apiUrl, body)
      .then((response) => {
        console.log(response);
        const data = response.data.response?.invoice || {};
        setLatestUpdateInfo(response?.data?.response?.latest_update_info);
        setLineItemMetaData(response?.data?.response?.line_item_metadata);
        setPdfUrl(response.data?.response?.pdf_link);
        setBlobPath(response.data?.response?.blob_path);
        setPdfSource(response.data?.response?.pdf_source);

        setstatus(response.data.response.status);
        setMessage(response.data.response?.message);
        const issues =
          response.data?.response?.common_issues_in_invoice_extraction;
        issues && setIssues(issues);
        setUUID(response.data?.response?.uuid);
        if (Object.keys(data)?.length === 0) {
          toast.error("No data found for this invoice");
          setStatus(false);
          setTableData([]);
          setLoading(false);
          setTotalInvoices(response.data.response.total_invoices);
          setInvoiceNum(response.data.response.invoice_number);
          return;
        }

        const keys = Object.keys(data);
        const tableData = [];

        for (let i = 0; i < Object.values(data[keys[0]]).length; i++) {
          const obj = {};
          for (const key of keys) {
            obj[key] = data[key][i];
          }
          tableData.push(obj);
        }

        setTableData(tableData);

        // Get data for invoice table
        const data0 = response.data.response.invoice_1;
        if (Object.keys(data0).length === 0) {
          setInvoiceTableData([]);
          setLoading(false);
          setTotalInvoices(response.data.response.total_invoices);
          setInvoiceNum(response.data.response.invoice_number);
        }

        const keys0 = Object.keys(data0);
        const invoicetableData = [];

        for (let i = 0; i < Object.values(data0[keys0[0]]).length; i++) {
          const obj = {};
          for (const key of keys0) {
            obj[key] = data0[key][i];
          }
          invoicetableData.push(obj);
        }
        setInvoiceTableData(invoicetableData);

        // Get data for additional table
        const data1 =
          response.data.response.additional_columns[
            "combined_additional_columns"
          ];
        if (data1 && Object.keys(data1).length === 0) {
          setAdditionalCols([]);
          setLoading(false);
        }
        if (data1 && Object.keys(data1).length > 0) {
          const keys1 = Object.keys(data1);
          const additionaltableData = [];

          for (let i = 0; i < Object.values(data1[keys1[0]]).length; i++) {
            const obj = {};
            for (const key of keys1) {
              obj[key] = data1[key][i];
            }
            additionaltableData.push(obj);
          }
          setAdditionalCols(additionaltableData);
        } else {
          setAdditionalCols([]);
        }

        //Get data for the combined table
        const data2 = response.data.response.combined_invoice;
        if (data2 && Object.keys(data2).length === 0) {
          setCombinedTableData([]);
          setLoading(false);
        }
        if (data2 && Object.keys(data2).length > 0) {
          const keys2 = Object.keys(data2);
          const combinedData = [];

          for (let i = 0; i < Object.values(data2[keys2[0]]).length; i++) {
            const obj = {};
            for (const key of keys2) {
              obj[key] = data2[key][i];
            }
            combinedData.push(obj);
          }
          setCombinedTableData(combinedData);
        } else {
          setCombinedTableData([]);
        }

        setAdditionalColsTables(
          response.data.response.additional_columns[
            "table_specific_additional_columns"
          ]
        );
        // setPdfUrl(response.data.response.pdf_link);
        setInvoiceNum(response.data.response.invoice_metadata.invoice_number);
        setCategoryChoices(response.data.response.category_choices);
        setInvoiceDate(response.data.response.invoice_metadata.invoice_date);
        setvendorName(response.data.response.invoice_metadata.vendor_name);
        setIsVendorVerified(
          response.data.response?.vendor_details?.vendor_human_verified || false
        );
        setBranchId(response.data.response.vendor_details.branch_id);
        setVendorId(response.data.response.vendor_details.vendor_id);
        setVendorPhone(
          response.data.response.invoice_metadata.vendor_phone_number
        );
        setVendorAddress(
          response.data.response.invoice_metadata.vendor_address
        );
        setIsAddressVerified(
          response.data.response?.vendor_details.branch_human_verified
        );
        setTotalInvoices(response.data.response.total_invoices);
        setdueDate(response.data.response.invoice_metadata.invoice_due_date);
        setInvoiceBalance(
          response.data.response.invoice_metadata.invoice_balance_due
        );
        setInvoiceTotal(
          response.data.response.invoice_metadata.invoice_total_amount
        );
        setInvoicePaymentTerms(
          response.data.response.invoice_metadata.invoice_payment_terms
        );
        setInvoiceRemitTo(
          response.data.response.invoice_metadata.invoice_remit_to
        );
        setInvoiceRoute(response.data.response.invoice_metadata.invoice_route);
        setInvoiceShipTo(
          response.data.response.invoice_metadata.invoice_ship_to
        );
        setInvoiceBillTo(
          response.data.response.invoice_metadata.invoice_bill_to
        );
        setInvoiceType(response.data.response.invoice_type);
        setInvoiceGlobalAddresses(
          response.data.response.invoice_metadata.invoice_global_addresses
        );
        setInvoiceSoldTo(
          response.data.response.invoice_metadata.invoice_sold_to
        );
        setQuickBooksDocType(
          response.data.response.invoice_metadata.quick_book_document_type
        );
        setTotalPagesRcvd(
          response.data.response.invoice_metadata.total_number_of_pages_received
        );
        setTotalPagesInInvoice(
          response.data.response.invoice_metadata.total_pages_in_invoice
        );
        setTotalPagesInInvoiceFromGlobal(
          response.data.response.invoice_metadata
            .total_pages_in_invoice_from_global
        );
        setvendorNamesSource(
          response.data.response.invoice_metadata.vendor_name_source
        );
        setTotalPagesProcessed(
          response.data.response.invoice_metadata
            .total_number_of_pages_processed
        );
        setHumanVerificationReqd(
          response.data.response.human_verification_info
            .human_verification_required
        );
        setInvoiceTotalFromtable(
          response.data.response.invoice_metadata.invoice_total_from_table
        );
        setInvoiceDiscount(
          response.data.response.invoice_metadata.invoice_discount
        );
        setInvoiceTaxes(response.data.response.invoice_metadata.invoice_taxes);
        setCreditCardName(
          response.data.response.invoice_metadata.credit_card_name
        );
        setCreditCardNumber(
          response.data.response.invoice_metadata.credit_card_number
        );
        setVerdict(response.data.response.human_verification_info.verdict);
        setHumanVerified(
          response.data.response.human_verification_info.human_verified
        );
        setIsRejected(response.data.response.human_verification_info.rejected);
        setFailedReasons(
          response.data.response.human_verification_info.failed_reasons
        );
        setConcerns(response.data.response.human_verification_info.concerns);
        let extra_charges_added =
          response.data.response.invoice_metadata.extra_charges_added;
        if (!extra_charges_added || extra_charges_added[0] === "NA") {
          extra_charges_added = [0];
        }
        setExtraChargesAdded(extra_charges_added);
        let extra_fees_added =
          response.data.response.invoice_metadata.extra_fees_added;
        if (!extra_fees_added || extra_fees_added[0] === "NA") {
          extra_fees_added = [0];
        }
        setExtraFeesAdded(extra_fees_added);
        let extra_discounts_added =
          response.data.response.invoice_metadata.extra_discounts_added;
        if (!extra_discounts_added || extra_discounts_added[0] === "NA") {
          extra_discounts_added = [0];
        }
        setExtraDiscountsAdded(extra_discounts_added);
        setRespData(response.data.response);
        setAdditionalHeaders(
          response.data.response.invoice_metadata
            .processed_table_header_candidates
        );
        setNumberOfRows(
          response.data.response.invoice_metadata.number_of_rows_in_tables
        );
        setVersion(response.data.response.version);
        setDocumentTypePrediction(
          response.data.response.document_type_prediction
        );
        setInvoiceUUID(response.data.response.uuid);
        setRestaurantsId(response.data.response.restaurant_id);
        setRestaurantName(response.data.response.restaurant_name);
        setRerunStatus(response.data.response.rerun_status);
        setCategoryWiseSum(response.data.response.invoice_category_wise_sum);
        setAcceptAndRejectControl(response.data.response.accept_and_reject_control);
        setLoading(false);
        setSaved(false);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    const nt = new Date().getTime();
    setSessionStartTime(nt);

    return () => {
      setSessionStartTime(null);
    };
  }, [pageNumber]);

  useEffect(() => {
    if (pageNumber === 0 || urlFilters === "{}") {
      return;
    }
    console.log(JSON.parse(urlFilters));
    setLoading(true);
    let body = {};
    if (page === "pdf" || page === "table") {
      body = {
        pdf_index: pageNumber,
        filters: JSON.parse(localStorage.getItem("filters")),
      };
    } else {
      body = urlFilters
        ? JSON.parse(urlFilters)
        : {
            pdf_index: pageNumber,
            filters: filters,
          };
    }

    const apiUrl = "/get_extracted_invoices";
    axiosInstance
      .post(apiUrl, body)
      .then((response) => {
        console.log(response);
        const data = response.data.response?.invoice || {};
        setPdfUrl(response.data?.response?.pdf_link);
        setLatestUpdateInfo(response?.data?.response?.latest_update_info);
        setLineItemMetaData(response?.data?.response?.line_item_metadata);
        setBlobPath(response.data?.response?.blob_path);
        setPdfSource(response.data?.response?.pdf_source);
        setstatus(response.data.response.status);
        setMessage(response.data.response?.message);
        const issues =
          response.data?.response?.common_issues_in_invoice_extraction;
        issues && setIssues(issues);
        setUUID(response.data?.response?.uuid);
        if (Object.keys(data)?.length === 0) {
          toast.error("No data found for this invoice");
          setStatus(false);
          setTableData([]);
          setLoading(false);
          setTotalInvoices(response.data.response.total_invoices);
          setInvoiceNum(response.data.response.invoice_number);
          return;
        }

        const keys = Object.keys(data);
        const tableData = [];

        for (let i = 0; i < Object.values(data[keys[0]]).length; i++) {
          const obj = {};
          for (const key of keys) {
            obj[key] = data[key][i];
          }
          tableData.push(obj);
        }

        setTableData(tableData);

        // Get data for invoice table
        const data0 = response.data.response.invoice_1;
        if (Object.keys(data0).length === 0) {
          setInvoiceTableData([]);
          setLoading(false);
          setTotalInvoices(response.data.response.total_invoices);
          setInvoiceNum(response.data.response.invoice_number);
        }

        const keys0 = Object.keys(data0);
        const invoicetableData = [];

        for (let i = 0; i < Object.values(data0[keys0[0]]).length; i++) {
          const obj = {};
          for (const key of keys0) {
            obj[key] = data0[key][i];
          }
          invoicetableData.push(obj);
        }
        setInvoiceTableData(invoicetableData);

        // Get data for additional table
        const data1 =
          response.data.response.additional_columns[
            "combined_additional_columns"
          ];
        if (data1 && Object.keys(data1).length === 0) {
          setAdditionalCols([]);
          setLoading(false);
        }
        if (data1 && Object.keys(data1).length > 0) {
          const keys1 = Object.keys(data1);
          const additionaltableData = [];

          for (let i = 0; i < Object.values(data1[keys1[0]]).length; i++) {
            const obj = {};
            for (const key of keys1) {
              obj[key] = data1[key][i];
            }
            additionaltableData.push(obj);
          }
          setAdditionalCols(additionaltableData);
        } else {
          setAdditionalCols([]);
        }

        //Get data for the combined table
        const data2 = response.data.response.combined_invoice;
        if (data2 && Object.keys(data2).length === 0) {
          setCombinedTableData([]);
          setLoading(false);
        }
        if (data2 && Object.keys(data2).length > 0) {
          const keys2 = Object.keys(data2);
          const combinedData = [];

          for (let i = 0; i < Object.values(data2[keys2[0]]).length; i++) {
            const obj = {};
            for (const key of keys2) {
              obj[key] = data2[key][i];
            }
            combinedData.push(obj);
          }
          setCombinedTableData(combinedData);
        } else {
          setCombinedTableData([]);
        }

        setAdditionalColsTables(
          response.data.response.additional_columns[
            "table_specific_additional_columns"
          ]
        );
        // setPdfUrl(response.data.response.pdf_link);
        setInvoiceNum(response.data.response.invoice_metadata.invoice_number);
        setCategoryChoices(response.data.response.category_choices);
        setInvoiceDate(response.data.response.invoice_metadata.invoice_date);
        setvendorName(response.data.response.invoice_metadata.vendor_name);
        setIsVendorVerified(
          response.data.response?.vendor_details?.vendor_human_verified || false
        );
        setBranchId(response.data.response.vendor_details.branch_id);
        setVendorId(response.data.response.vendor_details.vendor_id);
        setVendorPhone(
          response.data.response.invoice_metadata.vendor_phone_number
        );
        setVendorAddress(
          response.data.response.invoice_metadata.vendor_address
        );
        setIsAddressVerified(
          response.data.response?.vendor_details.branch_human_verified
        );
        setTotalInvoices(response.data.response.total_invoices);
        setdueDate(response.data.response.invoice_metadata.invoice_due_date);
        setInvoiceBalance(
          response.data.response.invoice_metadata.invoice_balance_due
        );
        setInvoiceTotal(
          response.data.response.invoice_metadata.invoice_total_amount
        );
        setInvoicePaymentTerms(
          response.data.response.invoice_metadata.invoice_payment_terms
        );
        setInvoiceRemitTo(
          response.data.response.invoice_metadata.invoice_remit_to
        );
        setInvoiceRoute(response.data.response.invoice_metadata.invoice_route);
        setInvoiceShipTo(
          response.data.response.invoice_metadata.invoice_ship_to
        );
        setInvoiceBillTo(
          response.data.response.invoice_metadata.invoice_bill_to
        );
        setCreditCardName(
          response.data.response.invoice_metadata.credit_card_name
        );
        setCreditCardNumber(
          response.data.response.invoice_metadata.credit_card_number
        );
        setInvoiceType(response.data.response.invoice_type);
        setInvoiceGlobalAddresses(
          response.data.response.invoice_metadata.invoice_global_addresses
        );
        setInvoiceSoldTo(
          response.data.response.invoice_metadata.invoice_sold_to
        );
        setQuickBooksDocType(
          response.data.response.invoice_metadata.quick_book_document_type
        );
        setTotalPagesRcvd(
          response.data.response.invoice_metadata.total_number_of_pages_received
        );
        setTotalPagesInInvoice(
          response.data.response.invoice_metadata.total_pages_in_invoice
        );
        setTotalPagesInInvoiceFromGlobal(
          response.data.response.invoice_metadata
            .total_pages_in_invoice_from_global
        );
        setHumanVerified(
          response.data.response.human_verification_info.human_verified
        );
        setIsRejected(response.data.response.human_verification_info.rejected);
        setvendorNamesSource(
          response.data.response.invoice_metadata.vendor_name_source
        );
        setTotalPagesProcessed(
          response.data.response.invoice_metadata
            .total_number_of_pages_processed
        );
        setHumanVerificationReqd(
          response.data.response.human_verification_info
            .human_verification_required
        );
        setInvoiceTotalFromtable(
          response.data.response.invoice_metadata.invoice_total_from_table
        );
        setInvoiceDiscount(
          response.data.response.invoice_metadata.invoice_discount
        );
        setInvoiceTaxes(response.data.response.invoice_metadata.invoice_taxes);
        setVerdict(response.data.response.human_verification_info.verdict);
        setFailedReasons(
          response.data.response.human_verification_info.failed_reasons
        );
        setConcerns(response.data.response.human_verification_info.concerns);
        let extra_charges_added =
          response.data.response.invoice_metadata.extra_charges_added;
        if (!extra_charges_added || extra_charges_added[0] === "NA") {
          extra_charges_added = [0];
        }
        setExtraChargesAdded(extra_charges_added);
        let extra_fees_added =
          response.data.response.invoice_metadata.extra_fees_added;
        if (!extra_fees_added || extra_fees_added[0] === "NA") {
          extra_fees_added = [0];
        }
        setExtraFeesAdded(extra_fees_added);
        let extra_discounts_added =
          response.data.response.invoice_metadata.extra_discounts_added;
        if (!extra_discounts_added || extra_discounts_added[0] === "NA") {
          extra_discounts_added = [0];
        }
        setExtraDiscountsAdded(extra_discounts_added);
        setRespData(response.data.response);
        setAdditionalHeaders(
          response.data.response.invoice_metadata
            .processed_table_header_candidates
        );
        setNumberOfRows(
          response.data.response.invoice_metadata.number_of_rows_in_tables
        );
        setVersion(response.data.response.version);
        setDocumentTypePrediction(
          response.data.response.document_type_prediction
        );
        setInvoiceUUID(response.data.response.uuid);
        setRestaurantsId(response.data.response.restaurant_id);
        setRestaurantName(response.data.response.restaurant_name);
        setRerunStatus(response.data.response.rerun_status);
        setCategoryWiseSum(response.data.response.invoice_category_wise_sum);
        setAcceptAndRejectControl(response.data.response.accept_and_reject_control);
        setLoading(false);
        setSaved(false);
      })
      .catch((error) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageNumber,
    saved,
    ...Object.values(filters),
    ...Object.values(urlFilters),
  ]);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    localStorage.setItem("detailsPage", "1");

    const handleBeforeUnload = () => {
      localStorage.setItem("detailsPage", "0");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      localStorage.setItem("detailsPage", "0");
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <NavHeader view="details" />
      <InvoiceHeader />
      <div
        className="mx-5"
        style={{
          marginTop: loading || getVerified === "false" ? "0px" : "35px",
        }}
      >
        {loading ? (
          <>
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={[
                "#F57E37",
                "#1BBEE9",
                "#F57E37",
                "#1BBEE9",
                "#F57E37",
                "#1BBEE9",
              ]}
            />
          </>
        ) : responsestatus === "error" ? (
          <>Error: {message}</>
        ) : (
          <>
            {page === "pdf" ? (
              <>
                <PDFComponent
                  pagination={true}
                  hoveredCellCoordinates={hoveredCellCoordinates}
                  setHoveredCellCoordinates={setHoveredCellCoordinates}
                  multipleBoundingBoxes={multipleboundingBoxes}
                  setMultipleBoundingBoxes={setMultipleBoundingBoxes}
                  toggleMultipleBoundingBoxes={handleMultipleCellHover}
                  singleCellCoordinates={singleCellCoordinates}
                />
              </>
            ) : page === "table" ? (
              <div className="mb-4" style={{ height: "530px" }}>
                <TableComponent
                  data={tableData}
                  invoiceTableData={invoiceTableData}
                  combinedTableData={combinedTableData}
                  setCombinedTableData={setCombinedTableData}
                  getExtractedInvoice={getExtractedInvoice}
                  onCellHover={handleCellHover}
                  toggleMultipleBoundingBoxes={handleMultipleCellHover}
                  multipleBoundingBoxes={multipleboundingBoxes}
                  setMultipleBoundingBoxes={setMultipleBoundingBoxes}
                />
                <InvoicePagination />
              </div>
            ) : (
              <Row>
                <Col md={showVertical ? 12 : 6}>
                  <PDFComponent
                    pagination={true}
                    setHoveredCellCoordinates={setHoveredCellCoordinates}
                    hoveredCellCoordinates={hoveredCellCoordinates}
                    multipleBoundingBoxes={multipleboundingBoxes}
                    setMultipleBoundingBoxes={setMultipleBoundingBoxes}
                    singleCellCoordinates={singleCellCoordinates}
                    toggleMultipleBoundingBoxes={handleMultipleCellHover}
                  />
                </Col>
                <Col md={showVertical ? 12 : 6}>
                  <div className="mb-4" style={{ height: "530px" }}>
                    <TableComponent
                      data={tableData}
                      invoiceTableData={invoiceTableData}
                      combinedTableData={combinedTableData}
                      setCombinedTableData={setCombinedTableData}
                      getExtractedInvoice={getExtractedInvoice}
                      onCellHover={handleCellHover}
                      setMultipleBoundingBoxes={setMultipleBoundingBoxes}
                      multipleBoundingBoxes={multipleboundingBoxes}
                      toggleMultipleBoundingBoxes={handleMultipleCellHover}
                    />
                  </div>
                </Col>
              </Row>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default PDFTableComponent;
